import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import moment from 'moment';
import api from '../../services/api';
import ComboEmpresa from '../Components/ComboEmpresa/ComboEmpresa.js';
import ComboPDV from 'views/Components/ComboPDV';
import {
  AMODULO_RECEPTIVO, AMODULO_FINANCAS, AMODULO_GERENCIAL, AMODULO_FROTAS, AMODULO_CRM,
  AMODULO_ESTOQUE, PATHIMAGE,
  ASIM,
  AMODULO_ADMAPPCLI
} from 'consts';

import { saveConfigurations, getConfigField, saveCompany, savePDV } from '../../lib/configUtils';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'


/* pega url imagem do módulo */
const getBackgroundImage = (rota) => {
  if (rota === '/financas') {
    return 'url(/images/authfinancas.jpg)';
  } else if (rota === '/front') {
    return 'url(/images/auth.jpg)';
  } else if (rota === '/gerencial') {
    return 'url(/images/authgerencial.jpg)';
  } else if (rota === '/crm') {
    return 'url(/images/authcrm.jpg)';
  } else if (rota === '/frotas') {
    return 'url(/images/authfrotas.jpg)';
  } else if (rota === '/estoque') {
    return 'url(/images/authestoque.jpg)';
  } else if (rota === '/admappcli') {
    return 'url(/images/authsisarepassenger.png)';
  } else {
    return 'url(/images/authgerencial.jpg)';
  }
};

const schema = {
  usuario: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 20, message: 'máximo 20 carateres'
    }
  },
  senha: {
    presence: { allowEmpty: false, message: 'é obrigatória' },
    length: {
      maximum: 15, message: 'máximo 8 caracteres'
    }
  }
};

let useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: getBackgroundImage(location.pathname),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    //color: theme.palette.white,
    color: theme.palette.black,
    fontWeight: 300,

  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));


const Login = props => {
  const { history } = props;
  let rotaModulo = history.location.pathname;
  let descModulo = '';
  let modulo = '';

  if (rotaModulo === '/financas') {
    descModulo = 'Finanças';
    modulo = AMODULO_FINANCAS
  }
  else
  if (rotaModulo === '/front') {
    descModulo = 'Front';
    modulo = AMODULO_RECEPTIVO
  }
  else
  if (rotaModulo === '/crm') {
    descModulo = 'CRM';
    modulo = AMODULO_CRM
  }
  else
  if (rotaModulo === '/estoque') {
    descModulo = 'Estoque';
    modulo = AMODULO_ESTOQUE
  }
  else
  if (rotaModulo === '/frotas') {
    descModulo = 'Frotas';
    modulo = AMODULO_FROTAS
  }
  else
  if (rotaModulo === '/gerencial') {
    descModulo = 'Gerencial';
    modulo = AMODULO_GERENCIAL
  }
  else
  if (rotaModulo ==='/admappcli'){
    descModulo = 'AdmAppCli';
    modulo = AMODULO_ADMAPPCLI
  }
  sessionStorage.setItem('modulo', modulo);


  //console.log("o que já no message.message:" + message.url);
  //let classes = useStyles({ backgroundImage: 'url(/images/auth.jpg' });

  const [configuracoes, setConfiguracoes] = useState({
    codEmpresa: 0,
    formaPDV: 0,
    traboOutraMoeda: 0,

  })

  // remove token / usuário
  sessionStorage.removeItem('codUser');
  //sessionStorage.removeItem('user');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('lastLogin');


  const classes = useStyles();

  /* Mensagem Erro Geral */
  const [message, setMessage] = useState({
    message: ''
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    rotaModulo: history.location.pathname,
    descModulo: descModulo,
    modulo: modulo
  });

  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));

    const termsNeeded = [CommonTranslations.LABEL_USER,
    CommonTranslations.LABEL_PASSWORD,
    CommonTranslations.LABEL_PDV]; // Lista de termos da página
    i18n.fetchTerms(termsNeeded)
      .then(translations => {
        setTranslations(translations);
        console.log('teste>>' + JSON.stringify(translations)); // Move o console.log para depois da atualização
      })
      .catch(error => {
        console.error('Erro ao carregar traduções:', error);
      });

  }, [formState.values]);



  const handleChange = event => {
    event.persist();

    setFormState(prevFormState => ({
      ...prevFormState, // Mantém o estado anterior intacto
      values: {
        ...prevFormState.values, // Mantém os outros campos que já foram preenchidos
        [event.target.name]: event.target.value // Atualiza apenas o campo que foi alterado
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleChangeEmpresa = (event) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        empresa: event.target.value // Atualiza o valor da empresa
      }
    }));
  };


  /* atualiza dados de configuracoes - front - geral - em sessionStorage */
  function loadConfiguracoes() {
    return new Promise((resolve, reject) => {

      let codEmpresa = sessionStorage.getItem('codEmpresa');
      if ((codEmpresa === null) || (codEmpresa === undefined)) {
        codEmpresa = 1;
      }

      /* carrega configurações da empresa */
      const aURL = `/configuracoes/${codEmpresa}`;
      const response = api.get(aURL)
        .then(function (response) {
          setConfiguracoes({
            codEmpresa: response.data.codempresa,
            formaPDV: response.data.formapdv,
            traboOutraMoeda: response.data.traboutramoeda,
            fantasia: response.data.fantasia
          });
          sessionStorage.setItem('codEmpresa', response.data[0].codempresa);
          sessionStorage.setItem('trabOutraMoeda', response.data[0].traboutramoeda);
          sessionStorage.setItem('formaPDV', response.data[0].formapdv);
          sessionStorage.setItem('fantasia', response.data[0].fantasia);

          // Salvar configurações no localStorage
          saveConfigurations(response.data[0], 'cfg');
          //localStorage.setItem('configuracoes', JSON.stringify(response.data[0]));

          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

    });
  }

  /* atualiza dados de configuracoes em sessionStorage */
  function loadConfiguracoesCRM() {
    return new Promise((resolve, reject) => {

      let codEmpresa = sessionStorage.getItem('codEmpresa');
      if ((codEmpresa === null) || (codEmpresa === undefined)) {
        codEmpresa = 1;
      }

      /* carrega configurações crm da empresa */
      const aURL = `/crm/configuracoes/${codEmpresa}`;
      const response = api.get(aURL)
        .then(function (response) {
          saveConfigurations(response.data[0], 'cfg_crm');
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

    });

  }

  /* atualiza dados de configuracoes em sessionStorage */
  function loadEmpresa() {
    return new Promise((resolve, reject) => {

      let codEmpresa = sessionStorage.getItem('codEmpresa');
      if ((codEmpresa === null) || (codEmpresa === undefined)) {
        codEmpresa = 1;
      }
      /* carrega dados empresa */
      const aURL = `/empresa/${codEmpresa}`;
      const response = api.get(aURL)
        .then(function (response) {
          saveCompany(response.data[0]);
          if (getConfigField('modulocrm', 'empresa') === ASIM) {
            loadConfiguracoesCRM();
          }
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

    });

  }

  /* atualiza dados do PDV na sessionStorage */
  function loadPDV() {
    return new Promise((resolve, reject) => {

      let codPDV = sessionStorage.getItem('codPDV');
      console.log('vai adicionar o método pdv do codpdv:' + codPDV);
      /* carrega dados empresa */
      const aURL = `/pdv/${codPDV}`;
      const response = api.get(aURL)
        .then(function (response) {
          savePDV(response.data[0]);
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

    });

  }


  /* carrega produção de serviço */
  async function validaUsuario() {
    const aURL = '/login';
    const data = { usuario: formState.values.usuario, senha: formState.values.senha, codEmpresa: sessionStorage.getItem('codEmpresa') };
    // console.log('validará usuário...');
    const response = api.post(aURL, data)
      .then(function (response) {
        let lastLogin = new Date();
        //console.log(response.data);
        lastLogin = moment(lastLogin, 'DD/MM/YYYY', true).format('DD/MM/YYYY');
        //    sessionStorage.setItem('codUser', response.data.codUsuario);   
        sessionStorage.setItem('codUser', response.data.codUsuario);

        //sessionStorage.setItem('user', response.data.usuario);
        sessionStorage.setItem('user', response.data.usuario);

        //console.log('o que está no módulo..' + formState.modulo);

        switch (parseInt(formState.modulo)) {
          case AMODULO_GERENCIAL:
            //console.log('módulo gerencial escolhido..')
            history.push('/gerencial/menuproducao')
            break;
          case AMODULO_RECEPTIVO:
            //console.log('módulo front escolhido..')
            history.push('/front/dashboard')
            break;
          case AMODULO_FINANCAS:
            //console.log('módulo financas escolhido..')
            history.push('financas/dashboard')
            break;

          case AMODULO_ADMAPPCLI:
              console.log('módulo ADMAPPCLI escolhido..')
              history.push('admappcli/dashboard')
              break;
  
            default:
            break;
        }


        setMessage({
          message: 'ok',

        });

        return response;

      })
      .catch(function (error) {
        //console.log('o erro na mensagem é: ' + error.message)
        if (error.message === 'Network Error') {
          //console.log('vai setar a mensagem: ' + error.message)
          setMessage({ message: 'Serviço API não respondeu' })
        }
        else {
          setMessage({ message: 'Login inválido!' });
        }

      });
  }

  /* submit logar */
  const handleSignIn = event => {
    event.preventDefault();
    if (sessionStorage.getItem('codEmpresa') === null) {
      sessionStorage.setItem('codEmpresa', 1);
    }

    sessionStorage.setItem('moeda', 1);
    sessionStorage.setItem('mod-ger-tipoExtraFat', 3);

    if (validaUsuario()) {
      /* carrega configurações geral - front */
      loadConfiguracoes();
      /* carrega dados da empresa */
      loadEmpresa();
      /* pega configuração do codPDV logado */
      //console.log('vai carregar load pdv..');
      //console.log('o que está no formaPDV:vai carregar load pdv..:' + sessionStorage.getItem('formaPDV'));

      //if (sessionStorage.getItem('formaPDV') === 2) {
      if (sessionStorage.getItem('codPDV') > 0) {
        //console.log('carregando loadpdvs..');
        loadPDV();
      }

    }
    else
      console.log('usuário inválido')
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  //console.log('entrou no hasError...campo' + formState.touched['usuario']);

  // Exemplo de como acessar um termo de tradução:
  const lblUser = translations[CommonTranslations.LABEL_USER];
  const lblPassword = translations[CommonTranslations.LABEL_PASSWORD];


  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
              </Typography>

            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Login
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  {formState.descModulo}
                </Typography>

                <ComboEmpresa
                  rotaModulo={formState.rotaModulo}
                />
                <ComboPDV
                  rotaModulo={formState.rotaModulo}
                //onChange = {handleChangeEmpresa}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('usuario')}
                  fullWidth
                  helperText={
                    hasError('usuario') ? formState.errors.usuario[0] : null
                  }
                  label={lblUser || "Usuário"}
                  name="usuario"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.usuario || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('senha')}
                  fullWidth
                  helperText={
                    hasError('senha') ? formState.errors.senha[0] : null
                  }
                  label={lblPassword || "Senha"}
                  name="senha"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.senha || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Entrar
                </Button>
                <Typography
                  id="servicoAPI"
                >
                  {message.message}
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default withRouter(Login);
