import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, withStyles } from '@material-ui/core';
import api from 'services/api';
import { formatDateTimeISO } from 'lib/functions';

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  dialogContent: {
    wordWrap: 'break-word',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
});

class HistoricoObsOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historicos: [], // Armazena os dados de histórico
      selectedHistorico: null, // Armazena o histórico selecionado
      open: false, // Controla o diálogo da observação
    };
  }

  // Método para buscar os dados da API ao montar o componente
  componentDidMount() {
    const { osId } = this.props; // Código da OS passado como prop

    this.fetchHistorico(osId);
  }

  // Faz a requisição à API para obter os históricos
  fetchHistorico = async (osId) => {
    try {
      const response = await api.get(`/historicoobsos/os/${osId}`);
      console.log('response: ' + JSON.stringify(response))
      this.setState({ historicos: response.data });
    } catch (error) {
      console.error('Erro ao buscar histórico:', error);
    }
  };

  // Abre o diálogo com a observação do histórico
  handleRowClick = (historico) => {
    this.setState({ selectedHistorico: historico, open: true });
  };

  // Fecha o diálogo
  handleClose = () => {
    this.setState({ open: false, selectedHistorico: null });
  };

  // Formata a data de exibição
  formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  render() {
    const { historicos, selectedHistorico, open } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Observação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicos.map((historico) => (
                <TableRow key={historico.id} onClick={() => this.handleRowClick(historico)} style={{ cursor: 'pointer' }}>
                  <TableCell>{formatDateTimeISO(historico.datahoraregistro)}</TableCell>
                  <TableCell>{historico.usuario}</TableCell>
                  <TableCell>{historico.obs}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Exibe a observação no diálogo */}
        <Dialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth>
          <DialogTitle className={classes.dialogTitle}>Observação do Histórico</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="body1">
              {selectedHistorico ? selectedHistorico.obs : ''}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(HistoricoObsOS);
