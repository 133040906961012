import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    select: {
        '& .MuiSelect-root': {
            padding: theme.spacing(2), // Aumenta a altura do Select
            borderRadius: 4,
            border: 'none', // Remove qualquer borda padrão
            boxShadow: 'none', // Remove sombras que possam estar interferindo
        },
        '& option': {
            padding: theme.spacing(1),
            borderRadius: 4,
            margin: 0, // Remove margens para evitar o risco leve
        }
    }
}));

const ComboCategoriaServico = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    function Categorias() {
        var listCategorias = rest.listItems;
        const selectedCategoria = listCategorias.find(categoria => categoria.codcategoriaservico === rest.value);

        return (
            <div>
                <InputLabel htmlFor="outlined-age-native-simple">{rest.label}</InputLabel>

                <Select
                    fullWidth
                    className={classes.select}
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    value={rest.value || ''} // Garante que a opção em branco é selecionada se não houver valor
                    style={{
                        color: selectedCategoria && selectedCategoria.inativo === 'S' ? 'white' : 'black',
                        backgroundColor: selectedCategoria && selectedCategoria.inativo === 'S' ? '#ff9999' : 'inherit', // Tom mais forte de vermelho
                        border: 'none'
                    }}                   
                >
                    <option value="" aria-label="Não Definido"> </option> {/* Opção em branco */}
                    {listCategorias.map(categoria => (
                        <option
                            key={categoria.codcategoriaservico}
                            value={categoria.codcategoriaservico}
                            style={{
                                color: categoria.inativo === 'S' ? 'white' : 'black',
                                backgroundColor: categoria.inativo === 'S' ? '#ff9999' : 'inherit' // Tom mais forte de vermelho
                            }}
                        >
                            {categoria.desccategoriaservico}
                        </option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Categorias />
    );
};

ComboCategoriaServico.propTypes = {
    className: PropTypes.string
};

export default ComboCategoriaServico;
