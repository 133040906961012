import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import i18n from './i18n'; // Certifique-se de que o caminho está correto
//import './i18n'; // Importa a configuração do i18n
import * as serviceWorker from './serviceWorker';

require('dotenv').config(); // Habilita variáveis de ambiente

// Inicializa o i18n antes de renderizar o App
// Importar i18n garante que as traduções e a configuração sejam aplicadas antes da renderização
  ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
