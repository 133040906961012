import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const AlertInformation = ({ open, handleClose,  message }) => {
  const classes = useStyles();

  return (
  
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Informação
        </Typography>
        <Typography variant="body1">
          {message}
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AlertInformation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message : PropTypes.string.isRequired,
};

export default AlertInformation;
