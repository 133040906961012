import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography, Icon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import StoreIcon from '@material-ui/icons/Store'; // Ícone para representar POS
import { getPDVField, getConfigField } from 'lib/configUtils';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column', // Coloca os elementos um abaixo do outro
    alignItems: 'flex-end', // Alinha o conteúdo à direita
    width: '100%', // Ocupa toda a largura disponível
  },
  companyName: {
    fontSize: '0.7rem', // Tamanho de fonte para o nome da empresa
    color: '#fff', // Cor branca
  },
  pdvContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', // Alinha o conteúdo à direita
  },
  pdvIcon: {
    marginRight: theme.spacing(1), // Espaço entre o ícone e o texto
  },
  pdvText: {
    fontSize: '0.7rem', // Tamanho de fonte para o nome do PDV
    color: '#fff', // Cor branca
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();

  function getImage() {
    const modulo = sessionStorage.getItem('modulo');
    let imagePath = '';
    if (modulo === '37') {
      imagePath = '/images/logocliappcli.png';
    } else {
      imagePath = '/images/logos/logo--white.png';
    }
    return imagePath;
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={getImage()}
          />
        </RouterLink>
        <div className={classes.flexGrow} />

        <div className={classes.titleContainer}>
          {/* Nome da Empresa */}
          <Typography
            className={classes.companyName}
            color="inherit"
            gutterBottom
            variant="body2"
          >
            {rest.fantasia}
          </Typography>

          {/* Nome do PDV com ícone */}
          <div className={classes.pdvContainer}>
            <StoreIcon className={classes.pdvIcon} /> {/* Ícone do POS */}
            {getConfigField('formapdv') === 2 && (
              <Typography
                className={classes.pdvText}
                color="inherit"
                gutterBottom
                variant="body2"
              >
                {getPDVField('descpdv')}
              </Typography>
            )}
          </div>
        </div>

        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
