import { AppBar, Box, Button, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, List, ListItem, ListItemIcon, ListItemText, Modal, Radio, RadioGroup, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, colors } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import api from '../../../services/api';
import { ACREDITO, ADEBITO, AFORMA_PDV_SISARE_MULTI, AGENERO_SERVICO_AEREO, AGENERO_SERVICO_CRUZEIRO, AGENERO_SERVICO_HOSPEDAGEM, AGENERO_SERVICO_LOCADORAVEICULOS, AGENERO_SERVICO_OPCIONAL, AGENERO_SERVICO_OUTROS, AGENERO_SERVICO_OUTROSVIAGENS, AGENERO_SERVICO_PACOTEVIAGENS, AGENERO_SERVICO_SEGUROVIAGEM, AGENERO_SERVICO_SERVICOVIAGENS, AGENERO_SERVICO_TRANSPORTE, ANAO, ASIM, ATIPO_SERVICOAPOIO_IDA, ATIPO_SERVICOAPOIO_RETORNO, ATIPOSERVICO_REGULAR, tDialeto, tPais } from 'consts';
import PropTypes from 'prop-types';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import ComboCategoriaServico from 'views/Components/ComboCategoriaServico';
import ComboSetor from 'views/Components/ComboSetor';
import ComboCentroReceitaDespesa from 'views/Components/ComboCentroReceitaDespesa';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import moment from 'moment';
import ComboServico from 'views/Components/ComboServico';
import ComboCidade from 'views/Components/ComboCidade';
import CategoriaServico from '../CategoriaServico';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import ComboPadrao from 'views/Components/ComboPadrao';
import ComboTipoServico from 'views/Components/ComboTipoServico';
import SearchCidade from 'views/Components/SearchCidade';
import { getConfigField, loadCompany } from 'lib/configUtils';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';
import { CheckBox } from '@material-ui/icons';
import HorarioPadraoServico from './HorarioPadraoServico';

function TabContainer(props) {
    return (
        <Typography
            component="div"
        // style={{ padding: 8 * 3 }}
        >
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0), // Margem superior
        marginBottom: "0.5rem", // Margem inferior
        marginRight: "0.5rem", // Margem direita
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right', // alinhamento a direita
    },
    textarea: {
        width: '100%',
        height: '100px',
        padding: theme.spacing(1),
        boxSizing: 'border-box',
    },
    dialogContent: {
        padding: theme.spacing(2),
    },

});

class ServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servico: {},
            categoriasservico: [],
            centrosreceita: [],
            setoresreceita: [],
            centrosdespesa: [],
            setoresdespesa: [],
            servicosvinctransp: [],
            // cidades: [],
            afinidadesservico: [],
            locais: [],
            empresa: {},
            selectedCity: null,
            activetab: 0,
            activeTabDescritivo: 0,
            isUpdatingAfinidade: false,
            pdvsservico: [],
            horariosservico: [],
            isUpdatingPDV: false,
            isNewRecord: false,
            isView: false,
            schema: {
                usuario: {
                    presence: { allowEmpty: false, message: 'é obrigatório' },
                    length: {
                        maximum: 64
                    }
                },
            },

            useStyles: makeStyles(theme => ({
                root: {
                    backgroundColor: theme.palette.background.default,
                    height: '100%'
                },
                grid: {
                    height: '100%'
                },
                quoteContainer: {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                quote: {
                    backgroundColor: theme.palette.neutral,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(/images/auth.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                },
                quoteInner: {
                    textAlign: 'center',
                    flexBasis: '600px'
                },
                quoteText: {
                    color: theme.palette.white,
                    fontWeight: 300
                },
                name: {
                    marginTop: theme.spacing(3),
                    color: theme.palette.white
                },
                bio: {
                    color: theme.palette.white
                },
                contentContainer: {},
                content: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                contentHeader: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: theme.spacing(5),
                    paddingBototm: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2)
                },
                logoImage: {
                    marginLeft: theme.spacing(4)
                },
                contentBody: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                },
                form: {
                    paddingLeft: 100,
                    paddingRight: 100,
                    paddingBottom: 125,
                    flexBasis: 700,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2)
                    }
                },
                title: {
                    marginTop: theme.spacing(3)
                },
                socialButtons: {
                    marginTop: theme.spacing(3)
                },
                socialIcon: {
                    marginRight: theme.spacing(1)
                },
                sugestion: {
                    marginTop: theme.spacing(2)
                },
                textField: {
                    marginTop: theme.spacing(2)
                },
                signInButton: {
                    margin: theme.spacing(2, 0)
                },
                alert: {
                    color: '#FF0000',
                }
            })),
        };
    }

    async componentDidMount() {
        // busca dados configurações
        // carrega informações formulário
        const { id } = this.props.match.params;
        const { location } = this.props;
        const currentPath = location.pathname;
        //console.log(this.props.match.params.id);

        const vincservaservtransp = (getConfigField('vincservaservtransp') == ASIM);
        const trabmaisumaafinidadeservico = (getConfigField('trabmaisumaafinidadeservico') == ASIM);
        const multipdv = (getConfigField('formapdv') == AFORMA_PDV_SISARE_MULTI);

        if (id >= 0) {
            const response = await api.get(`/servico/${id}`);
            // console.log('o que está em response.data do servico:' + JSON.stringify(response.data[0]));

            this.setState({
                servico: response.data[0],
                activeTab: '1',
                activeTabDescritivo: '1',
                isNewRecord: false,
                SelectedCategoriaServico: response.data[0].codcategoriaservico,
                isView: (currentPath.includes('/view'))
            });

            const { servico } = this.state;
            servico.hspadraoservico = moment(servico.hspadraoservico).format("HH:mm");
            servico.horaordappcli = moment(servico.horaordappcli).format("HH:mm");
            servico.hsunicapresopcguia = moment(servico.hsunicapresopcguia).format("HH:mm");
            this.setState({ servico: servico });

            const categoriasservico = await api.get(`/categoriaservico/listforchange/${response.data[0].codcategoriaservico}`);
            this.setState({ categoriasservico: categoriasservico.data.data });

            const centrosreceita = await api.get(`/financas/centroreceitadespesa/listforchange/${response.data[0].codcreditodebito}
                                                 ?tipoconta=A&tipolancamento=C`);
            this.setState({ centrosreceita: centrosreceita.data });

            const setoresreceita = await api.get(`/setor/listforchange/${response.data[0].codsetor}`);
            this.setState({ setoresreceita: setoresreceita.data });

            const centrosdespesa = await api.get(`/financas/centroreceitadespesa/listforchange/${response.data[0].codcreditodebitodespesa}
                                                 ?tipoconta=A&tipolancamento=D`);
            this.setState({ centrosdespesa: centrosdespesa.data });

            const setoresdespesa = await api.get(`/setor/listforchange/${response.data[0].codsetordespesa}`);
            this.setState({ setoresdespesa: setoresdespesa.data });

            if (vincservaservtransp) {
                const servicosvinctransp = await api.get(`/servico/listforchange/${response.data[0].codservtransp}`);
                this.setState({ servicosvinctransp: servicosvinctransp.data });
            }

            // const cidades = await api.get(`/cidade/listforchange/${response.data[0].codcidadeservico}`);
            // this.setState({ cidades: cidades.data });

            // Busca as cidades
            const cidades = await api.get(`/cidade/listforchange/${response.data[0].codcidadeservico}`);
            // Encontre a cidade correspondente ao codcidade
            let cidadeSelecionada = cidades.data.find(cidade => cidade.codcidade === response.data[0].codcidadeservico);

            if (cidadeSelecionada == undefined) {
                cidadeSelecionada = {};
            }
            this.setState({ selectedCity: cidadeSelecionada });

            if (trabmaisumaafinidadeservico) {
                const afinidadesservico = await api.get(`/servico/afinidade/${id}`);
                this.setState({ afinidadesservico: afinidadesservico.data });
            } else {
                const afinidadesservico = await api.get(`/afinidadeservico/listforchange/${response.data[0].idafinidade}`);
                this.setState({ afinidadesservico: afinidadesservico.data });
            }

            const locais = await api.get(`/local/listforchange/${response.data[0].codlocal}`);
            this.setState({ locais: locais.data });

            if (multipdv) {
                const pdvsservico = await api.get(`/servico/pdv/${id}`);
                this.setState({ pdvsservico: pdvsservico.data });
            }
        }
        else if (id === 'novo') {
            this.setState({
                activeTab: '1',
                activeTabDescritivo: '1',
                isNewRecord: true
            });
            this.state.isNewRecord = true;
            const { servico } = this.state;
            servico.figuranoticketreceptivo = ASIM;
            servico.servicopordiaria = ANAO;
            servico.escalavel = ASIM;
            servico.codcidadeservico = getConfigField('codcidade', 'empresa');
            servico.categoriasservico = -1;
            servico.centrosreceita = -1;
            servico.setoresreceita = -1;
            servico.centrosdespesa = -1;
            servico.setoresdespesa = -1;
            servico.servicosvinctransp = -1;
            // servico.cidades = -1;
            servico.afinidadesservico = -1;
            servico.locais = -1;
            servico.tiposervpadrao = ATIPOSERVICO_REGULAR;
            this.setState({ servico: servico });
            const categoriasservico = await api.get(`/categoriaservico/active/true`);
            this.setState({ categoriasservico: categoriasservico.data.data });
            const centrosreceita = await api.get(`/financas/centroreceitadespesa/active/true?tipoconta=A&tipolancamento=C`);
            this.setState({ centrosreceita: centrosreceita.data });
            const setoresreceita = await api.get(`/setor/active/true`);
            this.setState({ setoresreceita: setoresreceita.data });
            const centrosdespesa = await api.get(`/financas/centroreceitadespesa/active/true?tipoconta=A&tipolancamento=D`);
            this.setState({ centrosdespesa: centrosdespesa.data });
            const setoresdespesa = await api.get(`/setor/active/true`);
            this.setState({ setoresdespesa: setoresdespesa.data });

            if (vincservaservtransp) {
                const servicosvinctransp = await api.get(`/servico/active/true`);
                this.setState({ servicosvinctransp: servicosvinctransp.data });
            }

            // const cidades = await api.get(`/cidade/active/true`);
            // this.setState({ cidades: cidades.data });
            // Busca as cidades
            const cidades = await api.get(`/cidade/listforchange/${servico.codcidadeservico}`);
            // Encontre a cidade correspondente ao codcidade
            let cidadeSelecionada = cidades.data.find(cidade => cidade.codcidade === servico.codcidadeservico);

            if (cidadeSelecionada == undefined) {
                cidadeSelecionada = {};
            }
            this.setState({ selectedCity: cidadeSelecionada });

            if (trabmaisumaafinidadeservico) {
                const afinidadesservico = await api.get(`/servico/afinidade/0`);
                this.setState({ afinidadesservico: afinidadesservico.data });
            } else {
                const afinidadesservico = await api.get(`/afinidadeservico/active/true`);
                this.setState({ afinidadesservico: afinidadesservico.data });
            }

            const locais = await api.get(`/local/active/true`);
            this.setState({ locais: locais.data });

            if (multipdv) {
                const pdvsservico = await api.get(`/servico/pdv/0`);
                this.setState({ pdvsservico: pdvsservico.data });
            }
        }
    }

    /* evento on change componentes */
    OnChange = (event) => {
        const { servico } = this.state;
        const { name, type, value, checked } = event.target;
        let adjustedValue = value; // Valor ajustado com base no tipo do input


        // console.log('name-component:' + name + ' value:' + value + 'type:' + type);

        // Decide o tratamento com base no tipo do input ou nome
        switch (type) {
            case 'checkbox':
                // Usa o valor checked para campos do tipo checkbox
                // Tratamentos adicionais específicos para campos
                if (name === 'inativo') {
                    if (checked === false) {
                        adjustedValue = ASIM;
                    }
                    else {
                        adjustedValue = ANAO;
                    }
                }
                else {
                    if (checked === false) {
                        adjustedValue = ANAO;
                    } else {
                        adjustedValue = ASIM;
                    }
                }

                if (name === 'escalavel') {
                    if (checked === true) {
                        if ((servico.servicoaereo === ASIM) || (servico.servicohospedagem === ASIM)) {
                            adjustedValue = ANAO;
                            this.handleOpenAlert('Não é possível marcar como escalável este gênero de serviço!');
                        }
                    } else {
                        if (servico.servicotransporte === ASIM) {
                            adjustedValue = ASIM;
                            this.handleOpenConfirmation('Serviço de transporte é recomendável que seja ' +
                                'marcado como escalável para serviços executados ' +
                                'pela empresa. Continuar sem marcar como escalável?')
                        }

                        servico.escalaautomatica = ANAO;
                    }
                } else if (name === 'servicopasseio') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_OPCIONAL;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicohospedagem = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicooutros') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_OUTROS;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicotransporte') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_TRANSPORTE;

                        servico.servicooutros = ANAO;
                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;
                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    } else {
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;
                    }
                } else if (name === 'transferin') {
                    if (checked === true) {
                        servico.transferout = ANAO;
                    }
                } else if (name === 'transferout') {
                    if (checked === true) {
                        servico.transferin = ANAO;
                    }
                } else if (name === 'servicoaereo') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_AEREO;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;

                        servico.escalavel = ANAO;
                    }
                } else if (name === 'servicohospedagem') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_HOSPEDAGEM;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;

                        servico.escalavel = ANAO;
                    }
                } else if (name === 'servicoseguroviagem') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_SEGUROVIAGEM;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicolocadoraveiculos') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_LOCADORAVEICULOS;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicoservicoviagens') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_SERVICOVIAGENS;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicocruzeiro') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_CRUZEIRO;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicoservicoviagens = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicopacoteviagens') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_PACOTEVIAGENS;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicoservicoviagens = ANAO;
                        servico.servicocruzeiro = ANAO;
                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicooutrosviagens = ANAO;
                    }
                } else if (name === 'servicooutrosviagens') {
                    if (checked === true) {
                        servico.codigogenero = AGENERO_SERVICO_OUTROSVIAGENS;

                        servico.servicotransporte = ANAO;
                        servico.transferin = ANAO;
                        servico.transferout = ANAO;
                        servico.servtranspvintarifcidade = ANAO;

                        servico.servicopasseio = ANAO;
                        servico.servicohospedagem = ANAO;
                        servico.servicooutros = ANAO;
                        servico.servicoaereo = ANAO;
                        servico.servicoseguroviagem = ANAO;

                        servico.servicoservicoviagens = ANAO;
                        servico.servicopacoteviagens = ANAO;
                        servico.servicolocadoraveiculos = ANAO;
                        servico.servicocruzeiro = ANAO;
                    }
                } else if (name === 'controlapontuacao') {
                    if (checked === false) {
                        servico.pontos = 0;
                        servico.essencialparapontuacao = ANAO;
                    }
                } else if (name === 'servicopordiaria') {
                    if (checked === true) {
                        servico.consideraadt = ANAO;
                        servico.considerachd = ANAO;
                        servico.considerainf = ANAO;
                        servico.considerasen = ANAO;
                    }
                } else if (name === 'controladisponibilidade') {
                    if (checked === false) {
                        servico.contrrigdisponib = ANAO;

                        servico.consideraadt = ANAO;
                        servico.considerachd = ANAO;
                        servico.considerainf = ANAO;
                        servico.considerasen = ANAO;

                        servico.controleunicosaldo = ANAO;
                    }
                } else if (name === 'contrrigdisponib') {
                    if (checked === false) {
                        servico.consideraadt = ANAO;
                        servico.considerachd = ANAO;
                        servico.considerainf = ANAO;
                        servico.considerasen = ANAO;
                    }
                } else if (name === 'permitemeiaentrada') {
                    if (checked === false) {
                        servico.percdescmeiaentrada = 0;
                    }
                } else if (name === 'servicoapoio') {
                    if (checked === false) {
                        servico.tiposervicoapoio = 0;
                    }
                }
                break;
            case 'select-one':
                adjustedValue = Number(value);
                break;
            case 'radio':
                adjustedValue = Number(value);
                break;
            case 'textarea':
                break;
            default:
                if ((name == 'perccomissvendedor') || (name === 'perciss') || (name === 'custounitadt') ||
                    (name === 'custounitchd') || (name === 'custounitinf') || (name === 'custounit') ||
                    (name === 'precofixoreaispdvmovel') || (name === 'precofixoreaischdpdvmovel') ||
                    (name === 'precofixodolarpdvmovel') || (name === 'precofixodolarchdpdvmovel') ||
                    (name === 'custounitsen') || (name === 'pontos') || (name === 'percdescmeiaentrada') ||
                    (name === 'codservsnowadt') || (name === 'codservsnowchd') || (name === 'codservsnowsen') ||
                    (name === 'codservsnowinf') || (name === 'sortorder') || (name === 'perciva') ||
                    (name === 'percivaisento')) {
                    adjustedValue = Number(value.replace(/\./g, '').replace(',', '.'));
                } else if (!((name == 'referenciacodbarrapulseira') || (name == 'duracao') ||
                    (name == 'descservicoappcli'))) {
                    adjustedValue = value.toUpperCase();
                }
                break;
        }

        // Atualiza o estado com o valor ajustado
        servico[name] = adjustedValue;
        this.setState({ servico: servico });
    };

    /* evento on change lista de afinidades */
    OnChangeAfinidade = (event) => {
        const { afinidadesservico } = this.state;
        const { checked, id } = event.target;
        const { isUpdatingAfinidade } = this.state;

        if (checked === false) {
            afinidadesservico[id].marcado = ANAO;
        } else {
            afinidadesservico[id].marcado = ASIM;
        }

        this.setState({
            afinidadesservico: afinidadesservico,
            isUpdatingAfinidade: true
        });
    };

    /* evento on change lista de pdvs */
    OnChangePDV = (event) => {
        const { pdvsservico } = this.state;
        const { checked, id } = event.target;
        const { isUpdatingPDV } = this.state;

        if (checked === false) {
            pdvsservico[id].marcado = ANAO;
        } else {
            pdvsservico[id].marcado = ASIM;
        }

        this.setState({
            pdvsservico: pdvsservico,
            isUpdatingPDV: true
        });
    };

    /* atualiza dados de serviço existente - post */
    atualizaServico = async () => {
        const { servico } = this.state;
        servico.codusuarioalterou = parseInt(sessionStorage.getItem('codUser'));

        /* monta lista de afinidades */
        const { isUpdatingAfinidade } = this.state;
        if (isUpdatingAfinidade === true) {
            const { afinidadesservico } = this.state;

            let afinidades = [];
            for (let i = 0; i <= afinidadesservico.length - 1; i++) {
                if (afinidadesservico[i].marcado === ASIM) {
                    afinidades.push({ idafinidade: afinidadesservico[i].id });
                }
            }

            servico['afinidades'] = afinidades;
        }

        /* monta lista de pdvs */
        const { isUpdatingPDV } = this.state;
        if (isUpdatingPDV === true) {
            const { pdvsservico } = this.state;

            let pdvs = [];
            for (let i = 0; i <= pdvsservico.length - 1; i++) {
                if (pdvsservico[i].marcado === ASIM) {
                    pdvs.push({ codpdv: pdvsservico[i].codpdvconfig });
                }
            }

            servico['pdvs'] = pdvs;
        }
        /* */

        try {
            const response = await api.post(`/servico/${servico.codservico}`, servico);
            if (response.status === 200) {
                this.props.history.push('/front/servicos');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* abre janela de confirmação com mensagem */
    handleOpenConfirmation = (message) => {
        this.setState({ openConfirmation: true });
        this.setState({ messageConfirmation: message });
    };

    /* fecha janela de confirmação */
    handleCloseConfirmation = () => {
        this.setState({ openConfirmation: false });
    }

    /* confirmação */
    handleConfirmation = async (message) => {
        const { servico } = this.state;

        /* verifica motivo da confirmação */
        if (message === 'Serviço de transporte é recomendável que seja ' +
            'marcado como escalável para serviços executados ' +
            'pela empresa. Continuar sem marcar como escalável?') {
            servico.escalavel = ANAO;
        }

        this.setState({ servico: servico });
        this.setState({ openConfirmation: false });
    };

    /* Click salvar */
    onClickSave = () => {
        if (this.validationRequiredFields()) {
            if (this.state.isNewRecord) {
                this.insereServico()
            }
            else {
                this.atualizaServico();
            }
        }
    }

    /* Click cancelar */
    onClickCancel = () => {
        this.props.history.push('/front/servicos');
    }

    /* valida campos obrigatórios ao salvar */
    validationRequiredFields() {
        const { servico } = this.state;
        let retorno = true;

        if ((String(servico.descservico).trim() === '') || (servico.descservico === undefined)) {
            servico.descservico = '';
            this.setState({ servico: servico });
            retorno = false;
        }

        if ((String(servico.abreviatura).trim() === '') || (servico.abreviatura === undefined)) {
            servico.abreviatura = '';
            this.setState({ servico: servico });
            retorno = false;
        }

        return retorno;
    }

    /* insere novo serviço */
    insereServico = async () => {
        const { servico } = this.state;
        servico.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));

        /* monta lista de afinidades */
        const { isUpdatingAfinidade } = this.state;
        if (isUpdatingAfinidade === true) {
            const { afinidadesservico } = this.state;

            let afinidades = [];
            for (let i = 0; i <= afinidadesservico.length - 1; i++) {
                if (afinidadesservico[i].marcado === ASIM) {
                    afinidades.push({ idafinidade: afinidadesservico[i].id });
                }
            }

            servico['afinidades'] = afinidades;
        }

        /* monta lista de pdvs */
        const { isUpdatingPDV } = this.state;
        if (isUpdatingPDV === true) {
            const { pdvsservico } = this.state;

            let pdvs = [];
            for (let i = 0; i <= pdvsservico.length - 1; i++) {
                if (pdvsservico[i].marcado === ASIM) {
                    pdvs.push({ codpdv: pdvsservico[i].codpdvconfig });
                }
            }

            servico['pdvs'] = pdvs;
        }
        /* */

        try {
            const response = await api.post(`/servico`, servico);
            if (response.status === 200) {
                this.props.history.push('/front/servicos');
            }
        } catch (error) {
            // console.log('teste erro: ' + error.response.data.error);
            this.handleOpenAlert(error.response.data.error);
        }
    }

    handleChangeTab = (event, value) => {
        this.setState({ activetab: value });
        this.setState({ activetabdescritivo: 0 });
    };

    handleChangeTabDescritivo = (event, value) => {
        this.setState({ activetabdescritivo: value });
    };


    /* verifica se tem erro campos atendimento */
    hasErrorField = (field, value) => {

    }

    // Função que será passada como prop para o CitySearch
    handleCitySelection = (city) => {
        console.log('handleCitySelection:' + city);
        let { servico } = this.state;
        servico.codcidadeservico = city.codcidade;
        this.setState({ selectedCity: city, servico: servico });
    };

    /* abre janela de horários padrões */
    handleOpenHorariosPadroes = () => {
        this.setState({ openHorariosPadroes: true });
    };

    /* fecha janela de horários padrões */
    handleCloseHorariosPadroes = () => {
        this.setState({ openHorariosPadroes: false });
    }

    render() {
        const { classes } = this.props;

        const { activetab } = this.state;
        const { activetabdescritivo } = this.state;
        const { servico } = this.state;
        const { categoriasservico } = this.state;
        const { centrosreceita } = this.state;
        const { setoresreceita } = this.state;
        const { centrosdespesa } = this.state;
        const { setoresdespesa } = this.state;
        const { servicosvinctransp } = this.state;
        // const { cidades } = this.state;
        const { afinidadesservico } = this.state;
        const { locais } = this.state;
        const { selectedCity } = this.state;
        const { pdvsservico } = this.state;
        const empresa = loadCompany();

        // campos configurações
        const vendehospedagem = (getConfigField('vendehospedagem') == ASIM);
        const vendetransporte = (getConfigField('vendetransporte') == ASIM);
        const vendeaereo = (getConfigField('vendeaereo') == ASIM);
        const vendeopcionais = (getConfigField('vendeopcionais') == ASIM);
        const controlaservicoscompulseira = (getConfigField('controlaservicoscompulseira') == ASIM);
        const coddialeto = getConfigField('coddialeto');
        const codpais = getConfigField('codpais');
        const controladisponibservico = (getConfigField('controladisponibservico') == ASIM);
        const exibecustopadraocadservico = (getConfigField('exibecustopadraocadservico') == ASIM);
        const vincservaservtransp = (getConfigField('vincservaservtransp') == ASIM);
        const controlapontosservico = (getConfigField('controlapontosservico') == ASIM);
        const exibenomeservnoforneccadserv = (getConfigField('exibenomeservnoforneccadserv') == ASIM);
        const vendeseguroviagem = (getConfigField('vendeseguroviagem') == ASIM);
        const recebdiretofornec = (getConfigField('recebdiretofornec') == ASIM);
        const detalhaformapgtofornec = (getConfigField('detalhaformapgtofornec') == ASIM);
        const contrservrecebfornec = (getConfigField('contrservrecebfornec') == ASIM);
        const exportsnowland = (getConfigField('exportsnowland') == ASIM);
        const carrodeapoio = (getConfigField('carrodeapoio') == ASIM);
        const vendelocadoraveiculos = (getConfigField('vendelocadoraveiculos') == ASIM);
        const vendeservicosviagens = (getConfigField('vendeservicosviagens') == ASIM);
        const vendecruzeiros = (getConfigField('vendecruzeiros') == ASIM);
        const vendepacotesviagens = (getConfigField('vendepacotesviagens') == ASIM);
        const vendeoutrosviagens = (getConfigField('vendeoutrosviagens') == ASIM);
        const trabprogramacaosugestivo = (getConfigField('trabprogramacaosugestivo') == ASIM);
        const trabhsservdiaespec = (getConfigField('trabhsservdiaespec') == ASIM);
        const hsunicapresopcguia = (getConfigField('hsunicapresopcguia') == ASIM);
        const trabtipopadraoservico = (getConfigField('trabtipopadraoservico') == ASIM);
        const trabmaisumaafinidadeservico = (getConfigField('trabmaisumaafinidadeservico') == ASIM);
        const multipdv = (getConfigField('formapdv') == AFORMA_PDV_SISARE_MULTI);

        // afinidades do servico
        // const { servAfinidades, isLoadingAfinidade } = this.state;

        return (
            <div>
                <AppBar
                    color="default"
                    position="static"
                >
                    <Tabs
                        indicatorColor="primary"
                        onChange={this.handleChangeTab}
                        scrollButtons="auto"
                        scrollable
                        variant="scrollable"
                        textColor="primary"
                        value={activetab}
                    >
                        <Tab
                            // icon={<AssignmentIcon />}
                            label="Dados"
                        />

                        <Tab
                            label="Descritivo"
                        />

                        {empresa && empresa.modulopdvmovel && empresa.modulovendatablet === ANAO ? null : (
                            <Tab
                                label="PDV Móvel"
                            />)
                        }

                        <Tab
                            label="Fornecedores"
                        />

                        {empresa && empresa.moduloadmappcli === ANAO ? null : (
                            <Tab
                                label="App Cliente"
                            />)
                        }

                        {exportsnowland && (
                            <Tab
                                label="Snowland"
                            />)
                        }

                        {carrodeapoio && (
                            <Tab
                                label={((servico.servicoapoio == ASIM) ? "Configurar serviço de apoio" : "Serviços de Apoio")}
                            />)
                        }

                        {((!(codpais == tPais['APAISCHILE'])) ? null : (
                            <Tab
                                label="Impostos"
                            />))
                        }
                    </Tabs>
                </AppBar>
                <CardContent>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Serviço {servico.codservico} - {servico.descservico}
                    </Typography>

                    <br />
                    <br />

                    {/* Aba Dados */}
                    {activetab === 0 &&
                        <TabContainer>
                            <FormGroup row={true}>
                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Nome do serviço"
                                    style={{ width: '84.5%', marginTop: '10px', marginRight: '1%' }}
                                    name="descservico"
                                    value={servico.descservico || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    helperText={servico.descservico === '' ? 'Nome do serviço obrigatório' : ' '}
                                    disabled={this.state.isView}
                                    inputProps={{ maxLength: 100 }}
                                />

                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Abreviatura"
                                    style={{ width: '14.5%', marginTop: '10px' }}
                                    name="abreviatura"
                                    value={servico.abreviatura || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    helperText={servico.abreviatura === '' ? 'Abreviatura obrigatória' : ' '}
                                    disabled={this.state.isView}
                                    inputProps={{ maxLength: 10 }}
                                />
                            </FormGroup>

                            <FormControlLabel
                                // style={{ marginTop: '1.5rem', marginLeft: '10px' }}
                                control={
                                    <Switch
                                        checked={!(servico.inativo === ASIM)}
                                        name="inativo"
                                        onChange={this.OnChange}
                                        color="primary"
                                        disabled={this.state.isView}
                                    />
                                }
                                label={(servico.inativo === ASIM) ? 'Inativo' : 'Ativo'}
                            />

                            <br />
                            <br />

                            <FormControl
                                fullWidth
                                variant="outlined"
                                disabled={this.state.isView}
                            >
                                <ComboPadrao
                                    name={'codcategoriaservico'}
                                    label={'Categoria Serviço'}
                                    value={servico.codcategoriaservico}
                                    onChange={this.OnChange}
                                    fieldId={'codcategoriaservico'}
                                    listItems={categoriasservico}
                                    fieldDescription={'desccategoriaservico'}
                                />
                                <FormHelperText>{servico.codcategoriaservico === '0' ? 'Categoria obrigatório' : ' '}</FormHelperText>
                            </FormControl>


                            <br />
                            <br />

                            <Divider style={{ backgroundColor: colors.grey[400] }} />

                            <Typography style={{ marginTop: '5px', color: colors.grey[600] }}>
                                Gênero
                            </Typography>

                            <Typography style={{ marginTop: '5px', marginLeft: '10px', color: colors.grey[600] }}>
                                Receptivo
                            </Typography>

                            <FormGroup row={true}>

                                <FormGroup row={true} style={{ marginLeft: '30px' }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.servicopasseio === ASIM)}
                                                    name="servicopasseio"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView || (!(vendeopcionais))}
                                                />}
                                            label={'Opcionais'}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.servicooutros === ASIM)}
                                                    name="servicooutros"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'Outros'}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.servicotransporte === ASIM)}
                                                    name="servicotransporte"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView || (!(vendetransporte))}
                                                />}
                                            label={'Transporte'}
                                        />
                                    </FormGroup>
                                </FormGroup>

                                <FormGroup row={true} style={{ marginLeft: '20px' }}>
                                    <Divider
                                        orientation="vertical"
                                        style={{
                                            backgroundColor: colors.grey[400],
                                            display: ((servico.servicotransporte == ASIM) ? '' : 'none')
                                        }}
                                        flexItem
                                    />

                                    <FormGroup
                                        style={{
                                            marginLeft: '10px',
                                            display: ((servico.servicotransporte == ASIM) ? '' : 'none')
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.transferin === ASIM)}
                                                    name="transferin"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'Transfer IN'}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.transferout === ASIM)}
                                                    name="transferout"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'Transfer OUT'}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        style={{
                                            display: ((servico.servicotransporte == ASIM) ? '' : 'none')
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.servtranspvintarifcidade === ASIM)}
                                                    name="servtranspvintarifcidade"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'Vínculo tarifário de cidade'}
                                        />
                                    </FormGroup>

                                    <Divider
                                        orientation="vertical"
                                        style={{
                                            backgroundColor: colors.grey[400],
                                            display: ((servico.servicotransporte == ASIM) ? '' : 'none')
                                        }}
                                        flexItem
                                    />
                                </FormGroup>
                            </FormGroup>

                            <Typography style={{ marginTop: '5px', marginLeft: '10px', color: colors.grey[600] }}>
                                Viagens
                            </Typography>

                            <FormGroup row={true} style={{ marginLeft: '30px' }} >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicoaereo === ASIM)}
                                            name="servicoaereo"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendeaereo))}
                                        />}
                                    label={'Aéreo'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicohospedagem === ASIM)}
                                            name="servicohospedagem"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendehospedagem))}
                                        />}
                                    label={'Hospedagem'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicoseguroviagem === ASIM)}
                                            name="servicoseguroviagem"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendeseguroviagem))}
                                        />}
                                    label={'Seguro Viagem'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicoservicoviagens === ASIM)}
                                            name="servicoservicoviagens"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendeservicosviagens))}
                                        />}
                                    label={'Serviço'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicolocadoraveiculos === ASIM)}
                                            name="servicolocadoraveiculos"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendelocadoraveiculos))}
                                        />}
                                    label={'Locação de Veículos'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicopacoteviagens === ASIM)}
                                            name="servicopacoteviagens"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendepacotesviagens))}
                                        />}
                                    label={'Pacotes'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicocruzeiro === ASIM)}
                                            name="servicocruzeiro"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendecruzeiros))}
                                        />}
                                    label={'Cruzeiro'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicooutrosviagens === ASIM)}
                                            name="servicooutrosviagens"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (!(vendeoutrosviagens))}
                                        />}
                                    label={'Outros'}
                                />
                            </FormGroup>

                            <Divider style={{ backgroundColor: colors.grey[400], marginTop: '10px' }} />

                            <br />

                            <Divider style={{ backgroundColor: colors.grey[400] }} />

                            <Typography style={{ marginTop: '5px', color: colors.grey[600] }}>
                                Opções
                            </Typography>

                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.naofiguraroomlist === ASIM)}
                                            name="naofiguraroomlist"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Não figura no room list'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.figuranoticketreceptivo === ASIM)}
                                            name="figuranoticketreceptivo"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Figura no ticket receptivo'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.ignoracontrolerigidotarifa === ASIM)}
                                            name="ignoracontrolerigidotarifa"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Ignora controle rígido de tarifa'}
                                />

                                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.obrigaguia === ASIM)}
                                            name="obrigaguia"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Obriga guia'}
                                /> */}

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicopordiaria === ASIM)}
                                            name="servicopordiaria"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Serviço por quantidade'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.escalavel === ASIM)}
                                            name="escalavel"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Escalável'}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.escalaautomatica === ASIM)}
                                            name="escalaautomatica"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView || (servico.escalavel === ANAO)}
                                        />}
                                    label={'Escala automática do serviço'}
                                />

                                {controladisponibservico && (<FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.controladisponibilidade === ASIM)}
                                            name="controladisponibilidade"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Controla disponibilidade de opcionais'}
                                />)}

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.obrigavalorservico === ASIM)}
                                            name="obrigavalorservico"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Obriga informar valor no lançamento'}
                                />

                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.permitemeiaentrada === ASIM)}
                                                name="permitemeiaentrada"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Permite meia-entrada'}
                                    />

                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label=""
                                        style={{
                                            width: '100px', marginRight: '10px',
                                            display: ((servico.permitemeiaentrada == ASIM) ? '' : 'none')
                                        }}
                                        name="percdescmeiaentrada"
                                        id="percdescmeiaentrada"
                                        value={servico.percdescmeiaentrada || ''}
                                        variant="outlined"
                                        size="small"
                                        currencySymbol="%"
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />
                                </FormGroup>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.bloqueiaservico === ASIM)}
                                            name="bloqueiaservico"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Serviço bloqueável'}
                                />

                                {carrodeapoio && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.servicoapoio === ASIM)}
                                                name="servicoapoio"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Serviço de apoio'}
                                    />)
                                }

                                {trabprogramacaosugestivo && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.exibeprogrsugest === ASIM)}
                                                name="exibeprogrsugest"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Exibe programação sugestiva'}
                                    />)
                                }

                                {trabhsservdiaespec && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.definehsdiaespecif === ASIM)}
                                                name="definehsdiaespecif"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Define horário serviço por dia específico'}
                                    />)
                                }

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.servicoterceirizado === ASIM)}
                                            name="servicoterceirizado"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Serviço terceirizado'}
                                />
                            </FormGroup>

                            <Divider style={{ backgroundColor: colors.grey[400], marginTop: '10px' }} />

                            <Button
                                color="primary"
                                style={{
                                    backgroundColor: colors.grey[300],
                                    color: 'black',
                                    marginTop: '10px'
                                }}
                                // className={classes.addButton}
                                variant="contained"
                                onClick={this.handleOpenHorariosPadroes}
                                disabled={this.state.isView}
                            >
                                Horários padrões
                            </Button>

                            <Divider style={{ backgroundColor: colors.grey[400], marginTop: '10px' }} />

                            <FormGroup
                                style={{
                                    display: ((servico.controladisponibilidade == ASIM) ? '' : 'none')
                                }}
                            >
                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.contrrigdisponib === ASIM)}
                                                name="contrrigdisponib"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Controle rígido de disponibilidade para venda'}
                                    />

                                    <FormGroup
                                        row={true}
                                        style={{
                                            display: (((servico.contrrigdisponib == ASIM) && ((servico.servicopordiaria == ANAO))) ? '' : 'none')
                                        }}
                                    >
                                        <Divider
                                            orientation="vertical"
                                            style={{
                                                backgroundColor: colors.grey[400],
                                                marginTop: '5px', marginBottom: '5px'
                                            }}
                                            flexItem
                                        />

                                        <Typography style={{
                                            marginTop: '10px',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                            color: colors.grey[700]
                                        }}>
                                            Considerar na soma
                                        </Typography>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.consideraadt === ASIM)}
                                                    name="consideraadt"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'ADT'}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.considerachd === ASIM)}
                                                    name="considerachd"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'CHD'}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.considerainf === ASIM)}
                                                    name="considerainf"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'INF'}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={(servico.considerasen === ASIM)}
                                                    name="considerasen"
                                                    onChange={this.OnChange}
                                                    color="primary"
                                                    disabled={this.state.isView}
                                                />}
                                            label={'SEN'}
                                        />

                                        <Divider
                                            orientation="vertical"
                                            style={{
                                                backgroundColor: colors.grey[400],
                                                marginTop: '5px', marginBottom: '5px'
                                            }}
                                            flexItem
                                        />

                                    </FormGroup>
                                </FormGroup>

                                {multipdv && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={(servico.controleunicosaldo === ASIM)}
                                                name="controleunicosaldo"
                                                onChange={this.OnChange}
                                                color="primary"
                                                disabled={this.state.isView}
                                            />}
                                        label={'Estoque único'}
                                    />)
                                }

                            </FormGroup>

                            <Divider style={{
                                backgroundColor: colors.grey[400],
                                display: ((servico.controladisponibilidade == ASIM) ? '' : 'none')
                            }} />

                            <FormGroup row={true}>
                                <FormControl
                                    fullWidth
                                    style={{
                                        marginTop: '1rem',
                                        width: '49.5%',
                                        marginRight: '1%'
                                    }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Centro de Receita</InputLabel>

                                    {/* <ComboCentroReceitaDespesa
                                        name={'codcreditodebito'}
                                        label={'Centro de Receita'}
                                        value={servico.codcreditodebito}
                                        onChange={this.OnChange}
                                        inativo={ANAO}
                                        tipolancamento={ACREDITO}
                                    /> */}
                                    <ComboPadrao
                                        name={'codcreditodebito'}
                                        label={'Centro de Receita'}
                                        value={servico.codcreditodebito}
                                        onChange={this.OnChange}
                                        fieldId={'codcreditodebito'}
                                        listItems={centrosreceita}
                                        fieldDescription={'desccreditodebito'}
                                    />

                                </FormControl>

                                <FormControl
                                    fullWidth
                                    style={{
                                        marginTop: '1rem',
                                        width: '49.5%'
                                    }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Setor da Receita</InputLabel>

                                    {/* <ComboSetor
                                        name={'codsetor'}
                                        label={'Setor da Receita'}
                                        value={servico.codsetor}
                                        onChange={this.OnChange}
                                        inativo={ANAO}
                                    /> */}
                                    <ComboPadrao
                                        name={'codsetor'}
                                        label={'Setor da Receita'}
                                        value={servico.codsetor}
                                        onChange={this.OnChange}
                                        fieldId={'codsetor'}
                                        listItems={setoresreceita}
                                        fieldDescription={'descsetor'}
                                    />

                                </FormControl>
                            </FormGroup>

                            <FormGroup row={true}>
                                <FormControl
                                    fullWidth
                                    style={{
                                        marginTop: '1rem',
                                        width: '49.5%',
                                        marginRight: '1%'
                                    }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Centro de Despesa</InputLabel>

                                    {/* <ComboCentroReceitaDespesa
                                        name={'codcreditodebitodespesa'}
                                        label={'Centro de Despesa'}
                                        value={servico.codcreditodebitodespesa}
                                        onChange={this.OnChange}
                                        inativo={ANAO}
                                        tipolancamento={ADEBITO}
                                    /> */}
                                    <ComboPadrao
                                        name={'codcreditodebitodespesa'}
                                        label={'Centro de Despesa'}
                                        value={servico.codcreditodebitodespesa}
                                        onChange={this.OnChange}
                                        fieldId={'codcreditodebito'}
                                        listItems={centrosdespesa}
                                        fieldDescription={'desccreditodebito'}
                                    />
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    style={{
                                        marginTop: '1rem',
                                        width: '49.5%'
                                    }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Setor da Despesa</InputLabel>

                                    {/* <ComboSetor
                                        name={'codsetordespesa'}
                                        label={'Setor da Despesa'}
                                        value={servico.codsetordespesa}
                                        onChange={this.OnChange}
                                        inativo={ANAO}
                                    /> */}
                                    <ComboPadrao
                                        name={'codsetordespesa'}
                                        label={'Setor da Despesa'}
                                        value={servico.codsetordespesa}
                                        onChange={this.OnChange}
                                        fieldId={'codsetor'}
                                        listItems={setoresdespesa}
                                        fieldDescription={'descsetor'}
                                    />
                                </FormControl>
                            </FormGroup>

                            <FormGroup row>
                                {trabtipopadraoservico && (
                                    <FormControl
                                        style={{ marginTop: '20px', width: '225px', marginRight: '10px' }}
                                        variant="outlined"
                                        disabled={this.state.isView}
                                    >
                                        <InputLabel htmlFor="outlined-age-native-simple">Tipo serviço padrão</InputLabel>

                                        <ComboTipoServico
                                            name={'tiposervpadrao'}
                                            label={'Tipo serviço padrão'}
                                            value={servico.tiposervpadrao}
                                            onChange={this.OnChange}
                                        />
                                    </FormControl>)
                                }

                                <CurrencyTextField
                                    className={this.state.useStyles.textField}
                                    label="Comissão Vendedor"
                                    style={{ marginRight: '10px', marginTop: '20px', width: '225px' }}
                                    name="perccomissvendedor"
                                    id="perccomissvendedor"
                                    value={servico.perccomissvendedor || ''}
                                    variant="outlined"
                                    currencySymbol="%"
                                    outputFormat="number" // Use 'string' para manter a formatação personalizada
                                    decimalCharacter="," // Define a vírgula como o separador decimal
                                    digitGroupSeparator="." // Define o ponto como o separador de milhar
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    minimumValue="0"
                                />

                                {((codpais == tPais['APAISCHILE']) ? null : (
                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label={(coddialeto == tDialeto['ADialetoFrancesFranca']) ? "TVA" : "ISS"}
                                        style={{ marginTop: '20px', width: '225px' }}
                                        name="perciss"
                                        id="perciss"
                                        value={servico.perciss || ''}
                                        variant="outlined"
                                        currencySymbol="%"
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />))}
                            </FormGroup>

                            {/* <br /> */}

                            {exibecustopadraocadservico && (
                                <FormGroup row>
                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label="Custo Unit. ADT"
                                        style={{ marginTop: '25px', marginRight: '10px', width: '150px' }}
                                        name="custounitadt"
                                        id="custounitadt"
                                        value={servico.custounitadt || ''}
                                        variant="outlined"
                                        currencySymbol=""
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />

                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label="Custo Unit. CHD"
                                        style={{ marginTop: '25px', marginRight: '10px', width: '150px' }}
                                        name="custounitchd"
                                        id="custounitchd"
                                        value={servico.custounitchd || ''}
                                        variant="outlined"
                                        currencySymbol=""
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />

                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label="Custo Unit. INF"
                                        style={{ marginTop: '25px', marginRight: '10px', width: '150px' }}
                                        name="custounitinf"
                                        id="custounitinf"
                                        value={servico.custounitinf || ''}
                                        variant="outlined"
                                        currencySymbol=""
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />

                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label="Custo Unit. SEN"
                                        style={{ marginTop: '25px', marginRight: '10px', width: '150px' }}
                                        name="custounitsen"
                                        id="custounitsen"
                                        value={servico.custounitsen || ''}
                                        variant="outlined"
                                        currencySymbol=""
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />

                                    <CurrencyTextField
                                        className={this.state.useStyles.textField}
                                        label="Custo Unit."
                                        style={{ marginTop: '25px', marginRight: '10px', width: '150px' }}
                                        name="custounit"
                                        id="custounit"
                                        value={servico.custounit || ''}
                                        variant="outlined"
                                        currencySymbol=""
                                        outputFormat="number"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        minimumValue="0"
                                    />
                                </FormGroup>)
                            }

                            {((!((vincservaservtransp) && (servico.servicopasseio == ASIM))) ? null : (
                                <FormControl
                                    fullWidth
                                    style={{ marginTop: '20px', marginRight: '10px' }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Vinculado com serviço de transporte</InputLabel>

                                    {/* <ComboServico
                                    name={'codservtransp'}
                                    label={'Vinculado com serviço de transporte'}
                                    value={servico.codservtransp}
                                    onChange={this.OnChange}
                                    inativo={ANAO}
                                /> */}
                                    <ComboPadrao
                                        name={'codservtransp'}
                                        label={'Vinculado com serviço de transporte'}
                                        value={servico.codservtransp}
                                        onChange={this.OnChange}
                                        fieldId={'codservico'}
                                        listItems={servicosvinctransp}
                                        fieldDescription={'descservico'}
                                    />

                                </FormControl>))
                            }

                            {(!(controlapontosservico || (hsunicapresopcguia))) ? null : (
                                <FormGroup
                                    row
                                    style={{ marginTop: '20px' }}
                                >
                                    {hsunicapresopcguia && (
                                        <TextField
                                            InputLabelProps={{ shrink: true, required: false }}
                                            inputProps={{ maxLength: 50 }}
                                            label="Apresentação Guia"
                                            name="hsunicapresopcguia"
                                            onChange={this.OnChange}
                                            style={{ width: '150px' }}
                                            type="time"
                                            value={servico.hsunicapresopcguia || ''}
                                            variant="outlined"
                                            disabled={this.state.isView}
                                        />)
                                    }

                                    {controlapontosservico && (
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={(servico.controlapontuacao === ASIM)}
                                                        name="controlapontuacao"
                                                        onChange={this.OnChange}
                                                        color="primary"
                                                        disabled={this.state.isView}
                                                    />}
                                                style={{ marginLeft: ((hsunicapresopcguia) ? '10px' : '') }}
                                                label={'Controla pontuação'}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={(servico.essencialparapontuacao === ASIM)}
                                                        name="essencialparapontuacao"
                                                        onChange={this.OnChange}
                                                        color="primary"
                                                        disabled={this.state.isView}
                                                    />}
                                                style={{ display: ((servico.controlapontuacao === ASIM) ? '' : 'none') }}
                                                label={'Essencial na pontuação'}
                                            />

                                            <TextField
                                                className={this.state.useStyles.textField}
                                                label="Pontos"
                                                style={{ width: '100px', display: ((servico.controlapontuacao === ASIM) ? '' : 'none') }}
                                                name="pontos"
                                                value={servico.pontos || ''}
                                                variant="outlined"
                                                onChange={this.OnChange}
                                                disabled={this.state.isView}
                                                type="number"
                                            />
                                        </FormGroup>)
                                    }
                                </FormGroup>)
                            }

                            {exibenomeservnoforneccadserv && (
                                <TextField
                                    fullWidth
                                    className={this.state.useStyles.textField}
                                    label="Descrição do serviço no fornecedor"
                                    style={{ marginTop: '20px' }}
                                    name="nomeserviconofornec"
                                    value={servico.nomeserviconofornec || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    inputProps={{ maxLength: 100 }}
                                />)
                            }

                            <FormControl
                                fullWidth
                                style={{ marginTop: '20px' }}
                                variant="outlined"
                                disabled={this.state.isView}
                            >
                                {/* <InputLabel htmlFor="outlined-age-native-simple">Cidade do serviço</InputLabel> */}

                                {/* <ComboCidade
                                    name={'codcidadeservico'}
                                    label={'Cidade do serviço'}
                                    value={servico.codcidadeservico}
                                    onChange={this.OnChange}
                                    inativo={ANAO}
                                /> */}
                                {/* <ComboPadrao
                                    name={'codcidadeservico'}
                                    label={'Cidade do serviço'}
                                    value={servico.codcidadeservico}
                                    onChange={this.OnChange}
                                    fieldId={'codcidade'}
                                    listItems={cidades}
                                    fieldDescription={'desccidade'}
                                /> */}

                                {selectedCity && (
                                    <>
                                        {/* Renderiza o CitySearch passando a função handleCitySelection como prop */}
                                        <SearchCidade
                                            defaultCity={selectedCity || ''}
                                            onCitySelect={this.handleCitySelection} />
                                        <div>
                                        </div>
                                    </>
                                )}

                                <br />
                            </FormControl>

                            <FormGroup row={true}>
                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Sort Order"
                                    style={{ width: '100px', marginRight: '10px' }}
                                    name="sortorder"
                                    value={servico.sortorder || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    type="number"
                                />

                                {controlaservicoscompulseira && (
                                    <TextField
                                        className={this.state.useStyles.textField}
                                        label="Código de barras pulseira"
                                        style={{ width: '200px' }}
                                        name="referenciacodbarrapulseira"
                                        value={servico.referenciacodbarrapulseira || ''}
                                        variant="outlined"
                                        onChange={this.OnChange}
                                        disabled={this.state.isView}
                                        inputProps={{ maxLength: 20 }}
                                    />)}
                            </FormGroup>

                            {multipdv && (
                                <Box>
                                    <FormLabel
                                        component="legend"
                                        style={{
                                            marginTop: '20px',
                                            textAlign: 'center',
                                            backgroundColor: '#e0e0e0',
                                            padding: '3px',
                                        }}
                                        disabled={this.state.isView}
                                    > Pontos de venda do serviço
                                    </FormLabel>
                                    <Table className={''} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        component="p"
                                                        variant="body2"
                                                    >
                                                        Marcado
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        component="p"
                                                        variant="body2"
                                                    >
                                                        PDV
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pdvsservico.map((pdv, index) => (
                                                <TableRow key={pdv.codpdvconfig}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={pdv.marcado === ASIM}
                                                            onChange={this.OnChangePDV}
                                                            id={index}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left" width="250">{pdv.descpdv}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <FormLabel
                                        component="legend"
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#e0e0e0',
                                            padding: '3px'
                                        }}
                                        disabled={this.state.isView}
                                    >
                                    </FormLabel>
                                </Box>
                            )
                            }
                        </TabContainer>
                    }

                    {/* Aba Descritivo */}
                    {activetab === 1 &&
                        <TabContainer>
                            <FormGroup>
                                <AppBar
                                    color="default"
                                    position="static"
                                >
                                    <Tabs
                                        indicatorColor="primary"
                                        onChange={this.handleChangeTabDescritivo}
                                        scrollButtons="auto"
                                        scrollable
                                        variant="fullWidth"
                                        centered="true"
                                        textColor="primary"
                                        value={activetabdescritivo}
                                    >
                                        <Tab
                                            label="Português"
                                        />

                                        <Tab
                                            label="Espanhol"
                                        />

                                        <Tab
                                            label="Inglês"
                                        />
                                    </Tabs>
                                </AppBar>

                                {/* Aba Português */}
                                {activetabdescritivo === 0 &&
                                    <TabContainer>
                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Descritivo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="descritivo"
                                                name="descritivo"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.descritivo}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Informativo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="informativo"
                                                name="informativo"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.informativo}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Política de Cancelamento
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="politicacancelamento"
                                                name="politicacancelamento"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.politicacancelamento}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>
                                    </TabContainer>
                                }

                                {/* Aba Espanhol */}
                                {activetabdescritivo === 1 &&
                                    <TabContainer>
                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Descritivo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="descritivoesp"
                                                name="descritivoesp"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.descritivoesp}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Informativo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="informativoesp"
                                                name="informativoesp"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.informativoesp}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Política de Cancelamento
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="politicacancelamentoesp"
                                                name="politicacancelamentoesp"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.politicacancelamentoesp}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>
                                    </TabContainer>
                                }

                                {/* Aba Inglês */}
                                {activetabdescritivo === 2 &&
                                    <TabContainer>
                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Descritivo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="descritivoing"
                                                name="descritivoing"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.descritivoing}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Informativo do Serviço
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="informativoing"
                                                name="informativoing"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.informativoing}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>

                                        <FormLabel
                                            component="legend"
                                            style={{ marginTop: '1rem' }}
                                            disabled={this.state.isView}
                                        > Política de Cancelamento
                                        </FormLabel>
                                        <FormGroup>
                                            <textarea
                                                id="politicacancelamentoing"
                                                name="politicacancelamentoing"
                                                onChange={this.OnChange}
                                                style={{ height: '100px' }}
                                                type="textarea"
                                                value={servico.politicacancelamentoing}
                                                disabled={this.state.isView}
                                            />
                                        </FormGroup>
                                    </TabContainer>
                                }

                            </FormGroup>

                            {!(trabmaisumaafinidadeservico) && (
                                <FormControl
                                    fullWidth
                                    style={{ marginRight: '10px', marginTop: '20px' }}
                                    variant="outlined"
                                    disabled={this.state.isView}
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Afinidade</InputLabel>

                                    <ComboPadrao
                                        name={'idafinidade'}
                                        label={'Afinidade'}
                                        value={servico.idafinidade}
                                        onChange={this.OnChange}
                                        fieldId={'id'}
                                        listItems={afinidadesservico}
                                        fieldDescription={'descafinidade'}
                                    />

                                </FormControl>)
                            }

                            {trabmaisumaafinidadeservico && (
                                <Box>
                                    <FormLabel
                                        component="legend"
                                        style={{
                                            marginTop: '20px',
                                            textAlign: 'center',
                                            backgroundColor: '#e0e0e0',
                                            padding: '3px',
                                        }}
                                        disabled={this.state.isView}
                                    > Afinidades
                                    </FormLabel>
                                    <Table className={''} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        component="p"
                                                        variant="body2"
                                                    >
                                                        Marcado
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        component="p"
                                                        variant="body2"
                                                    >
                                                        Afinidade
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {afinidadesservico.map((afinidade, index) => (
                                                <TableRow key={afinidade.id}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={afinidade.marcado === ASIM}
                                                            onChange={this.OnChangeAfinidade}
                                                            id={index}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left" width="250">{afinidade.descafinidade}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    <FormLabel
                                        component="legend"
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#e0e0e0',
                                            padding: '3px'
                                        }}
                                        disabled={this.state.isView}
                                    >
                                    </FormLabel>
                                </Box>
                            )
                            }


                            <FormControl
                                fullWidth
                                style={{ marginRight: '10px', marginTop: '20px' }}
                                variant="outlined"
                                disabled={this.state.isView}
                            >
                                <InputLabel htmlFor="outlined-age-native-simple">Local</InputLabel>

                                <ComboPadrao
                                    name={'codlocal'}
                                    label={'Local'}
                                    value={servico.codlocal}
                                    onChange={this.OnChange}
                                    fieldId={'codorigemdestino'}
                                    listItems={locais}
                                    fieldDescription={'descorigemdestino'}
                                />

                                <br />
                            </FormControl>

                            <Divider style={{ backgroundColor: colors.grey[400], marginTop: '10px' }} />

                            <Typography style={{ marginTop: '5px', color: colors.grey[600] }}>
                                Outras opções
                            </Typography>

                            <FormGroup row={true}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.novidade === ASIM)}
                                            name="novidade"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'É novidade?'}
                                />

                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Duração"
                                    style={{ width: '300px' }}
                                    name="duracao"
                                    value={servico.duracao || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </FormGroup>

                            <Divider style={{ backgroundColor: colors.grey[400], marginTop: '10px' }} />
                        </TabContainer>
                    }

                    {/* Aba PDV Móvel */}
                    {activetab === 2 &&
                        <TabContainer>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(servico.atualizainfpdvmovel === ASIM)}
                                        name="atualizainfpdvmovel"
                                        onChange={this.OnChange}
                                        color="primary"
                                        disabled={this.state.isView}
                                    />}
                                label={'Atualiza informações para PDV Móvel'}
                            />

                            {/*    <br />
                            <br />

                             <FormGroup row={true}>
                                <CurrencyTextField
                                    className={this.state.useStyles.textField}
                                    label="Preço Fixo ADT R$"
                                    style={{ marginRight: '10px', width: '150px' }}
                                    name="precofixoreaispdvmovel"
                                    id="precofixoreaispdvmovel"
                                    value={servico.precofixoreaispdvmovel || ''}
                                    variant="outlined"
                                    currencySymbol=""
                                    outputFormat="number"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    minimumValue="0"
                                />

                                <CurrencyTextField
                                    className={this.state.useStyles.textField}
                                    label="Preço Fixo CHD R$"
                                    style={{ marginRight: '10px', width: '150px' }}
                                    name="precofixoreaischdpdvmovel"
                                    id="precofixoreaischdpdvmovel"
                                    value={servico.precofixoreaischdpdvmovel || ''}
                                    variant="outlined"
                                    currencySymbol=""
                                    outputFormat="number"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    minimumValue="0"
                                />
                            </FormGroup>

                            <br />

                            <FormGroup row={true}>
                                <CurrencyTextField
                                    className={this.state.useStyles.textField}
                                    label="Preço Fixo ADT $"
                                    style={{ marginRight: '10px', width: '150px' }}
                                    name="precofixodolarpdvmovel"
                                    id="precofixodolarpdvmovel"
                                    value={servico.precofixodolarpdvmovel || ''}
                                    variant="outlined"
                                    currencySymbol=""
                                    outputFormat="number"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    minimumValue="0"
                                />

                                <CurrencyTextField
                                    className={this.state.useStyles.textField}
                                    label="Preço Fixo CHD $"
                                    style={{ marginRight: '10px', width: '150px' }}
                                    name="precofixodolarchdpdvmovel"
                                    id="precofixodolarchdpdvmovel"
                                    value={servico.precofixodolarchdpdvmovel || ''}
                                    variant="outlined"
                                    currencySymbol=""
                                    outputFormat="number"
                                    decimalCharacter=","
                                    digitGroupSeparator="."
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    minimumValue="0"
                                />
                            </FormGroup> 

                            <br />    

                            <TextField
                                InputLabelProps={{ shrink: true, required: false }}
                                inputProps={{ maxLength: 50 }}
                                label="Horário Padrão"
                                name="hspadraoservico"
                                onChange={this.OnChange}
                                style={{ width: '150px' }}
                                type="time"
                                value={servico.hspadraoservico || ''}
                                variant="outlined"
                                disabled={this.state.isView}
                            />

                            <br />
                            <br />

                            <FormLabel
                                component="legend"
                                disabled={this.state.isView}
                            > Observação
                            </FormLabel>
                            <FormGroup>
                                <textarea
                                    id="obspdvmovel"
                                    name="obspdvmovel"
                                    onChange={this.OnChange}
                                    style={{ height: '100px' }}
                                    type="textarea"
                                    value={servico.obspdvmovel}
                                    disabled={this.state.isView}
                                />
                            </FormGroup> */}

                        </TabContainer>
                    }

                    {/* Aba Fornecedores */}
                    {activetab === 3 &&
                        <TabContainer>

                            {((!((recebdiretofornec) && (detalhaformapgtofornec) &&
                                (contrservrecebfornec))) ? null : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={(servico.recebenofornecedor === ASIM)}
                                            name="recebenofornecedor"
                                            onChange={this.OnChange}
                                            color="primary"
                                            disabled={this.state.isView}
                                        />}
                                    label={'Recebe no fornecedor'}
                                />))
                            }

                        </TabContainer>
                    }

                    {/* Aba App Cliente */}
                    {activetab === 4 &&
                        <TabContainer>
                            <TextField
                                fullWidth
                                className={this.state.useStyles.textField}
                                label="Descrição do serviço para App Cliente"
                                name="descservicoappcli"
                                value={servico.descservicoappcli || ''}
                                variant="outlined"
                                onChange={this.OnChange}
                                disabled={this.state.isView}
                                inputProps={{ maxLength: 100 }}
                            />

                            <FormLabel
                                component="legend"
                                style={{ marginTop: '10px' }}
                                disabled={this.state.isView}
                            > Descritivo serviço para App Cliente
                            </FormLabel>
                            <FormGroup>
                                <textarea
                                    id="descritivoappcli"
                                    name="descritivoappcli"
                                    onChange={this.OnChange}
                                    style={{ height: '100px' }}
                                    type="textarea"
                                    value={servico.descritivoappcli}
                                    disabled={this.state.isView}
                                />
                            </FormGroup>

                            < br />

                            <TextField
                                InputLabelProps={{ shrink: true, required: false }}
                                inputProps={{ maxLength: 50 }}
                                label="Hora ordenação"
                                name="horaordappcli"
                                onChange={this.OnChange}
                                style={{ width: '150px' }}
                                type="time"
                                value={servico.horaordappcli || ''}
                                variant="outlined"
                                disabled={this.state.isView}
                            />
                        </TabContainer>
                    }

                    {/* Aba Snowland */}
                    {activetab === 5 &&
                        <TabContainer>
                            <FormGroup row={true}>
                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Código ADT"
                                    style={{ width: '150px', marginRight: '10px' }}
                                    name="codservsnowadt"
                                    value={servico.codservsnowadt || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    type="number"
                                />

                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Código CHD"
                                    style={{ width: '150px', marginRight: '10px' }}
                                    name="codservsnowchd"
                                    value={servico.codservsnowchd || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    type="number"
                                />

                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Código SEN"
                                    style={{ width: '150px', marginRight: '10px' }}
                                    name="codservsnowsen"
                                    value={servico.codservsnowsen || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    type="number"
                                />

                                <TextField
                                    className={this.state.useStyles.textField}
                                    label="Código INF"
                                    style={{ width: '150px' }}
                                    name="codservsnowinf"
                                    value={servico.codservsnowinf || ''}
                                    variant="outlined"
                                    onChange={this.OnChange}
                                    disabled={this.state.isView}
                                    type="number"
                                />
                            </FormGroup>
                        </TabContainer>
                    }

                    {/* Aba Serviços de Apoio */}
                    {activetab === 6 &&
                        <TabContainer>
                            <FormControl
                                disabled={this.state.isView}
                                style={{ display: ((servico.servicoapoio == ASIM) ? '' : 'none') }}
                            >
                                <FormLabel>Tipo</FormLabel>
                                <RadioGroup
                                    row
                                    name="tiposervicoapoio"
                                    value={servico.tiposervicoapoio || ''}
                                    onChange={this.OnChange}
                                >
                                    <FormControlLabel value={ATIPO_SERVICOAPOIO_IDA} control={<Radio />} label="Ida" />
                                    <FormControlLabel value={ATIPO_SERVICOAPOIO_RETORNO} control={<Radio />} label="Retorno" />
                                </RadioGroup>
                            </FormControl>
                        </TabContainer>
                    }

                    {/* Aba Impostos */}
                    {activetab === 7 &&
                        <TabContainer>
                            <CurrencyTextField
                                className={this.state.useStyles.textField}
                                label="IVA"
                                style={{ marginTop: '20px', marginRight: '10px', width: '150px' }}
                                name="perciva"
                                id="perciva"
                                value={servico.perciva || ''}
                                variant="outlined"
                                currencySymbol="%"
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={this.OnChange}
                                disabled={this.state.isView}
                                minimumValue="0"
                            />

                            <CurrencyTextField
                                className={this.state.useStyles.textField}
                                label="IVA Isento"
                                style={{ marginTop: '20px', width: '150px' }}
                                name="percivaisento"
                                id="percivaisento"
                                value={servico.percivaisento || ''}
                                variant="outlined"
                                currencySymbol="%"
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={this.OnChange}
                                disabled={this.state.isView}
                                minimumValue="0"
                            />
                        </TabContainer>
                    }

                    <br />

                    <br />
                    <br />

                    <Button
                        name="salvar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSave}
                        style={{ marginTop: '10px' }}
                        disabled={this.state.isView}
                    >
                        Salvar
                    </Button>

                    <Button
                        name="cancelar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickCancel}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        Cancelar
                    </Button>
                </CardContent>

                <ConfirmationModal
                    open={this.state.openConfirmation}
                    handleClose={() => this.handleCloseConfirmation()}
                    title={'Informação'}
                    message={this.state.messageConfirmation}
                    handleConfirmation={() => this.handleConfirmation(this.state.messageConfirmation)}
                />

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />

                {/* Exclusão de Afinidade*/}
                <DeleteConfirmationModal
                    open={this.state.isAfinidadeDeleteModalOpen}
                    handleClose={() => this.closeAfinidadeDeleteModal()}
                    itemId={this.state.selectedIdAfinidade}
                    message={"Confirma exclusão da afinidade " + this.state.selectedNomeAfinidade + '?'}
                    handleDelete={() => this.handleDeleteAfinidade(this.state.selectedIdAfinidade)}
                />

                {/* Horários padrões */}
                <Dialog open={this.state.openHorariosPadroes} onClose={this.handleCloseHorariosPadroes} maxWidth="md" fullWidth>
                    <DialogTitle className={classes.dialogTitle}>Horários padrões do serviço</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <HorarioPadraoServico
                            codServico={servico.codservico}
                            horariosServico={this.state.horariosservico}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseHorariosPadroes} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ServicoForm.propTypes =
{
    classes: PropTypes.object.isRequired
};

//export default ServicoForm;
export default withStyles(styles)(ServicoForm);