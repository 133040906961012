import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function MapaOperacao(){
  const defaultProps = {
    center: {
      lat: -27.0969013,
      lng: -52.6279616
    },
    zoom: 15
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={-27.0969013}
          lng={-52.6279616}
          text="Seu motorista está aqui"
        />
      </GoogleMapReact>
    </div>
  );
}