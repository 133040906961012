import { Button, CardContent, FormControl, FormControlLabel, FormGroup, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import api from '../../../services/api';
import { ANAO, ASIM } from 'consts';
import PropTypes from 'prop-types';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'
//import transitions from '@material-ui/core/styles/transitions';

class CategoriaServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriaservico: {},
            isNewRecord: false,
            isView: false,
            translations: {}, // Armazenará as traduções
            schema: {
                usuario: {
                    presence: { allowEmpty: false, message: 'é obrigatório' },
                    length: {
                        maximum: 64
                    }
                },
            },

            useStyles: makeStyles(theme => ({
                root: {
                    backgroundColor: theme.palette.background.default,
                    height: '100%'
                },
                grid: {
                    height: '100%'
                },
                quoteContainer: {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                quote: {
                    backgroundColor: theme.palette.neutral,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(/images/auth.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                },
                quoteInner: {
                    textAlign: 'center',
                    flexBasis: '600px'
                },
                quoteText: {
                    color: theme.palette.white,
                    fontWeight: 300
                },
                name: {
                    marginTop: theme.spacing(3),
                    color: theme.palette.white
                },
                bio: {
                    color: theme.palette.white
                },
                contentContainer: {},
                content: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                contentHeader: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: theme.spacing(5),
                    paddingBototm: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2)
                },
                logoImage: {
                    marginLeft: theme.spacing(4)
                },
                contentBody: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                },
                form: {
                    paddingLeft: 100,
                    paddingRight: 100,
                    paddingBottom: 125,
                    flexBasis: 700,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2)
                    }
                },
                title: {
                    marginTop: theme.spacing(3)
                },
                socialButtons: {
                    marginTop: theme.spacing(3)
                },
                socialIcon: {
                    marginRight: theme.spacing(1)
                },
                sugestion: {
                    marginTop: theme.spacing(2)
                },
                textField: {
                    marginTop: theme.spacing(2)
                },
                signInButton: {
                    margin: theme.spacing(2, 0)
                },
                alert: {
                    color: '#FF0000',
                }
            })),
        };
    }

    async componentDidMount() {
        // carrega informações formulário
        const { id } = this.props.match.params;
        const { location } = this.props;
        const currentPath = location.pathname;

        this.performsTranslation();


        if (id >= 0) {
            const response = await api.get(`/categoriaservico/${id}`);
            console.log('response: '+ JSON.stringify(response))
            this.setState({
                categoriaservico: response.data.data[0],
                isNewRecord: false,
                isView: (currentPath.includes('/view'))
            }
            );
        }
        else if (id === 'novo') {
            this.setState({
                isNewRecord: true
            });
            this.state.isNewRecord = true;
            const { categoriaservico } = this.state;
        }
    }

    /* evento on change componentes */
    OnChange = (event) => {
        const { categoriaservico } = this.state;
        const { name, type, value, checked } = event.target;
        let adjustedValue = value; // Valor ajustado com base no tipo do input
        // Decide o tratamento com base no tipo do input ou nome
        switch (type) {
            case 'checkbox':
                // Usa o valor checked para campos do tipo checkbox
                // Tratamentos adicionais específicos para campos
                if (name === 'inativo') {
                    if (checked === false) {
                        adjustedValue = ASIM;
                    }
                    else {
                        adjustedValue = ANAO;
                    }
                }
                break;
            default:
                adjustedValue = value.toUpperCase();
                break;
        }

        // Atualiza o estado com o valor ajustado
        categoriaservico[name] = adjustedValue;
        this.setState({ categoriaservico: categoriaservico });

    };

    /* atualiza dados de categoria serviço existente - post */
    atualizaCategoriaServico = async () => {
        const { categoriaservico } = this.state;
        try {
            const response = await api.put(`/categoriaservico/${categoriaservico.codcategoriaservico}`, categoriaservico);
            if (response.status === 200) {
                this.props.history.push('/front/categoriasservicos');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Click salvar */
    onClickSave = () => {
        if (this.validationRequiredFields()) {
            if (this.state.isNewRecord) {
                this.insereCategoriaServico()
            }
            else {
                this.atualizaCategoriaServico();
            }
        }
    }

    /* Click cancelar */
    onClickCancel = () => {
        this.props.history.push('/front/categoriasservicos');
    }

    /* valida campos obrigatórios ao salvar */
    validationRequiredFields() {
        const { categoriaservico } = this.state;
        let retorno = true;

        if ((String(categoriaservico.desccategoriaservico).trim() === '') || (categoriaservico.desccategoriaservico === undefined)) {
            categoriaservico.desccategoriaservico = '';
            this.setState({ categoriaservico: categoriaservico });
            retorno = false;
        };

        return retorno;
    }

    /* insere nova categoria serviço */
    insereCategoriaServico = async () => {
        const { categoriaservico } = this.state;
        categoriaservico.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
        try {
            const response = await api.post(`/categoriaservico`, categoriaservico);
            if (response.status === 200) {
                this.props.history.push('/front/categoriasservicos');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }


    }
    /* Realiza a tradução */
    async performsTranslation() {
        const termsNeeded = [CommonTranslations.BUTTON_SAVE,
        CommonTranslations.BUTTON_CANCEL,
        CommonTranslations.CHECKBOX_INATIVO];
        // Lista de termos da página
        const translations = await i18n.fetchTerms(termsNeeded);
        this.setState({ translations });
    }

    render() {
        //const { categoriaservico, btn_save, cancelLabel } = this.state;
        const { categoriaservico, translations } = this.state;
        return (
            <div>
                <CardContent>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Categoria de Serviço {categoriaservico.codcategoriaservico}
                    </Typography>

                    <br />

                    <FormGroup>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Nome da categoria de serviço"
                            style={{ marginTop: '10px' }}
                            name="desccategoriaservico"
                            value={categoriaservico.desccategoriaservico || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            helperText={categoriaservico.desccategoriaservico === '' ? 'Nome da categoria de serviço obrigatório' : ' '}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormGroup>

                    <FormControlLabel
                        // style={{ marginTop: '1.5rem', marginLeft: '10px' }}
                        control={
                            <Switch
                                checked={!(categoriaservico.inativo === ASIM)}
                                name={"inativo"}
                                onChange={this.OnChange}
                                color="primary"
                                disabled={this.state.isView}
                            />
                        }
                        label={(categoriaservico.inativo === ASIM) ?
                            translations[CommonTranslations.CHECKBOX_INATIVO] || "inativo" : 'Ativo'}
                    />

                    <br />
                    <br />

                    <Button
                        name="salvar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSave}
                        style={{ marginTop: '10px' }}
                        disabled={this.state.isView}
                    >
                        {translations[CommonTranslations.BUTTON_SAVE] || 'Salvar'}

                    </Button>

                    <Button
                        name="cancelar"
                        color="secondary"
                        variant="contained"
                        onClick={this.onClickCancel}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        {translations[CommonTranslations.BUTTON_CANCEL] || 'Cancelar'}
                    </Button>
                </CardContent>

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

CategoriaServicoForm.propTypes =
{
    classes: PropTypes.object.isRequired
};

export default CategoriaServicoForm;