import React, { Component } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { ANAO, APAX_ADT, APAX_CHD, APAX_INF, APAX_SEN } from 'consts';
import {
    ACATEGORIA_PAX_PAGANTE, ACATEGORIA_PAX_CORTESIA, ACATEGORIA_PAX_MEIAENTRADA, ASEXO_FEMININO, ASEXO_MASCULINO,
    ASIM, tPais
} from 'consts';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { diff } from 'deep-diff';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import { formatDateForInput, validarCPF, validateField } from 'lib/functions';
import ComboPadrao from 'views/Components/ComboPadrao';
import FormControl from '@material-ui/core/FormControl';
import ComboOnDemand from 'views/Components/ComboOnDemand';  // Importe o componente ComboOnDemand
import SearchCidade from 'views/Components/SearchCidade'; // Importa o componente SearchCidade
import InputMask from 'react-input-mask';
import { getConfigField, loadCompany } from '../../../lib/configUtils';
import { unescape } from 'lodash';
import moment from 'moment';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    }
});

class HorarioPadraoServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horario: {
                // nomepax: '',
                // tipopax: 1,
                // categoria: 1,
                codservico: this.props.codServico,
                // datanasc: '',
                // seqpaxos: 0,
                // codcidade: 0,
                // sexo: 'M'
            },
            // paises: [],
            // tiposdocpessoal: [],
            // cargos: [],
            // nacionalidades: [],
            horario_original: {},
            // selectedCity: null,            
            // errors: {},
            // cpfError: false,
            // schema: {
            //     nomepax: {
            //         presence: { allowEmpty: false, message: 'Nome obrigatório' },
            //         length: { maximum: 100 },
            //         type: 'string'
            //     },
            //     fone: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 20 },
            //         type: 'string'
            //     },
            //     rg: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 20 },
            //         type: 'string'
            //     },
            //     idade: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 10 },
            //         type: 'integer'
            //     },
            //     codfamilia: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 10 },
            //         type: 'integer'
            //     },
            //     cpfpax: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 50 },
            //         type: 'string'
            //     },
            //     email: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 100 },
            //         type: 'string'
            //     },
            //     orgaoemissordoc: {
            //         presence: { allowEmpty: true, message: '' },
            //         length: { maximum: 20 },
            //         type: 'string'
            //     }
            // },
            isNewRecord: false,
            openAlert: false,
            messageAlert: '',
        };
    }

    async componentDidMount() {
        let id = this.props.id;
        let horarioData;

        if (id > 0) {
            if (this.props.codServico === undefined) {
                //     paxData = this.props.pax;
                //     paxData.datanasc = '';
            } else {
                const response = await api.get(`/horarioservico/${id}`);
                horarioData = response.data[0];

                horarioData.dom = moment(horarioData.dom).format("HH:mm");
                horarioData.seg = moment(horarioData.seg).format("HH:mm");
                horarioData.ter = moment(horarioData.ter).format("HH:mm");
                horarioData.qua = moment(horarioData.qua).format("HH:mm");
                horarioData.qui = moment(horarioData.qui).format("HH:mm");
                horarioData.sex = moment(horarioData.sex).format("HH:mm");
                horarioData.sab = moment(horarioData.sab).format("HH:mm");
            }

            this.setState({
                horario: horarioData,
                horario_original: JSON.parse(JSON.stringify(horarioData)),
                isNewRecord: false
            });
        } else if (id === 'novo') {
            this.setState({ isNewRecord: true });
            // this.setState(prevState => ({
            //     pax: {
            //         ...prevState.pax,
            //         tipopax: 1,
            //         recebesms: ASIM
            //     }
            // }));
        }
    }

    // handleChange = (event) => {
    //     const { name, value } = event.target;
    //     this.setState(prevState => ({
    //         pax: {
    //             ...prevState.pax,
    //             [name]: value
    //         }
    //     }));
    // };

    // handleSave = () => {
    //     console.log('Dados salvos:', this.state);
    //     if (this.props.onSave) {
    //         this.props.onSave();
    //     }
    // };

    handleCancel = () => {
        this.props.onClose();
    };

    // Campos para tratamento de exceção no OnChange
    // nonUpperCaseFields = ['email'];
    // integerFields = ['idade', 'codfamilia'];

    OnChange = (event) => {
        const { horario } = this.state;
        const { name, value } = event.target;
        let adjustedValue = value;

        // this.setState(prevState => ({
        //     pax: {
        //         ...prevState.pax,
        //         [name]: adjustedValue
        //     },
        //     errors: {
        //         ...prevState.errors,
        //         [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
        //     }
        // }));

        // Atualiza o estado com o valor ajustado
        horario[name] = adjustedValue;
        this.setState({ horario: horario });
    }

    onClickSave = () => {
        if (this.state.isNewRecord) {
            this.insereHorario();
        } else {
            this.atualizaHorario();
        }
    }

    // validationRequiredFields = () => {
    //     const { pax } = this.state;
    //     const { cpfError } = this.state;
    //     const { schema } = this.state;
    //     const codpais = getConfigField('codpais');
    //     let errors = {};
    //     const nomePaxError = validateField('nomepax', pax.nomepax, schema);
    //     if (nomePaxError) {
    //         errors.nomepax = nomePaxError;
    //     }
    //     const foneError = validateField('fone', pax.fone, schema);
    //     if (foneError) {
    //         errors.fone = foneError;
    //     }
    //     const rgError = validateField('rg', pax.rg, schema);
    //     if (rgError) {
    //         errors.rg = rgError;
    //     }
    //     const idadeError = validateField('idade', pax.idade, schema);
    //     if (idadeError) {
    //         errors.idade = idadeError;
    //     }
    //     const codfamiliaError = validateField('codfamilia', pax.codfamilia, schema);
    //     if (codfamiliaError) {
    //         errors.codfamilia = codfamiliaError;
    //     }
    //     const cpfpaxError = validateField('cpfpax', pax.cpfpax, schema);
    //     if (cpfpaxError) {
    //         errors.cpfpax = cpfpaxError;
    //     }
    //     const emailError = validateField('email', pax.email, schema);
    //     if (emailError) {
    //         errors.email = emailError;
    //     }
    //     const orgaoemissordocError = validateField('orgaoemissordoc', pax.orgaoemissordoc, schema);
    //     if (orgaoemissordocError) {
    //         errors.orgaoemissordoc = orgaoemissordocError;
    //     }

    //     else {
    //         if ((cpfError) && (codpais == tPais.APAISBRASIL)) {
    //             let cpf = {
    //                 field: {
    //                     presence: { allowEmpty: false, message: 'CPF inválido!' },
    //                 }
    //             }
    //             errors.cpfpax = cpf;
    //             this.handleOpenAlert(errors.cpfpax.field.presence.message);
    //             return errors;
    //         }
    //     }

    //     return errors;
    // };


    insereHorario = async () => {
        const { horario } = this.state;    
        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.post(`/horarioservico`, horario);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.horario);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    atualizaHorario = async () => {
        const { horario, horario_original } = this.state;

        const differences = diff(horario_original, horario);

        if (!differences) {
            this.handleCancel();
            return;
        }

        const horarioAlterado = {};
        differences.forEach(d => {
            if (d.kind === 'E' || d.kind === 'N') {
                horarioAlterado[d.path.join('.')] = d.rhs;
            }
        });

        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.put(`/horarioservico/${horario.chave}`, horarioAlterado);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.horario);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    // handleSubmit = () => {
    //     let errors = this.validationRequiredFields();
    //     console.log('the errors..' + JSON.stringify(errors))
    //     this.setState({ errors });
    //     if (Object.keys(errors).length === 0) {
    //         this.onClickSave();
    //     }
    // };

    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    // handleCloseAlert = () => {
    //     this.setState({ openAlert: false });
    // };

    // Função que será passada como prop para o CitySearch
    // handleCitySelection = (city) => {
    //     console.log('handleCitySelection:' + city);
    //     let { pax } = this.state;
    //     pax.codcidade = city.codcidade;
    //     console.log('o codigo da nova cidade: ' + pax.codcidade);
    //     //this.setState({ pax : pax });
    //     this.setState({ selectedCity: city, pax: pax });
    // };

    /* verifica validade CPF */
    // handleCPFBlur = () => {
    //     const { cpfpax } = this.state.pax;

    //     console.log('cpf informado: ' + cpfpax)
    //     if (!(cpfpax == '___.___.___-__')) {
    //         console.log('o que está em cpfpax: ' + cpfpax)
    //         if (!validarCPF(cpfpax)) {
    //             this.setState({ cpfError: true });
    //         } else {
    //             this.setState({ cpfError: false });
    //         }
    //     } else {
    //         this.setState({ cpfError: false });
    //     }

    // }

    formatHora(hora) {
        if (!(hora === null)) {
            const horaApresentacao = hora;
            const dateObject = new Date(horaApresentacao);
            const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
            return formattedTime;
        }
        else {
            return '';
        }
    };

    render() {
        const { horario } = this.state;
        const { isModal,
            classes,
            // pais 
        } = this.props;
        // const { paises } = this.state;
        // const { errors } = this.state;
        // const { tiposdocpessoal } = this.state;
        // const { cargos } = this.state;
        // const { nacionalidades } = this.state;
        // const { selectedCity } = this.state;
        // const empresa = loadCompany();

        // campos configuracoes
        // const codpais = getConfigField('codpais');
        // const exibecampodatanascpax = (getConfigField('exibecampodatanascpax') == ASIM);
        // const exibecampoidadepax = (getConfigField('exibecampoidadepax') == ASIM);
        // const exibecampopaispax = (getConfigField('exibecampopaispax') == ASIM);
        // const exibecampotipodocpessoal = (getConfigField('exibecampotipodocpessoal') == ASIM);
        // const exibecamporgpax = (getConfigField('exibecamporgpax') == ASIM);
        // const exibecampofamiliapax = (getConfigField('exibecampofamiliapax') == ASIM);
        // const exibecampocpfpax = (getConfigField('exibecampocpfpax') == ASIM);
        // const exibecampofonepax = (getConfigField('exibecampofonepax') == ASIM);
        // const exibecampocargopax = (getConfigField('exibecampocargopax') == ASIM);
        // const exibecampocidadepax = (getConfigField('exibecampocidadepax') == ASIM);
        // const exibecamposexopax = (getConfigField('exibecamposexopax') == ASIM);
        // const exibecampoemailpax = (getConfigField('exibecampoemailpax') == ASIM);
        // const exibecamponacionalidepax = (getConfigField('exibecamponacionalidepax') == ASIM);
        // const exibeorgaoemissdocpax = (getConfigField('exibeorgaoemissdocpax') == ASIM);
        // const temmascarapersonfone = (getConfigField('temmascarapersonfone') == ASIM);
        // const mascarapersonfone = (getConfigField('mascarapersonfone'));

        // configurações CRM
        // const enviasmscomservico = (getConfigField('enviasmscomservico', 'cfg_crm') == ASIM);        

        return (
            <Dialog open={isModal} onClose={this.handleCancel}>
                <DialogContent className={classes.modalContent}>

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Domingo"
                        name="dom"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.dom || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Segunda"
                        name="seg"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.seg || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Terça"
                        name="ter"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.ter || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Quarta"
                        name="qua"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.qua || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Quinta"
                        name="qui"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.qui || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Sexta"
                        name="sex"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.sex || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        // inputProps={{ maxLength: 50 }}
                        label="Sábado"
                        name="sab"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.sab || ''}
                    // variant="outlined"
                    // disabled={this.state.isView}
                    />

                    <br /><br />

                    <Box style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.onClickSave}
                            style={{ marginRight: '5px' }}
                        >
                            Salvar
                        </Button>

                        {isModal && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleCancel}
                            >
                                Cancelar
                            </Button>
                        )}
                    </Box>

                    {/* <div>
                        <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        />
                    </div> */}
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(HorarioPadraoServicoForm);