import React, { Component } from 'react';
import { Button, Box, Dialog, DialogContent, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import { diff } from 'deep-diff';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import { formatDateForInput, validarCPF, validateField } from 'lib/functions';
import { getConfigField, loadCompany } from '../../../lib/configUtils';
import Typography from '@material-ui/core/Typography';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    }
});

class TraducaoSisarePassengerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //configurations: null,
            //isLoadingConfig: true,

            traducao: {
                termoportuguesbr: '',
                termoespanholcl: '',
                termoinglesus: '',
                chave: this.props.chave,
            },
            errors: {},
            cpfError: false,
            schema: {
                termoportuguesbr: {
                    presence: { allowEmpty: false, message: 'Termo português obrigatório' },
                    length: { maximum: 100 },
                    type: 'string'
                },
            },
            isNewRecord: false,
            openAlert: false,
            messageAlert: '',
        };
    }

    async componentDidMount() {
        let chave = this.props.chave;
        let traducaoData;

        if (!(chave === undefined)) {
            if (this.props.chave === undefined) {
                traducaoData = this.props.traducao;
            } else {
                const params = `tbtraducaoweb.chave=${this.props.chave}`;
                const url = `/traducao/termos?${params}`;
                const response = await api.get(url);
                traducaoData = response.data[0];
                console.log('chave é definida');
                console.log('traducao data:' + JSON.stringify(response));
            }

            this.setState({
                traducao: traducaoData,
                traducao_original: JSON.parse(JSON.stringify(traducaoData)), // aplicar clonagem profunda. Se um campo no caso codcidade não
                isNewRecord: false                                 // atribuía em pax_original as mudanças ocorridas em
            });

        } else if (chave === 'novo') {
            this.setState({ isNewRecord: true });
            this.setState(prevState => ({
                traducao: {
                    ...prevState.traducao
                }
            }));
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            traducao: {
                ...prevState.traducao,
                [name]: value
            }
        }));
    };

    handleSave = () => {
        console.log('Dados salvos:', this.state);
        if (this.props.onSave) {
            this.props.onSave();
        }
    };

    handleCancel = () => {
        this.props.onClose();
    };

    // Campos para tratamento de exceção no OnChange
    nonUpperCaseFields = ['termoportuguesbr', 'termoespanholcl', 'termoinglesus'];
    integerFields = ['idade', 'codfamilia'];

    OnChange = (event) => {
        const { name, type, value, checked } = event.target;
        let adjustedValue = value;

        console.log(`name: ${name} > ${type} > ${value}`);

        switch (type) {
            case 'select-one':
                adjustedValue = name === 'sexo' ? value : Number(value);
                break;

            case 'date':
                adjustedValue = value;
                break;

            default:
                adjustedValue = value;

                break;
        }

        console.log(`Novo valor: ${adjustedValue}`);

        this.setState(prevState => ({
            traducao: {
                ...prevState.traducao,
                [name]: adjustedValue
            },
            errors: {
                ...prevState.errors,
                [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
            }
        }));
    }

    onClickSave = () => {
        if (this.state.isNewRecord) {
        } else {
            this.atualizaTraducao();
        }
    }

    validationRequiredFields = () => {
        const { traducao } = this.state;
        const { schema } = this.state;
        let errors = {};
        const termoportuguesbrError = validateField('termoportuguesbr', traducao.termoportuguesbr, schema);
        if (termoportuguesbrError) {
            errors.termoportuguesbr = termoportuguesbrError;
        }

        return errors;
    };


    /* Altera dados Traducao */
    atualizaTraducao = async () => {
        const { traducao, traducao_original } = this.state;
        const differences = diff(traducao_original, traducao);
        console.log(' o que está em diferences: ' + JSON.stringify(differences));
        if (!differences) {
            this.handleCancel();
            return;
        }

        const traducaoAlterado = {};
        differences.forEach(d => {
            if (d.kind === 'E' || d.kind === 'N') {
                traducaoAlterado[d.path.join('.')] = d.rhs;
            }
        });

        try {
            if (!(this.props.chave === undefined)) {
                const response = await api.put(`/traducao/${traducao.chave}`, traducaoAlterado);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.traducao);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    handleSubmit = () => {
        let errors = this.validationRequiredFields();
        console.log('the errors..' + JSON.stringify(errors))
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.onClickSave();
        }
    };

    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    render() {
        const { traducao } = this.state;
        const { isModal, classes } = this.props;
        const { errors } = this.state;
        return (
            <Dialog open={isModal} onClose={this.handleCancel}>
                <DialogContent className={classes.modalContent}>
                    <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                        style={{ fontWeight: 'bold', marginBottom: '1.5em' }}
                    >
                        {traducao.chave}
                    </Typography>

                    <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                    >Termo português BR
                    </Typography>
                    <FormGroup className={classes.formGroup}>
                        <textarea
                            id="termoportuguesbr"
                            name="termoportuguesbr"
                            onChange={this.OnChange}
                            className={classes.textarea}
                            value={traducao.termoportuguesbr || ''}
                            disabled={this.state.isView}

                        />
                    </FormGroup>

                    <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                    >Termo Espanhol CL
                    </Typography>
                    <FormGroup className={classes.formGroup}>
                        <textarea
                            id="termoespanholcl"
                            name="termoespanholcl"
                            onChange={this.OnChange}
                            className={classes.textarea}
                            value={traducao.termoespanholcl || ''}
                            disabled={this.state.isView}

                        />
                    </FormGroup>

                    <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                    >Termo Inglês US
                    </Typography>
                    <FormGroup className={classes.formGroup}>
                        <textarea
                            id="termoinglesus"
                            name="termoinglesus"
                            onChange={this.OnChange}
                            className={classes.textarea}
                            value={traducao.termoinglesus || ''}
                            disabled={this.state.isView}

                        />
                    </FormGroup>
                    <br /><br />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        style={{ marginRight: '10px' }}
                    >
                        Salvar
                    </Button>

                    {isModal && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleCancel}
                        >
                            Cancelar
                        </Button>
                    )}

                    <div>
                        <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(TraducaoSisarePassengerForm);