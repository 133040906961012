import {
    Button, CardContent, FormControl, FormControlLabel, FormGroup, Switch, TextField,
    FormLabel, RadioGroup, Radio, FormHelperText, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import api from '../../../services/api';
import { ANAO, ASIM, ATIPO_CONTA_BANCO, ATIPO_MOTIVO_CANCELAMENTO_SERVICO, ATIPO_MOTIVO_TROCA_SERVICO } from 'consts';
import PropTypes from 'prop-types';
import AlertInformation from 'components/AlertInformation/AlertInformation';

class MotivoCancelamentoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            motivocancelamento: {},
            isNewRecord: false,
            isView: false,
            schema: {
                usuario: {
                    presence: { allowEmpty: false, message: 'é obrigatório' },
                    length: {
                        maximum: 64
                    }
                },
            },

            useStyles: makeStyles(theme => ({
                root: {
                    backgroundColor: theme.palette.background.default,
                    height: '100%'
                },
                grid: {
                    height: '100%'
                },
                quoteContainer: {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                quote: {
                    backgroundColor: theme.palette.neutral,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(/images/auth.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                },
                quoteInner: {
                    textAlign: 'center',
                    flexBasis: '600px'
                },
                quoteText: {
                    color: theme.palette.white,
                    fontWeight: 300
                },
                name: {
                    marginTop: theme.spacing(3),
                    color: theme.palette.white
                },
                bio: {
                    color: theme.palette.white
                },
                contentContainer: {},
                content: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                contentHeader: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: theme.spacing(5),
                    paddingBototm: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2)
                },
                logoImage: {
                    marginLeft: theme.spacing(4)
                },
                contentBody: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                },
                form: {
                    paddingLeft: 100,
                    paddingRight: 100,
                    paddingBottom: 125,
                    flexBasis: 700,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2)
                    }
                },
                title: {
                    marginTop: theme.spacing(3)
                },
                socialButtons: {
                    marginTop: theme.spacing(3)
                },
                socialIcon: {
                    marginRight: theme.spacing(1)
                },
                sugestion: {
                    marginTop: theme.spacing(2)
                },
                textField: {
                    marginTop: theme.spacing(2)
                },
                signInButton: {
                    margin: theme.spacing(2, 0)
                },
                alert: {
                    color: '#FF0000',
                }
            })),
        };
    }

    async componentDidMount() {
        // carrega informações formulário
        const { id } = this.props.match.params;
        const { location } = this.props;
        const currentPath = location.pathname;

        if (id >= 0) {
            const response = await api.get(`/motivocancelamento/${id}`);
            this.setState({
                motivocancelamento: response.data[0],
                isNewRecord: false,
                isView: (currentPath.includes('/view'))
            }
            );
        }
        else if (id === 'novo') {
            this.setState({
                isNewRecord: true
            });
            this.state.isNewRecord = true;
            const { motivocancelamento } = this.state;
            motivocancelamento.tipo = ATIPO_MOTIVO_CANCELAMENTO_SERVICO;
        }
    }

    /* evento on change componentes */
    OnChange = (event) => {
        const { motivocancelamento } = this.state;
        const input = event.target;
        const cursorPosition = input.selectionStart;        
        const { name, type, value, checked } = event.target;        

        let adjustedValue = value; // Valor ajustado com base no tipo do input      

        // Decide o tratamento com base no tipo do input ou nome
        switch (type) {
            case 'radio':
                // Converte a string numérica para número antes de salvar
                adjustedValue = Number(value);
                break;
            case 'checkbox':
                // Usa o valor checked para campos do tipo checkbox
                // Tratamentos adicionais específicos para campos
                if (name === 'inativo') {
                    if (checked === false) {
                        adjustedValue = ASIM;
                    }
                    else {
                        adjustedValue = ANAO;
                    }
                }

                if (name === 'solicitadopelocliente') {
                    if (checked === false) {
                        adjustedValue = ANAO;
                    } else {
                        adjustedValue = ASIM;
                    }
                }

                break;
            default:
                // Converte valor para maiúsculas
                adjustedValue = value.toUpperCase();
                break;
        }

        // Atualiza o estado com o valor ajustado
        motivocancelamento[name] = adjustedValue;
        this.setState({ motivocancelamento: motivocancelamento }, () => {
            // Depois que o estado é atualizado e o componente é renderizado novamente, você reposiciona o cursor
            if (input.type === "text") { // Confirma que é um campo de texto para evitar erros com outros tipos de input
                input.setSelectionRange(cursorPosition, cursorPosition);
            }
        });
    };

    /* atualiza dados de motivo cancelamento existente - post */
    atualizaMotivoCancelamento = async () => {
        const { motivocancelamento } = this.state;
        console.log(motivocancelamento);
        try {
            const response = await api.post(`/motivocancelamento/${motivocancelamento.codmotivo}`, motivocancelamento);
            if (response.status === 200) {
                this.props.history.push('/front/motivoscancelamentos');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Click salvar */
    onClickSave = () => {
        if (this.validationRequiredFields()) {
            if (this.state.isNewRecord) {
                this.insereMotivoCancelamento()
            }
            else {
                this.atualizaMotivoCancelamento();
            }
        }
    }

    /* Click cancelar */
    onClickCancel = () => {
        this.props.history.push('/front/motivoscancelamentos');
    }

    /* valida campos obrigatórios ao salvar */
    validationRequiredFields() {
        const { motivocancelamento } = this.state;
        let retorno = true;

        if ((String(motivocancelamento.descmotivo).trim() === '') || (motivocancelamento.descmotivo === undefined)) {
            motivocancelamento.descmotivo = '';
            this.setState({ motivocancelamento: motivocancelamento });
            retorno = false;
        };

        return retorno;
    }

    /* insere novo motivo cancelamento */
    insereMotivoCancelamento = async () => {
        const { motivocancelamento } = this.state;
        motivocancelamento.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
        try {
            const response = await api.post(`/motivocancelamento`, motivocancelamento);
            if (response.status === 200) {
                this.props.history.push('/front/motivoscancelamentos');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    render() {
        const { motivocancelamento } = this.state;
        return (
            <div>
                <CardContent>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Motivo de cancelamento / troca {motivocancelamento.codmotivo}
                    </Typography>

                    <br />

                    <FormGroup>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Motivo cancelamento / troca"
                            style={{ marginTop: '10px' }}
                            name="descmotivo"
                            value={motivocancelamento.descmotivo || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            helperText={motivocancelamento.descmotivo === '' ? 'Motivo cancelamento / troca obrigatório' : ' '}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormGroup>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={!(motivocancelamento.inativo === ASIM)}
                                name="inativo"
                                onChange={this.OnChange}
                                color="primary"
                                disabled={this.state.isView}
                            />
                        }
                        label={(motivocancelamento.inativo === ASIM) ? 'Inativo' : 'Ativo'}
                    />

                    <br />
                    <br />

                    <FormControl disabled={this.state.isView}>
                        <FormLabel>Tipo</FormLabel>
                        <RadioGroup
                            row
                            name="tipo"
                            value={motivocancelamento.tipo || ''}
                            onChange={this.OnChange}
                        >
                            <FormControlLabel value={ATIPO_MOTIVO_CANCELAMENTO_SERVICO} control={<Radio />} label="Cancelamento" />
                            <FormControlLabel value={ATIPO_MOTIVO_TROCA_SERVICO} control={<Radio />} label="Troca" />
                        </RadioGroup>
                        <FormHelperText>{motivocancelamento.tipo === '' ? 'Tipo obrigatório' : ' '}</FormHelperText>
                    </FormControl>

                    <br />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(motivocancelamento.solicitadopelocliente === ASIM)}
                                name="solicitadopelocliente"
                                onChange={this.OnChange}
                                color="primary"
                                disabled={this.state.isView}
                            />}
                        label={'Solicitado pelo cliente'}
                    />

                    <br />
                    <br />

                    <Button
                        name="salvar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSave}
                        style={{ marginTop: '10px' }}
                        disabled={this.state.isView}
                    >
                        Salvar
                    </Button>

                    <Button
                        name="cancelar"
                        variant="contained"
                        onClick={this.onClickCancel}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                        color="secondary"
                    >
                        Cancelar
                    </Button>
                </CardContent>

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

MotivoCancelamentoForm.propTypes =
{
    classes: PropTypes.object.isRequired
};

export default MotivoCancelamentoForm;