import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import api from '../../../services/api';
import InputLabel from '@material-ui/core/InputLabel';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'

const ComboPDV = props => {
  //const {  ...rest } = props;
  //const classes = useStyles();
  const [pdvsx, setPDVs] = useState({
    pdvs: []
  });


  const handleChangePDV = event => {
    const value = event.target.value;
    setSelectedPDV(value);
    sessionStorage.setItem('codPDV', value);
  };

  const [selectedPDV, setSelectedPDV] = useState(parseInt(sessionStorage.getItem('codPDV'), 10) || '');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    async function loadPDVs() {
      const response = await api.get('/pdv/0')
        .then(function (response) {
          console.log('lista de PDVs: ' + JSON.stringify(response.data));
          //setSelectedPDV(response.data[0].codpdvconfig);
          sessionStorage.setItem('codPDV', response.data[0].codpdvconfig); // guardando o primeiro PDV 
          setPDVs({ pdvs: response.data });
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      const termsNeeded = [CommonTranslations.LABEL_USER,
      CommonTranslations.LABEL_PDV]; // Lista de termos da página
      i18n.fetchTerms(termsNeeded)
        .then(translations => {
          setTranslations(translations);
          console.log('teste>>' + JSON.stringify(translations)); // Move o console.log para depois da atualização
        })
        .catch(error => {
          console.error('Erro ao carregar traduções:', error);
        });



    }

    loadPDVs();
  }, []);



  function ListaPDVs() {

    var pdvs = pdvsx.pdvs;
    const listPDVs = pdvs.map(
      (pdv) => {
        return (
          <option
            key={pdv.codpdvconfig}
            value={pdv.codpdvconfig}
          >{pdv.descpdv}</option>
        )
      }
    )

    const lblPDV = translations[CommonTranslations.LABEL_PDV];


    return (
      <div>
        <InputLabel
          htmlFor="outlined-age-native-simple"
          style={{ display: (listPDVs.length > 0 ? '' : 'none') }}
        // show when length > 1 company
        >{lblPDV || "Ponto de Venda"}</InputLabel>
        <Select
          fullWidth
          inputProps={{
            id: 'outlined-age-native-simple',
            name: 'cmbPDV'
          }}
          label=""
          native
          onChange={handleChangePDV}
          //onClick={rest.onClick}
          size
          style={{ display: (listPDVs.length > 0 ? '' : 'none') }}
          value={selectedPDV}
        >
          {listPDVs}
        </Select>
      </div>
    )
  }



  return (
    <ListaPDVs />

  );
};

ComboPDV.propTypes = {
  className: PropTypes.string
};

export default ComboPDV;