import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboCidade = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [cidadesx, setCidades] = useState({
        cidades: []
    });

    useEffect(() => {

        async function loadCidades() {
            let params = [];

            params += '?sort=desccidade';

            if (rest.inativo) {
                params += '&tbcidade.inativo=' + rest.inativo;
            }

            try {
                const response = await api.get('/cidade' + params);
                if (response.status === 200) {
                    setCidades({ cidades: response.data });

                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        loadCidades();
    }, []);

    function Cidades() {
        var listCidades = cidadesx.cidades;
        return (
            <div>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    value={rest.value}                    
                >
                    <option
                        aria-label="Não Definido"
                        value="0"
                    />
                    {listCidades.map(cidade => (
                        <option
                            key={cidade.codcidade}
                            value={cidade.codcidade}
                        >
                            {cidade.desccidade}
                        </option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Cidades />
    );
};

ComboCidade.propTypes = {
    className: PropTypes.string
};

export default ComboCidade;