/* termos comuns utilizados em todo sistema */
export const CommonTranslations = {
  BUTTON_SAVE: 'btn_save',
  BUTTON_CANCEL: 'btn_cancel',
  BUTTON_DELETE : 'btn_delete', 
  BUTTON_NEW : 'btn_new',
  CHECKBOX_INATIVO : 'chk_inativo',
  CHECKBOX_ATIVO: 'chk_ativo',
  /* labels padrões */
  LABEL_USER : 'lbl_user',
  LABEL_PASSWORD : 'lbl_password',
  LABEL_PDV: 'lbl_pdv',



  /* itens de Menu Front */
  MENU_ITEM_CADASTRO :'menuitem_cadastro',
  MENU_ITEM_SERVICOS :'menuitem_servicos',
  MENU_ITEM_ORDEMSERVICO :'menuitem_ordemservico',
  MENU_ITEM_CLIENTE :'menuitem_cliente',
  MENU_ITEM_CATEGORIASERVICOS: 'menuitem_categoriasservicos',
  MENU_ITEM_MOTIVOCANCELAMENTO: 'menuitem_motivocancelamento',
  
  

  /* itens de todos menus */
  MENU_ITEM_SAIR : 'menuitem_sair',
  /* itens aba geral */
  TABSHEET_GERAL : 'tabsheet_geral',

  /* colunas de grids */
  GRID_COLUMN_ACOES: 'gridcolumn_acoes'



};


export const Dashboard_Front_Translations = {
  LABEL_CHEGADAS: 'lbl_dash_front_chegadas',
  LABEL_PARTIDAS: 'lbl_dash_front_partidas',
  LABEL_PAX_DESTINO: 'lbl_dash_front_paxdestino'

};


export const Form_OS_Translations = {
  FORM_TITLE: 'form_OS_title_form',
};



export const Form_CategoriaServico_Translations = {
  FORM_TITLE: 'form_categoriaservico_title_form',
};

export const list_CategoriaServico_Translations = {
  FORM_TITLE: 'form_categoriaservico_grid_column',
};

