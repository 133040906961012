// src/utils/configUtils.js

/* Salva configurações no localStorage */
export const saveConfigurations = (configurations, nameConfiguration) => {
  sessionStorage.setItem(nameConfiguration, JSON.stringify(configurations));
};

/* carrega configurações armazenadas no localStorage */
export const loadConfigurations = (nameConfiguration) => {
  const storedConfigs = sessionStorage.getItem(nameConfiguration);
  if (storedConfigs) {
    return JSON.parse(storedConfigs);
  }
  return null;
};

/* pega informação configuração específica */
export const getConfigField = (field, nameConfiguration) => {
  let nameCfg = nameConfiguration;
  if (((nameCfg) === "") || (nameCfg === undefined)){
    nameCfg = 'cfg';
  }
  //console.log('passando.. vai consultar: ' + nameCfg);
  const configs = loadConfigurations(nameCfg);
   if (configs && typeof configs === 'object') {
    return configs[field];
  }
  return null;
};


/* Salva Empresa no sessionStorage */
export const saveCompany = (company) => {
  console.log('vai realizar o savecompany..')
  sessionStorage.setItem('empresa', JSON.stringify(company));
};


/* carrega empresa armazenada no localStorage */
export const loadCompany = () => {
  //console.log('load......comp')
  const storedCompany = sessionStorage.getItem('empresa');
  if (storedCompany) {
    //console.log('loadcompany..: ' + storedCompany);
    return JSON.parse(storedCompany);
  }
  return null;
};
/* pega informação específica empresa*/
export const getCompanyField = (field) => {
  const company = loadCompany();
   if (company && typeof company === 'object') {
    return company[field];
  }
  return null;
};



/* carrega PDV armazenada no localStorage */
export const loadPDV = () => {
  const storedPDV = sessionStorage.getItem('pdv');
  if (storedPDV) {
    return JSON.parse(storedPDV);
  }
  return null;
};
/* Salva PDV no sessionStorage */
export const savePDV = (pdv) => {
  console.log('vai realizar o savePDV..')
  sessionStorage.setItem('pdv', JSON.stringify(pdv));
};


/* pega campo específico PDV */
export const getPDVField = (field) => {
  const pdv = loadPDV();
   if (pdv && typeof pdv === 'object') {
    return pdv[field];
  }
  return null;
};
