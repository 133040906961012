import React, { Component } from 'react';
import _ from 'lodash'; // responsável por controlar chamada aguardando um tempo - melhorando experiência
import api from '../../../services/api';
import { TextField, CircularProgress, MenuItem, Paper } from '@material-ui/core';

class SearchCidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      cities: [],
      loading: false,
    };

    this.debounceFetchCities = _.debounce(this.fetchCities, 500);
  }

  componentDidMount() {
    // Se há uma cidade padrão passada como prop, inicializa o estado com ela
    const { defaultCity } = this.props;

    console.log('o que está em default city[1]: ' + JSON.stringify(defaultCity))

    if (defaultCity && defaultCity.codcidade != null) {
      console.log('o que está em default city: ' + JSON.stringify(defaultCity));
      this.setState({
        query: `${defaultCity.desccidade} - ${defaultCity.sigla}`
      });
    } else {
      console.log('entrou no debaixo..');
    }
  }

  fetchCities = async (searchTerm) => {
    this.setState({ loading: true });
    try {
      const response = await api.get(`/cidade?tbcidade.desccidade=_like_${searchTerm}`);
      this.setState({ cities: response.data, loading: false });
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      this.setState({ loading: false });
    }
  };

  handleInputChange = (event) => {
    const value = event.target.value.toUpperCase();
    console.log('o que está no handleInputChange: ' + value)
    this.setState({ query: value });

    if (value) {
      console.log('entrou value do handleinputchange..')
      this.debounceFetchCities(value);
    } else {
      console.log('value do handleinputchange sem valor')
      this.setState({ cities: [] });
      this.handleCitySelect({codcidade:null, desccidade: "", sigla:""});
    }
  };

  handleCitySelect = (city) => {
    // Concatena o nome da cidade com o estado

    console.log('o que está em city..: ' + JSON.stringify(city))
    const cityWithState = `${city.desccidade} - ${city.sigla}`;
    this.setState({ query: cityWithState, cities: [] });
    this.props.onCitySelect(city); // Passa a cidade selecionada para o componente pai
  };

  render() {
    const { query, cities, loading } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <TextField
          label="Cidade"
          value={query}
          onChange={this.handleInputChange}
          fullWidth
        />
        {loading && <CircularProgress size={24} style={{ position: 'absolute', right: '10px', top: '50%', marginTop: '-12px' }} />}
        {cities.length > 0 && (
          <Paper style={{ position: 'absolute', top: '100%', left: 0, right: 0, maxHeight: '200px', overflowY: 'auto', zIndex: 1 }}>
            {cities.map((city, index) => (
              <MenuItem
                key={index}
                onClick={() => this.handleCitySelect(city)}
                style={{ padding: '8px', cursor: 'pointer' }}
              >
                {city.desccidade} - {city.sigla}
              </MenuItem>
            ))}
          </Paper>
        )}
      </div>
    );
  }
}

export default SearchCidade;
