import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { TotalPaxTransito, TotalPaxChegando, TotalPaxPartindo } from './components';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'

const useStyles = makeStyles(() => ({
  root: {
    padding: 16, // valor de padding fixo, simplificado sem tema
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [totalPaxTransito, setTotalPaxTransito] = useState(0);
  const [totalPaxChegando, setTotalPaxChegando] = useState(0); // Novo estado para total de pax partindo
  const [totalPaxPartindo, setTotalPaxPartindo] = useState(0); // Novo estado para total de pax partindo

  useEffect(() => {
    const loadDashBoard = async () => {
      try {
        let codempresa = parseInt(sessionStorage.getItem('codEmpresa'), 10);

        const responseTransito = await api.get(`/dashboard/front/paxdestino/${codempresa}` );
        console.log('Total de Pax em Trânsito: ' + JSON.stringify(responseTransito.data.totaltransito));
        setTotalPaxTransito(responseTransito.data[0].totaltransito);

        // Chamada para obter total de passageiros partindo
        const responseChegando = await api.get(`/dashboard/front/paxchegando/${codempresa}`); // Ajuste a rota conforme necessário
        console.log('Total de Pax Chegando: ' + JSON.stringify(responseChegando.data.totalchegando));
        setTotalPaxChegando(responseChegando.data[0].totalchegando);


        // Chamada para obter total de passageiros partindo
        const responsePartindo = await api.get(`/dashboard/front/paxpartindo/${codempresa}`); // Ajuste a rota conforme necessário
        console.log('Total de Pax Partindo: ' + JSON.stringify(responsePartindo.data.totalpartindo));
        setTotalPaxPartindo(responsePartindo.data[0].totalpartindo);

      } catch (error) {
        console.error(error);
      }
    };

    loadDashBoard();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalPaxTransito totalPaxTransito={totalPaxTransito} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalPaxChegando totalPaxTransito={totalPaxChegando} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalPaxPartindo totalPaxTransito={totalPaxPartindo} /> {/* Passa o total de pax partindo */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
