import React, { Component } from 'react';
import './PassengerScreen.css'; // Arquivo CSS para estilização

class PassengerScreen extends Component {
  componentDidMount() {
    this.enterFullScreen();
  }

  componentWillUnmount() {
    this.exitFullScreen();
  }

  enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { // Para Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { // Para Chrome, Safari e Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { // Para IE/Edge
      document.documentElement.msRequestFullscreen();
    }
  };

  exitFullScreen = () => {
    if (
      document.fullscreenElement || // Padrão moderno
      document.webkitFullscreenElement || // Para Chrome, Safari e Opera
      document.mozFullScreenElement || // Para Firefox
      document.msFullscreenElement // Para IE/Edge
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Para Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Para Chrome, Safari e Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // Para IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  render() {
    const { passengerName, companyLogo } = this.props;
    return (
      <div className="passenger-screen">
        <div className="company-logo">
          <img src={companyLogo} alt="Company Logo" />
        </div>
        <div className="passenger-name">
          {passengerName}
        </div>
      </div>
    );
  }
}

export default PassengerScreen;
