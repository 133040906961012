import React, { Component } from 'react';
import {
  Button, Card, CardActions, CardContent, TablePagination, Input, Paper, Select, TextField,
  TableHead, TableRow, Table, TableCell, TableBody, FormControlLabel, Switch
} from '@material-ui/core';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import { ANAO, AOS_ABERTA, AOS_CANCELADA, AOS_ENCERRADA, ASIM, ATIPO_TRANSFER_IN, ATIPO_TRANSFER_OUT } from '../../../consts';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { formatData, formatDateForInput } from 'lib/functions';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'
import { loadConfigurations, getConfigField, loadCompany } from '../../../lib/configUtils';
import { CircularProgress } from '@material-ui/core';


class OSList extends Component {
  state = {
    itensos: [],
    searchFilter: {
      situacaoOSFilter: AOS_ABERTA,
      nroOSFilter: '',
      dataServicoFilter: '',
      horaServicoFilter: '',
      tipoServicoFilter: 0,
      nomePaxFilter: '',
      permiteOSSemServico: ANAO,
    },
    translations: {},
    page: 0,
    rowsPerPage: 50,
    isLoadingItens: false

  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
    }
  }

  constructor(props) {
    super(props);
    this.page = 0; // Página atual
    this.rowsPerPage = 50; // Número de itens por página
    this.hasMore = true; // Controle se ainda há mais dados para carregar
    this.loading = false; // Controle de carregamento

  }

  async componentDidMount() {
    let date = moment().format('YYYY-MM-DD');
    //console.log('a data é: ' + date);
    this.performsTranslation();
    window.addEventListener('scroll', this.handleScroll); // Adiciona o evento de scroll


    const { searchFilter } = this.state;
    //searchFilter['dataServicoFilter'] = moment(date, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
    searchFilter['dataServicoFilter'] = date;

    if (getConfigField('permiteossemservico') === ASIM) {
      searchFilter['permiteOSSemServico'] = ASIM;
    }

    this.setState({ searchFilter: searchFilter });
    this.loadItensOS();
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Remove o evento de scroll ao desmontar
  }

  handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1) {
      if (this.hasMore && !this.loading) {
        console.log('vai incrementar página e buscar nova faixa..')
        this.page++; // Incrementa a página
        this.loadItensOS(); // Carrega mais dados
      }
    }
  };


  /* pesquisa itens */
  loadItensOS = async (opcao) => {
    const { searchFilter } = this.state;
    //    let params = '';
    // Verifica se já está carregando ou se não há mais itens para carregar
    if (this.loading || !this.hasMore) return;

    this.loading = true; // Define como carregando


    this.setState({ isLoadingItens: true });  // Inicia o carregamento
    let params = `?page=${this.page + 1}&limit=${this.rowsPerPage}`;

    if (searchFilter.nroOSFilter > 0) {
      params += `?tbos.nroos=${searchFilter.nroOSFilter}`;
    };

    if (searchFilter.dataServicoFilter) {
      params += `${params ? '&' : '?'}tbitensos.dataservico=${searchFilter.dataServicoFilter}`;
    };

    if (searchFilter.horaServicoFilter) {
      console.log('entrou em horaServicoFilter:' + searchFilter.horaServicoFilter);
      params += `${params ? '&' : '?'}tbitensos.horainservico=${searchFilter.horaServicoFilter}`;
    };

    if (searchFilter.tipoServicoFilter) {
      console.log('o que está em searchFilter.tiposervicofilter: ' + searchFilter.tipoServicoFilter)
      if (searchFilter.tipoServicoFilter == 1) {
        params += `${params ? '&' : '?'}tbservico.transferin=${ASIM}`;
      }
      else
        if (searchFilter.tipoServicoFilter == 2) {
          params += `${params ? '&' : '?'}tbservico.transferout=${ASIM}`;
        }
        else
          if (searchFilter.tipoServicoFilter == 3) {
            params += `${params ? '&' : '?'}tbservico.servicopasseio=${ASIM}`;
          }
    }
    /* situacao da OS */
    if (searchFilter.situacaoOSFilter) {
      if (searchFilter.situacaoOSFilter == AOS_ABERTA) {
        params += `${params ? '&' : '?'}tbos.situacao=${AOS_ABERTA}`;
      }
      else
        if (searchFilter.situacaoOSFilter == AOS_ENCERRADA) {
          params += `${params ? '&' : '?'}tbos.situacao=${AOS_ENCERRADA}`;
        }
        else
          if (searchFilter.situacaoOSFilter == AOS_CANCELADA) {
            params += `${params ? '&' : '?'}tbos.situacao=${AOS_CANCELADA}`;
          }
    }

    if (searchFilter.nomePaxFilter) {
      params += `${params ? '&' : '?'}tbpaxitens.nomepax=_like_${searchFilter.nomePaxFilter}`;
      //params += await api.get(`/cidade?tbcidade.desccidade=_like_${searchTerm}`);
    }

    /* Se marcado opção OS sem serviço */
    if (searchFilter.permiteOSSemServico === ASIM) {
      params += `${params ? '&' : '?'}cfg_permiteossemservico=${searchFilter.permiteOSSemServico}`;
    }
    console.log('o que está em searchFilter: ' + JSON.stringify(searchFilter));

    //const response = await api.get(`/os-itens` + params);



    try {
      //const response = await api.get(`/categoriaservico${params}`);
      const response = await api.get(`/os-itens${params}`);
      console.log('resposta: ' + JSON.stringify(response));

      const newData = response.data.data;
      //      this.setState({ itensos: response.data.data });
      //      this.setState({ isLoadingItens: false });  // Inicia o carregamento

      this.setState(prevState => ({
        itensos: [...prevState.itensos, ...newData],
        totalItems: parseInt(response.data.info['totalrecords'], 10) || 0,
      }));

      // Verifica se ainda há mais dados para carregar
      this.hasMore = newData.length === this.rowsPerPage;
    } catch (error) {
      console.error("Erro ao carregar itens de OS: ", error);
    } finally {
      this.loading = false; // Define como falso quando finalizado
    }
  }


  /* Mudança na quantidade de linhas por página */
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
      this.loadItensOS();
    });
  };


  /* Mudança de página */
  handleChangePage = (event, newPage) => {
    console.log('a nova página: ' + newPage);
    this.setState({ page: newPage }, () => {
      this.loadItensOS();
    });
  };

  /* onBlur - evento ao sair controle - se torna mais rapido quando
  tem muitos registros no retorno*/
  onInputBlur = (e) => {
    const { searchFilter } = this.state;
    //searchFilter.nomeFantasiaClienteFilter = e.target.value;
    console.log('passando no onblur: ' + e.target.name)
    if (e.target.name === 'nomepax') {
      //      if (!(e.target.value === '')) {
      searchFilter[e.target.name] = e.target.value.toUpperCase();
      //    }
      //console.log('passou onblue nome fantasia...')
    }
    else {
      searchFilter[e.target.name] = e.target.value;
    }

    if (!(e.target.value === '')) {
      this.setState({ searchFilter: searchFilter });
    }

  }

  LimpaFiltros = () => {
    const dataAtual = new Date();
    this.setState({
      searchFilter: {
        dataServicoFilter: formatDateForInput(dataAtual),
        nomePaxFilter: '',
        nroOSFilter: '',
        situacaoOSFilter: AOS_ABERTA,
        horaServicoFilter: ' ',
        tipoServicoFilter: 0

      },

      itensos: [], // Limpa a lista ao limpar filtros
    }, () => {
      this.page = 0; // Reinicia o contador de página
      this.hasMore = true; // Permite mais dados
      this.loadItensOS(); // Carrega categorias após limpar filtros
    });
  };



  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    const input = event.target;

    // Verifica se é um switch (checkbox) e usa checked, senão usa value
    let value = input.type === 'checkbox' ? input.checked ? ASIM : 'N' : input.value;

    if (input.name === 'nomePaxFilter') {
      value = value.toUpperCase();
    }
    // Atualiza o estado
    searchFilter[input.name] = value;

    this.setState({ searchFilter: searchFilter });
  };

  formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };


  /* Realiza a tradução */
  async performsTranslation() {
    const termsNeeded = [CommonTranslations.GRID_COLUMN_ACOES];
    // Lista de termos da página
    const translations = await i18n.fetchTerms(termsNeeded);
    this.setState({ translations });
  }


  // Reinicializa e pesquisa com novos filtros
  PesquisarItensOS = () => {
    this.setState({ itensos: [] }, () => {
      this.page = 0; // Reinicia a página
      this.hasMore = true; // Permite mais dados
      this.loadItensOS(); // Faz a nova busca
    });
  };

  render() {
    const { itensos, isLoadingItens } = this.state;
    const { searchFilter } = this.state;
    const { translations } = this.state;
    const permiteossemservico = (getConfigField('permiteossemservico') == ASIM);

    return (
      <div style={this.estilo}>
        <div>
          <span />
          <div>
            <Link to="/front/os/novo">
              <Button
                color="primary"
                style={this.estilo.addButton}
                variant="contained"
              >
                Novo
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <Paper
            hover
            size
            style={{ marginBottom: 10, marginTop: 10 }}
          >
            <Input
              id="nroOSFilter"
              name="nroOSFilter"
              onChange={this.OnChangeSearch}
              //onBlur={this.onInputBlur}
              placeholder="OS"
              style={{ marginTop: 15, marginLeft: 15, marginBottom: 10, width: 100 }}
              value={this.state.searchFilter.nroOSFilter}
            //type="number"
            />

            <TextField
              InputLabelProps={{ shrink: true, required: false }}
              inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="Data do Serviço"
              id="dataServicoFilter"
              name="dataServicoFilter"
              onChange={this.OnChangeSearch}
              //onBlur = {this.onInputBlur}                        
              //type="datetime-local"              
              style={{ marginLeft: 20 }}
              //type="date"              
              type="date"
              value={searchFilter.dataServicoFilter || ''}
            //variant="outlined"
            />

            <TextField
              InputLabelProps={{ shrink: true, required: false }}
              inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="Hora"
              id="horaServicoFilter"
              name="horaServicoFilter"
              onChange={this.OnChangeSearch}
              //onBlur = {this.onInputBlur}                        
              //type="datetime-local"              
              style={{ marginLeft: 20 }}
              type="Time"
              //type="hour"              
              value={searchFilter.horaServicoFilter || ''}
            //variant="outlined"
            />

            <Select
              id="tipoServicoFilter"
              name="tipoServicoFilter"
              native
              //onBlur = {this.onInputBlur}  
              onChange={this.OnChangeSearch}
              style={{ marginLeft: 20 }}
              value={searchFilter.tipoServicoFilter}
            >

              <option
                key={0}
                value={0}
              >
                {'Todos'}</option>

              <option
                key={ATIPO_TRANSFER_IN}
                value={ATIPO_TRANSFER_IN}
              >
                {'IN'}</option>
              <option
                key={ATIPO_TRANSFER_OUT}
                value={ATIPO_TRANSFER_OUT}
              >
                {'OUT'}</option>

              <option
                key={3}
                value={3}
              >
                {'Opcionais'}</option>

            </Select>


          </Paper>

          <Paper>
            <Select
              id="situacaoOSFilter"
              name="situacaoOSFilter"
              native
              onChange={this.OnChangeSearch}
              //onBlur={this.onInputBlur}
              style={{ marginLeft: 20 }}
              value={searchFilter.situacaoOSFilter}
            >
              <option
                key={AOS_ABERTA}
                value={AOS_ABERTA}
              >
                {'Abertas'}</option>

              <option
                key={AOS_ENCERRADA}
                value={AOS_ENCERRADA}
              >
                {'Encerradas'}</option>
              <option
                key={AOS_CANCELADA}
                value={AOS_CANCELADA}
              >
                {'Canceladas'}</option>

              <option
                key={''}
                value={''}
              >
                {'Todas'}</option>
            </Select>

            {permiteossemservico && (
              <FormControlLabel
                control={
                  <Switch
                    checked={searchFilter.permiteOSSemServico === ASIM}  // Define se está marcado ou não
                    name="permiteOSSemServico"
                    onChange={this.OnChangeSearch}
                    color="primary"
                  />
                }
                label={searchFilter.permiteOSSemServico === ASIM ? "OS Sem Serviço" : "OS Sem Serviço"}
              />
            )}


          </Paper>

          <Paper
            hover
            style={{ marginBottom: 10 }, { marginTop: 10 }}
          >
            <Input
              autoFocus
              id="nomePaxFilter"
              name="nomePaxFilter"
              onChange={this.OnChangeSearch}
              //onBlur={this.onInputBlur}
              placeholder="Nome Pax"
              style={{ marginBottom: 10, width: 300, marginLeft: 10 }}
              value={searchFilter.nomePaxFilter}
            />
            <Button
              color="primary"
              id="pesquisar"
              onClick={this.PesquisarItensOS}>
              <SearchIcon />
            </Button>

            {/* Exibe o círculo de carregamento enquanto os dados estão sendo carregados */}
            {/*this.loading && <CircularProgress size={24} />*/}

            <Button
              color="primary"
              onClick={() => this.LimpaFiltros()}
            >
              <ClearAllIcon />
            </Button>

          </Paper>
        </div>
        <div>
          <br />
        </div>

        <div>
          <Card
          //{...rest}
          //className={clsx(classes.root, className)}
          >
            <CardContent>
              <PerfectScrollbar>
                <div >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data Serviço</TableCell>
                        <TableCell>Hs</TableCell>
                        <TableCell>Hs Apres.</TableCell>
                        <TableCell>Serviço</TableCell>
                        <TableCell>Número OS</TableCell>
                        <TableCell>Pax</TableCell>
                        <TableCell>{translations[CommonTranslations.GRID_COLUMN_ACOES] || 'Ações'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itensos.slice(0, 1000).map(item => (
                        <TableRow
                          hover
                          key={item.codItemOS}
                        >
                          <TableCell width="100">{formatData(item.dataservico)}</TableCell>
                          <TableCell width="5">{this.formatTime(item.horainservico)}</TableCell>
                          <TableCell width="5">{this.formatTime(item.horaapresentacao)}</TableCell>
                          <TableCell width="50">{item.descservico}</TableCell>
                          <TableCell width="10">{item.nroos}</TableCell>
                          <TableCell width="10">
                            <Tooltip
                              onClose={this.handleTooltipClose}
                              onOpen={this.handleTooltipOpen}
                              open={this.state.open}
                              //title={item.paxitem[0].nomepax}
                              title={item.paxitem.map(pax => pax.nomepax).join(' / ')}
                            >
                              <Button>{item.paxitem[0].nomepax}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell width="100">
                            <Link to={`/front/os/${item.codos}`}>
                              <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                            </Link>

                            <Link to={`/front/os/${item.codos}/view`}>
                              <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                            </Link>


                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </div>

        {/* <TablePagination
          component="div"
          count={itensos.length}
          labelRowsPerPage={'Registros por página'}
          //labelDisplayedRows={'sdfsdfds', 'sdfsd', 'sdfsd', 'sdfsd'}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          //page={page}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          //rowsPerPageOptions={['30', 50, { value: 10, label: 'All' }]}            
          //rowsPerPage={50}          
          rowsPerPageOptions={[50, 100, 150, 200]}
        //rowsPerPageOptions={[10, 20, 30, 40], {label:'xyz'}}            
        /> */}

      </div>

    );
  }
}
export default OSList;
