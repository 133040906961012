import React, { Component } from 'react';
import { MenuItem, Select, CircularProgress, FormControl, InputLabel } from '@material-ui/core';
import api from '../../../services/api';

class ComboOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      hasMore: true,
      page: 1,
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = async () => {
    const { page, loading, hasMore } = this.state;
    if (loading || !hasMore) return;

    this.setState({ loading: true }); // Atualiza o estado para indicar que está carregando

    try {
      // A API agora usa a paginação corretamente
      const response = await api.get(`/cidade?skip=${(page - 1) * 100}&first=100`);

      const newItems = response.data;

      this.setState(prevState => ({
        items: [...prevState.items, ...newItems], // Adiciona novos itens
        hasMore: newItems.length > 0, // Verifica se há mais itens
        loading: false, // Carregamento concluído
      }));
    } catch (error) {
      console.error('Error loading items', error);
      this.setState({ loading: false });
    }
  };

  handleScroll = (event) => {
    //const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
    const bottom = event.target.scrollHeight >= event.target.scrollTop + event.target.clientHeight;

    console.log('passando no handlescroll')
    console.log('bottom: ' + JSON.stringify(bottom));
    console.log('event.target.scrollHeight: ' + JSON.stringify(event.target.scrollHeight));
    console.log('event.target.scrollTop: ' + JSON.stringify(event.target.scrollTop));
    console.log('event.target.clientHeight: ' + JSON.stringify(event.target.clientHeight));
    // Carregar mais itens ao atingir o final do scroll
    if (bottom && this.state.hasMore && !this.state.loading) {
        console.log('vai acionar o loaditems...')
      this.setState(prevState => ({ page: prevState.page + 1 }), this.loadItems);
    }
  };

  render() {
    const { items, loading } = this.state;
    const { value, onChange } = this.props;
    
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="city-select">Cidade</InputLabel>
        <Select
          id="city-select"
          value={value || ''}
          onChange={onChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Limita a altura para forçar a rolagem
                overflowY: 'auto', // Habilita a rolagem
              },
              onScrollCapture: this.handleScroll // Usa onScrollCapture para capturar corretamente
            }
          }}
        >
          {items.map(item => (
            <MenuItem key={item.codcidade} value={item.codcidade}>
              {item.desccidade}
            </MenuItem>
          ))}
          {loading && (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

export default ComboOnDemand;
