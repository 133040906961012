/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, colors, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys';
import CancelIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  subItem: {
    paddingLeft: theme.spacing(4), // Adiciona um deslocamento à direita
  },
  subIcon: {
    color: theme.palette.icon,
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },
}));

const SidebarNav = props => {
  const classes = useStyles();

  const [openCadastro, setOpenCadastro] = useState(false);
  const [openServicos, setOpenServicos] = useState(false);
  const [openClientes, setOpenClientes] = useState(false);

  const [translations, setTranslations] = useState({});

  // Chama a função de tradução ao montar o componente
  useEffect(() => {
    const performsTranslation = async () => {
      const termsNeeded = [
        CommonTranslations.MENU_ITEM_CADASTRO,
        CommonTranslations.MENU_ITEM_SERVICOS,
        CommonTranslations.MENU_ITEM_CATEGORIASERVICOS,
        CommonTranslations.MENU_ITEM_CLIENTE,
        CommonTranslations.MENU_ITEM_MOTIVOCANCELAMENTO,
        CommonTranslations.MENU_ITEM_ORDEMSERVICO,
        CommonTranslations.MENU_ITEM_SAIR,
      ];
      const translations = await i18n.fetchTerms(termsNeeded);
      console.log('tranalstions - side bar front ' + JSON.stringify(translations));
      setTranslations(translations);
    };

    performsTranslation();
  }, []); // O array vazio garante que o efeito seja executado apenas uma vez ao montar

  const handleCadastroClick = () => {
    setOpenCadastro(!openCadastro);
  };
  const handleServicosClick = () => {
    setOpenServicos(!openServicos);
  };
  const handleClientesClick = () => {
    setOpenClientes(!openClientes);
  };

  return (
    <List>
      <ListItem button component={Link} to="/front/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem button onClick={handleCadastroClick}>
        <ListItemIcon>
          <CreateNewFolderIcon />
        </ListItemIcon>
        <ListItemText primary={translations[CommonTranslations.MENU_ITEM_CADASTRO] || 'Cadastro'} />
        {openCadastro ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openCadastro} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button onClick={handleServicosClick} className={classes.subItem}>
            <ListItemIcon>
              <LocalActivityOutlinedIcon className={classes.subIcon} /> {/* Ícone para Serviços */}
            </ListItemIcon>
            <ListItemText primary={translations[CommonTranslations.MENU_ITEM_SERVICOS] || 'Serviços'} />
            {openServicos ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openServicos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/front/categoriasservicos" className={classes.subItem}>
                <ListItemIcon>
                  <CategoryIcon className={classes.subIcon} /> {/* Ícone para Categorias Serviços */}
                </ListItemIcon>
                <ListItemText primary={translations[CommonTranslations.MENU_ITEM_CATEGORIASERVICOS] || 'Categorias Serviços'} />
              </ListItem>

              <ListItem button component={Link} to="/front/servicos" className={classes.subItem}>
                <ListItemIcon>
                  <LocalActivityOutlinedIcon className={classes.subIcon} /> {/* Ícone para Serviços */}
                </ListItemIcon>
                <ListItemText primary={translations[CommonTranslations.MENU_ITEM_SERVIÇOS] || 'Serviços'} />
              </ListItem>

              <ListItem button component={Link} to="/front/motivoscancelamentos" className={classes.subItem}>
                <ListItemIcon>
                  <CancelIcon className={classes.subIcon} /> {/* Ícone para Motivos Cancelamento */}
                </ListItemIcon>
                <ListItemText primary={translations[CommonTranslations.MENU_ITEM_MOTIVOCANCELAMENTO] || 'Motivos Cancelamento/Troca'} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Collapse>

      <ListItem button component={Link} to="/front/oss">
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={translations[CommonTranslations.MENU_ITEM_ORDEMSERVICO] || 'Ordem de Serviço'} />
      </ListItem>

      <ListItem button component={Link} to="/Logout">
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary={translations[CommonTranslations.MENU_ITEM_SAIR] || 'Sair'} />
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
