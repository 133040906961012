import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import { CardHospedagem } from '..';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import CardPassageiro from '../CardPassageiro/CardPassageiro';
import OS from 'views/Front/OS/OSForm';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blue[500],
  },
  title : {
    color: 'white',
    fontSize:'18px'
  }
}));

 const CardReserva = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const {...rest } = props;
  


  useEffect(() => {
    //console.log('load dados os será acionado..')
    //loadDadosOS();
   }, []);  
   
  
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
      className={classes.avatar}
      titleTypographyProps={{ className: classes.title }}
      title = {"RESERVA " + rest.nroos   }
      avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <AssignmentTurnedInIcon />
          </Avatar>
        }
      />
      
      <CardHeader
        title="CLIENTE/OPERADORA"
        subheader={rest.nomefantcli}
      />
      <Divider/>
      
      <CardHeader
        title="Idioma"
        subheader={rest.idiomaos}
      />
      <Divider/>
      <CardContent>
        <CardPassageiro
          passageiros={rest.passageiros}
        />  
        <CardHospedagem
          hospedagemprincipal={rest.hospedagemprincipal}
        />
      
      
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
       
        </IconButton>
        <IconButton aria-label="share">
        </IconButton>
      </CardActions>
    </Card>
  );
}
CardReserva.propTypes = {
  //className: PropTypes.string,
    //datain : PropTypes.string,
   
};
  
export default CardReserva;