import { Button, Card, CardContent, Input, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TablePagination } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PerfectScrollbar from 'react-perfect-scrollbar';
import api from '../../../services/api';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys';

class CategoriaServicoList extends Component {
    state = {
        categoriasservicos: [],
        searchFilter: {
            nomeCategoriaServico: '',
            situacaoInativoFilter: ANAO,
        },
        page: 0, // Página atual
        rowsPerPage: 50, // Número de itens por página
        totalItems: 0, // Total de itens para paginação
        translations: {}
    };

    estilo = {
        marginLeft: 30,
        marginTop: 30,
        marginRight: 30,
        container: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        addButton: {
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            float: 'right', // alinhamento a direita
            marginTop: 3,
            marginRight: 3
        },
        redText: {
            color: 'red',
        },
    };

    constructor(props) {
        super(props);
    }

    /* carregamento inicial */
    async componentDidMount() {
        this.performsTranslation();
        this.loadCategoriasServicos();
    }

    /* Carrega Categorias Serviços com Paginação */
    loadCategoriasServicos = async () => {
        const { searchFilter, page, rowsPerPage } = this.state;
        //let params = `?page=${page * rowsPerPage}&limit=${rowsPerPage}`;
        //let params = `?page=${page * rowsPerPage}&limit=${rowsPerPage}`;
        let params = `?page=${page + 1}&limit=${rowsPerPage}`;

        console.log('vai buscar dados da page:' + page)

        /* consulta pela descrição da categoria de serviço */
        if (searchFilter.nomeCategoriaServico) {
            params += `&tbcategoriaservico.desccategoriaservico_like=${searchFilter.nomeCategoriaServico}`;
        }

        /* filtra situação da categoria de serviço */
        if (searchFilter.situacaoInativoFilter === ASIM) {
            params += `&tbcategoriaservico.inativo=${ASIM}`;
        } else if (searchFilter.situacaoInativoFilter === ANAO) {
            params += `&tbcategoriaservico.inativo=${ANAO}`;
        }

        const response = await api.get(`/categoriaservico${params}`);
        console.log('response is: ' + JSON.stringify(response));

        // Atualiza o estado com os dados retornados e o total de itens
        this.setState({
            categoriasservicos: response.data.data,
            totalItems: parseInt(response.data.info['totalrecords'], 10) || 0

        });
    };

    /* Mudança de página */
    handleChangePage = (event, newPage) => {
        console.log('a nova página: ' + newPage);
        this.setState({ page: newPage }, () => {
            this.loadCategoriasServicos();
        });
    };

    /* Mudança na quantidade de linhas por página */
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 }, () => {
            this.loadCategoriasServicos();
        });
    };

    /* Escolha de uma conta para exclusão */
    handleSelectItem = (id) => {
        this.setState({ selectedId: id });
    };

    /* deleta categoria de serviço */
    handleDelete = async (id) => {
        try {
            const response = await api.delete(`/categoriaservico/${id}`);
            if (response.status === 200) {
                this.handleClose();
                this.loadCategoriasServicos();
            }
        } catch (error) {
            this.handleClose();
            this.handleOpenAlert(error.response.data.error);
            console.log(error.message);
        }
    };

    /* abre janela confirmação para exclusão */
    handleOpen = (codcategoriaservico) => {
        this.setState({ open: true });
        this.handleSelectItem(codcategoriaservico);
    };

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    /* fecha janela de confirmação exclusão */
    handleClose = () => {
        this.setState({ open: false });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    /* Pesquisar filtros na tela */
    OnChangeSearch = (event) => {
        const { searchFilter } = this.state;
        let { name, value } = event.target;
        value = value.toUpperCase();
        searchFilter[name] = value;
        this.setState({ searchFilter }, () => {
            if (name === 'situacaoInativoFilter') {
                this.loadCategoriasServicos();
            }
        });
    };

    /* Limpa Filtros na tela */
    LimpaFiltros = () => {
        let { searchFilter } = this.state;
        searchFilter.nomeCategoriaServico = '';
        searchFilter.situacaoInativoFilter = ANAO;
        this.setState({ searchFilter }, () => {
            this.loadCategoriasServicos();
        });
    };

    /* Realiza a tradução */
    async performsTranslation() {
        const termsNeeded = [CommonTranslations.BUTTON_NEW];
        const translations = await i18n.fetchTerms(termsNeeded);
        this.setState({ translations });
    }

    render() {
        const { categoriasservicos, searchFilter, page, rowsPerPage, totalItems, translations } = this.state;

        return (
            <div style={this.estilo}>
                <Button
                    component={Link}
                    to="/front/categoriasservicos/novo"
                    variant="contained"
                    color="primary"
                    style={this.estilo.addButton}
                >
                    {translations[CommonTranslations.BUTTON_NEW] || 'Novo'}
                </Button>

                <Paper>
                    <Input
                        autoFocus
                        id="nomeCategoriaServico"
                        name="nomeCategoriaServico"
                        onBlur={this.onInputBlur}
                        onChange={this.OnChangeSearch}
                        placeholder="Nome categoria serviço a pesquisar"
                        style={{ marginBottom: 10, width: 300, marginLeft: 10, marginTop: 10 }}
                        value={searchFilter.nomeCategoriaServico}
                    />

                    <Select
                        id="situacaoInativoFilter"
                        name="situacaoInativoFilter"
                        style={{ marginLeft: 20 }}
                        value={searchFilter.situacaoInativoFilter || ''}
                        onChange={this.OnChangeSearch}
                    >
                        <MenuItem value={ANAO}>Ativos</MenuItem>
                        <MenuItem value={ASIM}>Inativos</MenuItem>
                        <MenuItem value={'T'}>Todos</MenuItem>
                    </Select>

                    <Button color="primary" id="pesquisar" onClick={this.loadCategoriasServicos}>
                        <SearchIcon />
                    </Button>

                    <Button color="primary" onClick={this.LimpaFiltros}>
                        <ClearAllIcon />
                    </Button>
                </Paper>

                <Card>
                    <CardContent>
                        <PerfectScrollbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '100px', maxWidth: '100px' }}>Código</TableCell>                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {categoriasservicos.map(categoriaservico => (
                                        <TableRow hover key={categoriaservico.codcategoriaservico}>
                                            <TableCell style={{ width: '100px', maxWidth: '100px' }}>{categoriaservico.codcategoriaservico}</TableCell>
                                            <TableCell>{categoriaservico.desccategoriaservico}</TableCell>
                                            <TableCell>
                                                <Link to={`/front/categoriasservicos/${categoriaservico.codcategoriaservico}`}>
                                                    <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                </Link>
                                                <Link to={`/front/categoriasservicos/${categoriaservico.codcategoriaservico}/view`}>
                                                    <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                </Link>
                                                <DeleteForeverOutlinedIcon
                                                    onClick={() => this.handleOpen(categoriaservico.codcategoriaservico)}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                                <TableFooter>
                                    <TableRow>

                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </PerfectScrollbar>
                    </CardContent>
                </Card>
                <TablePagination
                    component="div"
                    labelRowsPerPage={'Registros por página'}
                    rowsPerPageOptions={[50, 100, 150]}
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
                    }
                />

                {/* Modal de Confirmação de Exclusão */}
                <DeleteConfirmationModal
                    open={this.state.open}
                    handleClose={this.handleClose}
                    handleDelete={() => this.handleDelete(this.state.selectedId)}
                />

                {/* Alerta de Informação */}
                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={this.handleCloseAlert}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

export default CategoriaServicoList;
