import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import uuid from 'uuid/v1';
import moment from 'moment';
import mockData from './data';
import { getISOMoeda } from 'lib/functions';
const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const OperacionalValoresMedio = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  const valorMedioOrcamento = 
  rest.valorMedioOrcamento.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const valorMedioOS = rest.valorMedioOS.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  
  //const [products] = useState(mockData);

  //console.log('o nome do locate xxx.. ' + window.location.pathname);
  const itens = [
    {
      id: uuid(),
      name: 'Valor médio Orçamento',
      imageUrl: '/images/gerencial/operacional-img-os-orcamento.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : valorMedioOrcamento,     
      detalhe: ''
    },

    {
      id: uuid(),
      name: 'Valor médio por OS',
      imageUrl: '/images/gerencial/operacional-img-os-registrada1.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : valorMedioOS,
      detalhe: ''
    },
    {
      id: uuid(),
      name: 'Valor médio por pax',
      imageUrl: '/images/gerencial/operacional-img-qtde-pax1.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : rest.valorMedioPax.toLocaleString(moeda.abrevMoeda,{style: 'currency', currency: moeda.isoMoeda}),
      detalhe : '',
    },
    {
      id: uuid(),
      name: 'Valor médio por serviço',
      imageUrl: '/images/gerencial/operacional-img-os-servicos-registrados.png',
      updatedAt: moment().subtract(3, 'hours'),
      totalGenero : rest.valorMedioServico.toLocaleString(moeda.abrevMoeda,{style: 'currency', currency: moeda.isoMoeda}),
      detalhe: ''
    },
    {
      id: uuid(),
      name: 'Custo médio escala',
      imageUrl: '/images/gerencial/operacional-img-os-qtde-escalas.png',
      updatedAt: moment().subtract(5, 'hours'),
      totalGenero : rest.custoMedioEscala.toLocaleString(moeda.abrevMoeda,{style: 'currency', currency: moeda.isoMoeda}),
      detalhe: ''
    },
  ];


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${itens.length} in total`}
        title="Valores médio"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {itens.map((item, i) => (
            <ListItem
              divider={i < itens.length - 1}
              key={item.id}
            >
              <ListItemAvatar>
                <img
                  alt="Item"
                  className={classes.image}
                  src={item.imageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                //secondary={`R$ ${product.updatedAt.fromNow()}`}
                secondary={item.totalGenero}                
              />
              <ListItemText
                secondary={item.detalhe}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
    </Card>
  );
};

OperacionalValoresMedio.propTypes = {
  className: PropTypes.string
};

export default OperacionalValoresMedio;
