/* constante SIM e NAO */
export const ASIM = 'S';
export const ANAO = 'N';

/* módulos - códigos */
export const AMODULO_RECEPTIVO = 13;
export const AMODULO_CRM = 22;
export const AMODULO_FINANCAS = 23;
export const AMODULO_GERENCIAL = 41;
export const AMODULO_FROTAS = 27;
export const AMODULO_ESTOQUE = 24;
export const AMODULO_ADMAPPCLI = 37;
export const AMODULO_SISAREPASSENGER = 43;


export const DESC_NATIVE_MODULO_RECEPTIVO = 'mod-front'
export const DESC_NATIVE_MODULO_CRM = 'mod-crm'
export const DESC_NATIVE_MODULO_FINANCAS = 'mod-financas'
export const DESC_NATIVE_MODULO_GERENCIAL = 'mod-gerencial'
export const DESC_NATIVE_MODULO_FROTAS = 'mod-frotas'

export const PATHIMAGE = 'url(/images/auth.jpg)';


/* constante IN / OUT */
export const ATIPO_TRANSFER_IN = 1;
export const ATIPO_TRANSFER_OUT = 2;


/* constante IN / OUT */
export const AVOO_CHEGADA = 'C';
export const AVOO_PARTIDA = 'P';


/*Situação de OS */
export const AOS_ABERTA = 'A';
export const AOS_ENCERRADA = 'F';
export const AOS_CANCELADA = 'C';


/* Tipo de atividades */
export const TIPO_ATENDIMENTO = 1;
export const TIPO_OS = 2;
export const TIPO_AGENDAMENTO = 3;

//export const BASEURL = process.env.REACT_APP_SISARE_API;
export const BASEURL = process.env.REACT_APP_SISARE_API;
export const USERAPI = process.env.REACT_APP_SISARE_USER_API;
export const PASSAPI = process.env.REACT_APP_SISARE_PASS_API;


export const APAX_ADT = 1;  // Pax ADT Adulto
export const APAX_CHD = 2;  // Pax CHD Criança
export const APAX_INF = 3;  // Pax INF Infantis 
export const APAX_SEN = 5;  // Pax SEN Senior

/* Categorias de Pax */
export const ACATEGORIA_PAX_PAGANTE = 1;
export const ACATEGORIA_PAX_CORTESIA = 2;
//  ACATEGORIA_PAX_FREE        = 3; {obsoleto tambem na solução DESKTOP}
export const ACATEGORIA_PAX_MEIAENTRADA = 4;

/* Idiomas */
export const AIDIOMA_QUALQUER = 0;
export const AIDIOMA_PORTUGUES = 1;
export const DESC_IDIOMA_PORTUGUES = 'Português Brasil';
export const AIDIOMA_ESPANHOL = 2;
export const DESC_IDIOMA_ESPANHOL = 'Espanhol';
export const AIDIOMA_INGLES = 3;
export const DESC_IDIOMA_INGLES = 'Inglês';
export const AIDIOMA_FRANCES = 4;
export const DESC_IDIOMA_FRANCES = 'Francês';
export const AIDIOMA_CHINES = 5;
export const DESC_IDIOMA_CHINES = 'Chinês';
export const AIDIOMA_JAPONES = 6;
export const DESC_IDIOMA_JAPONES = 'Japonês';
export const AIDIOMA_RUSSO = 7;
export const DESC_IDIOMA_RUSSO = 'Russo';
export const AIDIOMA_ALEMAO = 8;
export const DESC_IDIOMA_ALEMAO = 'Alemão';
export const AIDIOMA_BILINGUE = 9;
export const DESC_IDIOMA_BILINGUE = 'Bilingue';
export const AIDIOMA_ITALIANO = 10;
export const DESC_IDIOMA_ITALIANO = 'Italiano';
export const AIDIOMA_COREANO = 11;
export const DESC_IDIOMA_COREANO = 'Coreano';
export const AIDIOMA_POLONES = 12;
export const DESC_IDIOMA_POLONES = 'Polonês';
export const AIDIOMA_GREGO = 13;
export const DESC_IDIOMA_GREGO = 'Grego';
export const AIDIOMA_MANDARIM = 14;
export const DESC_IDIOMA_MANDARIM = 'Mandarim';
export const AIDIOMA_CANTONES = 15;
export const DESC_IDIOMA_CANTONES = 'Cantones';

/* Tipo extra ou faturado */
export const ATIPO_EXTRA = 1;
export const ATIPO_FATURADO = 2;
export const ATIPO_TODOS = 3;

/* Moedas */

//{Moedas e formas de pagamento}
export const FORMA_DINHEIRO = 1; //{além de forma de pagamento também é moeda R$ }
export const FORMA_CHEQUE = 2;
export const FORMA_PARTICIONADO = 3;
export const FORMA_PRAZO = 4;
export const FORMA_CORTESIA = 5;
export const FORMA_CARTAO = 6;
export const FORMA_BANCO = 7;
export const FORMA_USO_CREDITO = 8;
export const FORMA_DOLAR = 9;
export const FORMA_EURO = 10; //{além de forma de pagamento também é moeda EURO }
export const FORMA_PESO = 11; //{além de forma de pagamento também é moeda PESO }
export const FORMA_GUARANI = 12; //{além de forma de pagamento também é moeda GUARANI }
export const FORMA_SOLES = 13; //{Moeda do Perú}
export const FORMA_BOLIVAR = 14; //{Moeda da Venezuela}
export const FORMA_FINANCIAMENTO = 15; //{Financiamento bancário}
export const FORMA_VOUCHER = 16; //{Exclusivo importação Bela Viagem}
export const FORMA_PESO_CHILENO = 17; //{Moeda pesos Chilenos}

// Financeiro - Tipo Crédito e Débito
export const ACREDITO = 'C';
export const ADEBITO = 'D';

/* Feriados */
export const AFERIADO_NACIONAL = 1;
export const AFERIADO_ESTADUAL = 2;
export const AFERIADO_MUNICIPAL = 3;
export const AFERIADO_OUTRO = 4;

/* Fechamento financeiro */
export const AFECHAMENTO_FINANCEIRO_FECHADO = 'F';
export const AFECHAMENTO_FINANCEIRO_CANCELADO = 'C';

/* Tipo de Conta Banco / Caixa  */
export const ATIPO_CONTA_CAIXA = 1;
export const ATIPO_CONTA_BANCO = 2;

/* Motivos de cancelamentos / Devoluções / Trocas de Serviços */
export const ATIPO_MOTIVO_CANCELAMENTO_SERVICO = 1;
export const ATIPO_MOTIVO_TROCA_SERVICO = 2;

// CENTROS DE CUSTO
export const ATIPO_ANALITICO = 'A';
export const ATIPO_SINTETICO = 'S';

/* Sexo */
export const ASEXO_FEMININO  = 'F';
export const ASEXO_MASCULINO = 'M';


/* Gêneros de serviços */
export const AGENERO_SERVICO_TRANSPORTE = 1;
export const AGENERO_SERVICO_OPCIONAL = 2;
export const AGENERO_SERVICO_HOSPEDAGEM = 3;
export const AGENERO_SERVICO_AEREO = 4;
export const AGENERO_SERVICO_OUTROS = 5;
export const AGENERO_SERVICO_SEGUROVIAGEM = 6;
export const AGENERO_SERVICO_LOCADORAVEICULOS = 7;
export const AGENERO_SERVICO_SERVICOVIAGENS = 8;
export const AGENERO_SERVICO_PACOTEVIAGENS = 9;
export const AGENERO_SERVICO_CRUZEIRO = 10;
export const AGENERO_SERVICO_OUTROSVIAGENS = 11;


/*Regimes hoteis */
export const AREGIME_NENHUM = 'NEM'; //{Nenhum}
export const AREGIME_CM =  'CM' ; //{Café da manhã}
export const AREGIME_MAP = 'MAP'; //{Meia Pensão}
export const AREGIME_FAP = 'FAP'; //{Pensão completa}
export const AREGIME_ALL_INCLUSIVE = 'ALL'; //{All Inclusive} 


/* Dialetos */
export const tDialeto = {
    ADialetoNone: 0,
    ADialetoPtgBrasil: 1,
    ADialetoPtgPortugal: 2,
    ADialetoEspanArgentina: 3,
    ADialetoEspanParaguay: 4,
    ADialetoFrancesFranca: 5,
    ADialetoInglesEUA: 6,
    ADialetoEspanChile: 7
};

/* Países */
export const tPais = {
    APAISNONE: 0,
    APAISBRASIL: 1,
    APAISPORTUGAL: 2,
    APAISARGENTINA: 3,
    APAISPARAGUAI: 4,
    APAISFRANCA: 5,
    APAISESTADOSUNIDOS: 6,
    APAISCHILE: 7
};

/* Forma PDV */
export const AFORMA_PDV_SISARE_UNICO = 1;
export const AFORMA_PDV_SISARE_MULTI = 2;

/* Tipos de serviços de apoio */
export const ATIPO_SERVICOAPOIO_IDA = 1; // serviço de apoio de ida
export const ATIPO_SERVICOAPOIO_RETORNO = 2; // serviço de apoio de retorno

/* Tipo de serviço */
export const ATIPOSERVICO_REGULAR = 1;
export const ATIPOSERVICO_PRIVATIVO = 2;
export const ATIPOSERVICO_SEMIPRIVATIVO = 3;