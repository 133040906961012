import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
//import { IntlProvider } from 'react-intl'; // Importando o IntlProvider para i18n
//import { initIntlProvider } from './i18n'; // Função para carregar as traduções (pode ser de uma API ou localStorage)

const browserHistory = createBrowserHistory();

// Extendendo as funcionalidades do Chart.js
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

// Adicionando validadores personalizados
validate.validators = {
  ...validate.validators,
  ...validators
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
  //    intl: null, // Estado para armazenar as mensagens de tradução
  //    locale: 'pt', // Definindo o idioma padrão
    };
  }

  async componentDidMount() {
    // Carrega as mensagens de tradução ao montar o componente
 //   const intlInstance = await initIntlProvider(this.state.locale);
//    this.setState({ intl: intlInstance });
  }

  render() {
   // const { intl, locale } = this.state;

    // Exibe uma mensagem de carregamento enquanto as traduções não forem carregadas
    //if (!intl) {
    //  return <div>Loading translations...</div>;
    //}

    return (
     // <IntlProvider locale={locale} messages={intl.messages}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      //</IntlProvider>
    );
  }
}

export default App;