import { getConfigField, loadConfigurations, saveConfigurations } from "lib/configUtils";
import api from "services/api";

class LangEngine {
  constructor(dbName = 'translationsDB') {
    this.dbName = dbName;
    this.language = this.getDefaultLanguage(); // Define o idioma automaticamente
  }

  // Método para obter o idioma padrão
  getDefaultLanguage() {
    return navigator.language || 'en'; // Padrão para o idioma do navegador
  }

  // Método para abrir conexão com IndexedDB
  async openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('translations')) {
          db.createObjectStore('translations', { keyPath: 'term' });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject('Erro ao abrir o banco de dados: ' + event.target.errorCode);
      };
    });
  }

  // Método para obter termos do IndexedDB
  async getStoredTerms(termsNeeded) {
    const db = await this.openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['translations'], 'readonly');
      const store = transaction.objectStore('translations');

      const termsFound = {};
      const missingTerms = [];

      let count = 0;
      console.log('getstoreterms: ' + JSON.stringify(termsNeeded))

      termsNeeded.forEach((term) => {
        const request = store.get(term);

        request.onsuccess = (event) => {
          const result = event.target.result;
          if (result) {
            termsFound[term] = result.text;
          } else {
            missingTerms.push(term);
          }
          count++;
          if (count === termsNeeded.length) {
            resolve({ termsFound, missingTerms });
          }
        };

        request.onerror = (event) => {
          reject('Erro ao buscar termos: ' + event.target.errorCode);
        };
      });
    });
  }

  // Método para armazenar novos termos no IndexedDB
  // Método para armazenar novos termos no IndexedDB
async storeTerms(terms) {
  const db = await this.openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['translations'], 'readwrite');
    const store = transaction.objectStore('translations');

    // Transforma o objeto {key: value} em [{term: key, text: value}]
    Object.entries(terms).forEach(([key, value]) => {
      // Garante que o objeto tenha a estrutura correta com 'term' e 'text'
      store.put({ term: key, text: value });
    });

    transaction.oncomplete = () => {
      resolve('Termos armazenados com sucesso');
    };

    transaction.onerror = (event) => {
      reject('Erro ao armazenar termos: ' + event.target.errorCode);
    };
  });
}
// Método para buscar os termos da API para os que faltam
  async fetchFromAPI(missingTerms, coddialeto) {
    try {
      const response = await api.post(`/traducao/${coddialeto}/findlist`, {
        listid: missingTerms,
      });
      return response.data; // Supondo que retorna um objeto { term: translation }
    } catch (error) {
      console.error('Erro ao buscar termos:', error);
      return {};
    }
  }

  getConfiguracoes = async () => {
    try {
      const aURL = `/configuracoes/${1}`;
      const response = await api.get(aURL);
      saveConfigurations(response.data[0], 'cfg');
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  // Método principal para buscar e retornar todos os termos necessários
  async fetchTerms(termsNeeded) {
    const { termsFound, missingTerms } = await this.getStoredTerms(termsNeeded);

    console.log('retorno getStoredTerms:' + JSON.stringify(termsFound));

    if (missingTerms.length > 0) {
      console.log('vai acionar a api..');
      //
      //let codidioma = 1;      
      let coddialeto = parseInt(getConfigField('coddialeto'), 10);
      if (isNaN(coddialeto)) {
        // busca dados configuracoes empresa 1;
        console.log('vai carregar configuracoes')
        const response = await this.getConfiguracoes();
        saveConfigurations(response.data);
        console.log('carregando dialeto..');
        coddialeto = parseInt(await getConfigField('coddialeto'), 10);

      }
      //
      const fetchedTerms = await this.fetchFromAPI(missingTerms, coddialeto);
      await this.storeTerms(fetchedTerms);

      console.log('o que há em termsFound:' + JSON.stringify(termsFound));

      return { ...termsFound, ...fetchedTerms };
    }
    console.log('o que há em termsFound:' + JSON.stringify(termsFound));

    return termsFound;
  }
}

export default new LangEngine();
