import { FORMA_DINHEIRO, FORMA_DOLAR, FORMA_EURO, FORMA_PESO, FORMA_GUARANI, FORMA_PRAZO, FORMA_CORTESIA, FORMA_PESO_CHILENO } from 'consts';


/*  retornar a ISO da Moeda e abreviatura em Objeto */
export function getISOMoeda(moeda) {
  let abrevMoeda = '';
  let isoMoeda = '';
  switch (parseInt(moeda)) {
    case FORMA_DINHEIRO:
      abrevMoeda = 'pt-BR';
      isoMoeda = 'BRL';
      break;
    case FORMA_DOLAR:
      abrevMoeda = 'en-US';
      isoMoeda = 'USD';
      break;
    case FORMA_PESO:
      abrevMoeda = 'es-AR';
      isoMoeda = 'ARS';
      break;
    case FORMA_PESO_CHILENO:
      abrevMoeda = 'es-CL';
      isoMoeda = 'CLP';
      break;

    case FORMA_EURO:
      abrevMoeda = 'en-US';
      isoMoeda = 'EUR';
      break;
    case FORMA_GUARANI:
      abrevMoeda = 'es-PY';
      isoMoeda = 'PYG';
      break;
  }
  const objMoeda = {
    abrevMoeda: abrevMoeda,
    isoMoeda: isoMoeda
  };
  return objMoeda;
}

export function formatCurrency(value) {
  //console.log(value));
  //return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(value)
    .replace(/[^\d,.-]/g, ''); // Remove o símbolo da moeda

}



/*  retornar descrição da moeda  */
export function getDescFormaPgto(formapgto) {
  let descFormaPgto = '';
  //console.log('passou pelo getdescformapgto')
  //let isoMoeda = '';
  switch (parseInt(formapgto, 10)) {
    case FORMA_DINHEIRO:
      descFormaPgto = 'BRL';
      break;
    case FORMA_DOLAR:
      descFormaPgto = 'USD';
      break;
    case FORMA_PESO:
      descFormaPgto = 'ARS';
      break;
    case FORMA_EURO:
      descFormaPgto = 'EUR';
      break;
    case FORMA_GUARANI:
      descFormaPgto = 'PYG';
      break;
    case FORMA_PRAZO:
      descFormaPgto = 'Prazo'
      break;
    case FORMA_CORTESIA:
      descFormaPgto = 'Cortesia'
      break;

  }
  //console.log('vai retornar: ' + descFormaPgto)
  return descFormaPgto;
}

/* formata data */
export function formatData(data) {
  if ((!data) || (data === null)) {
    return null; // Retorna null se a data for null ou undefined
  }
  const dataServico = data;
  const dateObject = new Date(dataServico);
  const formattedDate = dateObject.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return formattedDate;
}

export function formatDateForInput(dateString) {
  // se for null atribui em branco - senão é atribudo uma data antiga;
  if (dateString === null) {
    return "";
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
}

/* Valida CPF */
export function validarCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres especiais

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false; // Verifica se tem 11 dígitos ou todos iguais
  }

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
}

/* Formata data e hora recebendo no formato ISO: 2024-09-10T19:20:29.000Z */
/*export function formatDateTimeISO (isoString)  {
  const date = new Date(isoString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // `getUTCMonth()` retorna o mês de 0 a 11
  const year = date.getUTCFullYear();

  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
} */

/* Formata data e hora recebendo no formato ISO: 2024-09-10T19:20:29.000Z */
/*export function formatDateTimeISO(isoString) {
  const date = new Date(isoString);

  // Formata a data e hora de acordo com o fuso horário local do sistema do usuário
  return date.toLocaleString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
} */

/* recebe no formato ISO e converte para o pais que estiver para exibir no formato correto */
export function formatDateTimeISO(isoString) {
  const date = new Date(isoString);

  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,  // Para evitar exibição em formato AM/PM
  }).replace(',', ''); // Remove a vírgula entre data e hora
}

/* Método para validar campos e valores passados com base no schema de validação passado */
export function validateField(field, value, aschema) {
  let schema = aschema;
  let error = '';
  const fieldSchema = schema[field];
  const type = fieldSchema.type || 'string';

  //console.log('campo validando: ' + field);
  //console.log('valor validando: ' + value);

  const isEmptyValue = (val) => {
    return (
      val === undefined ||
      val === null ||
      (typeof val === 'string' && val.trim() === '') ||
      (typeof val === 'number' && isNaN(val)) ||
      (typeof val === 'boolean' && val === undefined) ||
      (type === 'select-one' && val === undefined)
    );
  };

  // Verifica se o campo é obrigatório e está vazio
  if (fieldSchema.presence && !fieldSchema.presence.allowEmpty && isEmptyValue(value)) {
    error = fieldSchema.presence.message;
  }

  // Validação de comprimento: Apenas verifica o comprimento se o valor for string
  if (!error && fieldSchema.length && typeof value === 'string') {
    if (value.length > fieldSchema.length.maximum) {
      error = `O campo ${field} deve ter no máximo ${fieldSchema.length.maximum} caracteres`;
    }
  }

  //console.log('antes de validar inteiros: type...' + type)

  // Valida comprimento para números no caso do tipo 'integer' (antes de converter para número)
  if (!error && type === 'integer' && !isEmptyValue(value) && fieldSchema.length) {
    const maxDigits = fieldSchema.length.maximum;
    const maxValue = Math.pow(10, maxDigits) - 1;  // Calcula o maior valor permitido com base no número de dígitos
    if (value > maxValue) {
      error = `O campo ${field} não pode ter mais que ${maxDigits} dígitos`;
    }
  }

  // Converte para número se o tipo for 'integer' e o valor não estiver vazio e sem erros de comprimento
  if (!error && type === 'integer' && !isEmptyValue(value)) {
    value = parseInt(value, 10);
  }

  // Verifica se há erro e continua com validação de tipo se o valor não for vazio
  if (!error && !isEmptyValue(value)) {
    switch (type) {
      case 'integer':
        if (typeof value !== 'number' || isNaN(value) || !Number.isInteger(value)) {
          error = `O campo ${field} deve ser um número inteiro válido`;
        }
        break;

      case 'string':
        if (typeof value !== 'string') {
          error = `O campo ${field} deve ser uma string válida`;
        }
        break;

      case 'select-one':
        if (typeof value !== 'number' || isNaN(value) || !Number.isInteger(value)) {
          error = `O campo ${field} deve ser escolhido`;
        }
        break;

      case 'checkbox':
        if (typeof value !== 'boolean') {
          error = `O campo ${field} deve ser um valor booleano (true/false)`;
        }
        break;

      default:
        error = `Tipo de campo inválido para ${field}`;
        break;
    }
  }

  return error;
};
