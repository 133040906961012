import React, { Component } from 'react';
import {
  Button, Card, CardActions, CardContent, TablePagination, Input, Paper, Select, TextField,
  TableHead, TableRow, Table, TableCell, TableBody, FormControlLabel, Switch
} from '@material-ui/core';
import api from 'services/api';
import { Link } from 'react-router-dom';
import { ANAO, AOS_ABERTA, AOS_CANCELADA, AOS_ENCERRADA, ASIM, ATIPO_TRANSFER_IN, ATIPO_TRANSFER_OUT } from 'consts';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'
import { loadConfigurations, getConfigField, loadCompany } from 'lib/configUtils';
import { CircularProgress } from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';


class AdmSisarePassengerList extends Component {
  state = {
    paginas: [],
    searchFilter: {
      titulo_PaginaFilter: '',
    },
    translations: {}

  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
    }
  }

  constructor(props) {
    super(props);

  }

  async componentDidMount() {
    this.performsTranslation();
    const { searchFilter } = this.state;

    this.setState({ searchFilter: searchFilter });
    this.loadPaginas();
  }

  /* pesquisa itens */
  loadPaginas = async (opcao) => {
    const { searchFilter } = this.state;
    let params = '';
    if (searchFilter.titulo_PaginaFilter) {
      params += `${params ? '&' : '?'}tbappclisisarepassenger.titulo_pagina=_like_${searchFilter.titulo_PaginaFilter}`;
      //params += await api.get(`/cidade?tbcidade.desccidade=_like_${searchTerm}`);
    }

    try {
      const response = await api.get(`/appcli/pages${params}`);
      console.log('resposta: ' + JSON.stringify(response));
      this.setState({ paginas: response.data });
    } catch (error) {
      console.error("Erro ao carregar itens de OS: ", error);
    } finally {
      this.loading = false; // Define como falso quando finalizado
    }
  }

  /* onBlur - evento ao sair controle - se torna mais rapido quando
  tem muitos registros no retorno*/
  onInputBlur = (e) => {
    const { searchFilter } = this.state;
    //searchFilter.nomeFantasiaClienteFilter = e.target.value;
    console.log('passando no onblur: ' + e.target.name)
    if (e.target.name === 'nomepax') {
      //      if (!(e.target.value === '')) {
      searchFilter[e.target.name] = e.target.value.toUpperCase();
      //    }
      //console.log('passou onblue nome fantasia...')
    }
    else {
      searchFilter[e.target.name] = e.target.value;
    }

    if (!(e.target.value === '')) {
      this.setState({ searchFilter: searchFilter });
    }

  }

  LimpaFiltros = () => {
    const dataAtual = new Date();
    this.setState({
      searchFilter: {
        titulo_PaginaFilter: '',
      },

      paginas: [], // Limpa a lista ao limpar filtros
    }, () => {
      this.loadPaginas(); // Carrega categorias após limpar filtros
    });
  };



  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    const input = event.target;

    // Verifica se é um switch (checkbox) e usa checked, senão usa value
    let value = input.type === 'checkbox' ? input.checked ? ASIM : 'N' : input.value;

    //  if (input.name === 'nomePaxFilter') {
    //      value = value.toUpperCase();
    //  }
    // Atualiza o estado
    searchFilter[input.name] = value;

    this.setState({ searchFilter: searchFilter });
  };

  /* Realiza a tradução */
  async performsTranslation() {
    const termsNeeded = [CommonTranslations.GRID_COLUMN_ACOES];
    // Lista de termos da página
    const translations = await i18n.fetchTerms(termsNeeded);
    this.setState({ translations });
  }


  /* deleta serviço */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/admappcli/page/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadPaginas();
      }
    } catch (error) {
      this.handleClose();
      this.handleOpenAlert(error.response.data.error);
      console.log(error);
      console.log(error.message);
    }
  };

  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (id) => {
    this.setState({ open: true });
    this.handleSelectItem(id);
  };

  /* Escolha de uma conta para exclusão */
  handleSelectItem = (id) => {
    this.setState({ selectedId: id });
  };


  render() {
    const { paginas } = this.state;
    const { searchFilter } = this.state;
    const { translations } = this.state;
    return (
      <div style={this.estilo}>
        <div>
          <span />
          <div>
            <Link to="/admappcli/adm-sisare-passenger/novo">
              <Button
                color="primary"
                style={this.estilo.addButton}
                variant="contained"
              >
                Novo
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <Paper
            hover
            style={{ marginBottom: 10, marginTop: 10 }}
          >
            <Input
              autoFocus
              id="titulo_PaginaFilter"
              name="titulo_PaginaFilter"
              onChange={this.OnChangeSearch}
              //onBlur={this.onInputBlur}
              placeholder="Página"
              style={{ marginBottom: 10, width: 300, marginLeft: 10 }}
              value={searchFilter.titulo_PaginaFilter}
            />
            <Button
              color="primary"
              id="pesquisar"
              onClick={this.PesquisarItensOS}>
              <SearchIcon />
            </Button>

            {/* Exibe o círculo de carregamento enquanto os dados estão sendo carregados */}
            {/*this.loading && <CircularProgress size={24} />*/}

            <Button
              color="primary"
              onClick={() => this.LimpaFiltros()}
            >
              <ClearAllIcon />
            </Button>

          </Paper>
        </div>
        <div>
          <br />
        </div>

        <div>
          <Card
          //{...rest}
          //className={clsx(classes.root, className)}
          >
            <CardContent>
              <PerfectScrollbar>
                <div >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Página</TableCell>
                        <TableCell>URL</TableCell>
                        <TableCell>{translations[CommonTranslations.GRID_COLUMN_ACOES] || 'Ações'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginas.slice(0, 100).map(item => (
                        <TableRow
                          hover
                          key={item.id}
                        >
                          <TableCell width="50">{item.titulo_pagina}</TableCell>
                          <TableCell width="10">{item.url_default}</TableCell>
                          <TableCell width="100">
                            <Link to={`/admappcli/adm-sisare-passenger/${item.id}`}>
                              <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                            </Link>

                            <Link to={`/admappcli/adm-sisare-passenger/${item.id}/view`}>
                              <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                            </Link>
                            <DeleteForeverOutlinedIcon
                              onClick={() => this.handleOpen(item.id)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />


                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>

          <DeleteConfirmationModal
            open={this.state.open}
            handleClose={() => this.handleClose()}
            itemId={this.state.selectedId}
            message={"Confirma exclusão do item " + this.state.selectedId + '?'}
            handleDelete={() => this.handleDelete(this.state.selectedId)}
          />

        </div>

      </div>

    );
  }
}
export default AdmSisarePassengerList;
