import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'; // Ícone de avião de partida
import i18n from 'translations/langEngine';
import { Dashboard_Front_Translations } from 'translations/translationKeys'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: '#f5f5f5', // Cor de fundo suave
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Sombra para profundidade
    borderRadius: 8, // Bordas arredondadas
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    color: '#333', // Cor do título
  },
  avatar: {
    backgroundColor: '#1E88E5', // Azul para o fundo do círculo
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32,
    color: '#FFFFFF' // Ícone branco para contraste
  },
  totalPax: {
    transition: 'opacity 0.5s ease-in-out', // Transição suave de opacidade
    opacity: 0, // Inicia invisível
    fontWeight: 'bold', // Negrito para destaque
    color: '#1E88E5', // Cor do total
  },
  totalPaxLoaded: {
    opacity: 1, // Torna-se visível quando os dados são carregados
  }
}));

const TotalPaxPartindo = props => {
  const { className, totalPaxTransito, ...rest } = props;


  useEffect(() => {
    const termsNeeded = [Dashboard_Front_Translations.LABEL_PARTIDAS]; // Lista de termos da página
    i18n.fetchTerms(termsNeeded)
      .then(translations => {
        setTranslations(translations);
        console.log('teste>>' + JSON.stringify(translations)); // Move o console.log para depois da atualização
      })
      .catch(error => {
        console.error('Erro ao carregar traduções:', error);
      });

  }, []);


  const classes = useStyles();
  const [translations, setTranslations] = useState({});
  const lblpartidas = translations[Dashboard_Front_Translations.LABEL_PARTIDAS];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              
              {lblpartidas || "PARTIDAS" }
            </Typography>
            <Typography
              variant="h3"
              className={clsx(classes.totalPax, {
                [classes.totalPaxLoaded]: totalPaxTransito !== undefined
              })}
            >
              {totalPaxTransito !== undefined ? totalPaxTransito : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <FlightTakeoffIcon className={classes.icon} /> {/* Ícone de avião de partida */}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalPaxPartindo.propTypes = {
  className: PropTypes.string,
  totalPaxTransito: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default TotalPaxPartindo;
