import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';
import { ATIPO_ANALITICO } from 'consts';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboCentroReceitaDespesa = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [centrosx, setCentros] = useState({
        centros: []
    });

    useEffect(() => {
        async function loadCentros() {
            let params = []

            params += '?tbcreditodebito.tipoconta=' + ATIPO_ANALITICO;

            if (rest.inativo) {
                params += '&tbcreditodebito.inativo=' + rest.inativo;
            }

            if (rest.tipolancamento) {
                params += '&tbcreditodebito.tipolancamento=' + rest.tipolancamento;
            }

            try {
                const response = await api.get('/financas/centroreceitadespesa' + params);
                if (response.status === 200) {
                    setCentros({ centros: response.data });

                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        loadCentros();
    }, []);

    function Centros() {
        var listCentros = centrosx.centros;

        return (
            <div>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    value={rest.value}
                >
                    <option
                        aria-label="Não Definido"
                        value="0"
                    />
                    {listCentros.map(centro => (
                        <option
                            key={centro.codcreditodebito}
                            value={centro.codcreditodebito}
                        >
                            {centro.desccreditodebito}</option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Centros />
    );
};

ComboCentroReceitaDespesa.propTypes = {
    className: PropTypes.string
};

export default ComboCentroReceitaDespesa;