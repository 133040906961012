import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboServico = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [servicosx, setServicos] = useState({
        servicos: []
    });

    useEffect(() => {

        async function loadServicos() {
            let params = [];

            params += '?sort=descservico';

            if (rest.inativo) {
                params += '&tbservico.inativo=' + rest.inativo;
            }            

            try {
                const response = await api.get('/servico' + params);
                if (response.status === 200) {
                    setServicos({ servicos: response.data });

                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        loadServicos();
    }, []);

    function Servicos() {
        var listServicos = servicosx.servicos;
        return (
            <div>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    value={rest.value}                    
                >
                    <option
                        aria-label="Não Definido"
                        value="0"
                    />
                    {listServicos.map(servico => (
                        <option
                            key={servico.codservico}
                            value={servico.codservico}
                        >
                            {servico.descservico}</option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Servicos />
    );
};

ComboServico.propTypes = {
    className: PropTypes.string
};

export default ComboServico;