import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Importe o hook useParams
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import moment from 'moment';
import api from '../../../services/api';
import { MODULO_GERENCIAL, PATHIMAGE, AMODULO_SISAREPASSENGER } from 'consts';
import { x } from 'react-router-dom';


/* pega url imagem do módulo */
const getBackgroundImage = (rota) => {
  if (rota === '/financas') {
    return 'url(/images/authfinancas.jpg)';
  } else if (rota === '/front') {
    return 'url(/images/auth.jpg)';
  } else if (rota === '/gerencial') {
    return 'url(/images/authgerencial.jpg)';
  } else if (rota === '/crm') {
    return 'url(/images/authcrm.jpg)';
  } else if (rota === '/frotas') {
    return 'url(/images/authfrotas.jpg)';
  } else if (rota === '/estoque') {
    return 'url(/images/authestoque.jpg)';
  } else if (rota === '/appcli') {
    return 'url(/images/authappcli.jpg)';
  } else {
    return 'url(/images/authappcli.jpg)';
  }
};

const schema = {
  localizador: {
    presence: { allowEmpty: false, message: 'é obrigatória' },
    length: {
      maximum: 15, message: 'máximo 8 caracteres'
    }
  }
};

let useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: getBackgroundImage(location.pathname),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    //color: theme.palette.white,
    color: theme.palette.black,
    fontWeight: 300,

  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const LoginAppCli = props => {
  const { history } = props;
  let rotaModulo = history.location.pathname;
  let descModulo = '';
  let modulo = '';
  //let localizador;
  //const { localizador } = useParams(); // Use o hook useParams para obter o parâmetro localizador
  //console.log('localizador é:' + localizador)

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const localizador = queryParams.get('localizador');
  console.log('o localizador nos parametros é: ' + localizador);

  if (!(localizador == null)) {
    validaLocalizador(localizador);
  }

  //formState.values.localizador = localizador;

  descModulo = 'AppCli';
  modulo = AMODULO_SISAREPASSENGER

  sessionStorage.setItem('modulo', modulo);


  const [configuracoes, setConfiguracoes] = useState({
    codEmpresa: 0,
    formaPDV: 0,
    traboOutraMoeda: 0,

  })

  // remove token / usuário
  sessionStorage.removeItem('codUser');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('lastLogin');
  const classes = useStyles();

  /* Mensagem Erro Geral */
  const [message, setMessage] = useState({
    message: ''
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    rotaModulo: history.location.pathname,
    descModulo: descModulo,
    modulo: modulo
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);



  const handleChange = event => {
    event.persist();
    //console.log('onchange executando..: ' + formState);
    //console.log('onchange executando..: ' + event.target.name);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  /* atualiza dados de configuracoes em sessionStorage */
  function loadConfiguracoes() {
    return new Promise((resolve, reject) => {

      let codEmpresa = sessionStorage.getItem('codEmpresa');
      if ((codEmpresa === null) || (codEmpresa === undefined)) {
        codEmpresa = 1;
      }

      const aURL = '/configuracoes/' + codEmpresa;
      const response = api.get(aURL)
        .then(function (response) {
          setConfiguracoes({
            codEmpresa: response.data.codempresa,
            formaPDV: response.data.formapdv,
            traboOutraMoeda: response.data.traboutramoeda,
            fantasia: response.data.fantasia
          });
          sessionStorage.setItem('codEmpresa', response.data[0].codempresa);
          sessionStorage.setItem('trabOutraMoeda', response.data[0].traboutramoeda);
          sessionStorage.setItem('formaPDV', response.data[0].formapdv);
          sessionStorage.setItem('fantasia', response.data[0].fantasia);

          return response;
        })
        .catch(function (error) {
          console.log(error);
        });

    });

  }

  /* valida / realiza login */
  async function validaLocalizador(localizador) {
    const aURL = '/loginappcli';
    let loc = localizador;

    console.log('o que está em loc: ' + loc);
    if ((loc == null) || (loc == undefined)) {
      console.log('sim localizador é nullo vindos dos parametros');
      loc = formState.values.localizador;
      //loc = 'XTZ2020';
    }
    let codemp = 1;
    codemp = sessionStorage.getItem('codEmpresa');
    if ((codemp == null) || (codemp == undefined)) {
      codemp = 1;

    }
    console.log('o codigo da empresa passado é:' + codemp);
    const data = { localizador: loc, codEmpresa: codemp };
    //const data = { localizador: loc};
    const response = api.post(aURL, data)
      .then(function (response) {
        let lastLogin = new Date();
        console.log(response.data);
        lastLogin = moment(lastLogin, 'DD/MM/YYYY', true).format('DD/MM/YYYY');
        //sessionStorage.setItem('codUser', response.data.codUsuario);   
        //sessionStorage.setItem('user', response.data.usuario);
        sessionStorage.setItem('codOS', response.data[0].codos);
        sessionStorage.setItem('localizador', response.data[0].localizador);
        sessionStorage.setItem('nomePax', response.data[0].nomepax);
        switch (parseInt(formState.modulo)) {
          case AMODULO_SISAREPASSENGER:
            //console.log('vai abrir o módulo app cli....');
            console.log('history log: ' + JSON.stringify(history));
            //let navigate = navigate(0);
            history.push('/appcli/programacao')
            //const navigate = useNavigate();
            //navigate('appcli/programacao');
            //navigate('/appcli/programacao');
            break;

          default:
            break;
        }


        setMessage({
          message: 'ok',

        });

        return response;
        //return true
      })
      .catch(function (error) {
        //console.log('o erro na mensagem é: ' + error.message)
        if (error.message === 'Network Error') {
          //console.log('vai setar a mensagem: ' + error.message)
          setMessage({ message: 'Serviço API não respondeu' })
        }
        else {
          //history.push('appcli');
          setMessage({ message: 'Localizador inválido!' })
          //history.go(0);
          history.push('/appcli');
        }

      });
  }

  /* submit logar */
  const handleSignIn = event => {
    event.preventDefault();
    if (sessionStorage.getItem('codEmpresa') === null) {
      sessionStorage.setItem('codEmpresa', 1);
    }

    if (validaLocalizador()) {
      loadConfiguracoes();

    }
    else
      console.log('localizador inválido')

    // Debugging do objeto history
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  //console.log('entrou no hasError...campo' + formState.touched['usuario']);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
              </Typography>

            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Acesso
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  {'Programação'}
                </Typography>

                <TextField
                  className={classes.textField}
                  error={hasError('localizador')}
                  fullWidth
                  helperText={
                    hasError('localizador') ? formState.errors.localizador[0] : null
                  }
                  label="localizador"
                  name="localizador"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.localizador || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Entrar
                </Button>
                <Typography
                  id="servicoAPI"
                >
                  {message.message}
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

LoginAppCli.propTypes = {
  history: PropTypes.object
};

export default withRouter(LoginAppCli);
