import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import ImageIcon from '@material-ui/icons/Image';
import ContactsIcon from '@material-ui/icons/Contacts';
import BuildIcon from '@material-ui/icons/Build';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const [openAdmin, setOpenAdmin] = useState(false);

  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  return (
    <List>
      {/* Item "Administração" */}
      <ListItem button onClick={handleAdminClick} className={classes.listItem}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Administração" />
        {openAdmin ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button component={Link} to="/admappcli/adm-sisare-passenger-list" className={classes.nested}>
            <ListItemIcon>
              <SupervisorAccount />
            </ListItemIcon>
            <ListItemText primary="Sisare Passenger" />
          </ListItem>
        </List>
      </Collapse>

      {/* Item "Imagens e cores" */}
      
      <ListItem Button component={Link} to="/Logout">
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>

    </List>
  );
};

export default Sidebar;
