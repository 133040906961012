import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Profile, SidebarNav } from './components';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();
  
  const [translations, setTranslations] = useState({});

  // Chama a função de tradução ao montar o componente
  useEffect(() => {
    const performsTranslation = async () => {
      const termsNeeded = [
        CommonTranslations.MENU_ITEM_CADASTRO,
        CommonTranslations.MENU_ITEM_SERVICOS,
        CommonTranslations.MENU_ITEM_SAIR
      ];
      const translations = await i18n.fetchTerms(termsNeeded);
      console.log('tranalstions - side bar front ' + JSON.stringify(translations))
      setTranslations(translations);
    };
    
    performsTranslation();
  }, []); // O array vazio garante que o efeito seja executado apenas uma vez ao montar

  const pages = [
    {
      title: translations[CommonTranslations.BUTTON_SAVE] || 'Dashboard',
      href: '/front/dashboard',
      icon: <DashboardIcon />,
    },
    {/*
      title: translations[CommonTranslations.BUTTON_CANCEL] || 'Cadastro',
      href: '/front/clientes',
      icon: <CreateNewFolderIcon />,
    */},
    {
      title: translations[CommonTranslations.CHECKBOX_INATIVO] || 'Ordens de Serviço',
      href: '/front/oss',
      icon: <AssignmentIcon />,
    },
    {
      title: 'Clientes',
      href: '/front/clientes',
      icon: <PeopleIcon />,
    },
    {
      
      title: translations[CommonTranslations.MENU_ITEM_SAIR] || 'Sair',
      href: '/logout',
      icon: <LockOpenIcon />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
