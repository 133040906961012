import { Button, CardContent, FormControl, FormControlLabel, FormGroup, InputLabel, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import api from '../../../services/api';
import ComboTipoCaixaBanco from 'views/Components/ComboTipoCaixaBanco';
import { ANAO, ASIM, ATIPO_CONTA_BANCO } from 'consts';
import ComboBanco from 'views/Components/ComboBanco';
import ComboTipoChavePix from 'views/Components/ComboTipoChavePix';
import ComboMoeda from 'views/Components/ComboMoeda';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import PropTypes from 'prop-types';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import ComboSetor from 'views/Components/ComboSetor';

class ContaCorrenteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacorrente: {},
            banco: {},
            isNewRecord: false,
            isView: false,
            schema: {
                usuario: {
                    presence: { allowEmpty: false, message: 'é obrigatório' },
                    length: {
                        maximum: 64
                    }
                },
            },

            useStyles: makeStyles(theme => ({
                root: {
                    backgroundColor: theme.palette.background.default,
                    height: '100%'
                },
                grid: {
                    height: '100%'
                },
                quoteContainer: {
                    [theme.breakpoints.down('md')]: {
                        display: 'none'
                    }
                },
                quote: {
                    backgroundColor: theme.palette.neutral,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(/images/auth.jpg)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                },
                quoteInner: {
                    textAlign: 'center',
                    flexBasis: '600px'
                },
                quoteText: {
                    color: theme.palette.white,
                    fontWeight: 300
                },
                name: {
                    marginTop: theme.spacing(3),
                    color: theme.palette.white
                },
                bio: {
                    color: theme.palette.white
                },
                contentContainer: {},
                content: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                },
                contentHeader: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: theme.spacing(5),
                    paddingBototm: theme.spacing(2),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2)
                },
                logoImage: {
                    marginLeft: theme.spacing(4)
                },
                contentBody: {
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'center'
                    }
                },
                form: {
                    paddingLeft: 100,
                    paddingRight: 100,
                    paddingBottom: 125,
                    flexBasis: 700,
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(2)
                    }
                },
                title: {
                    marginTop: theme.spacing(3)
                },
                socialButtons: {
                    marginTop: theme.spacing(3)
                },
                socialIcon: {
                    marginRight: theme.spacing(1)
                },
                sugestion: {
                    marginTop: theme.spacing(2)
                },
                textField: {
                    marginTop: theme.spacing(2)
                },
                signInButton: {
                    margin: theme.spacing(2, 0)
                },
                alert: {
                    color: '#FF0000',
                }
            })),
        };
    }

    async componentDidMount() {
        // busca dados configurações
        let aExportaLayoutA2x = sessionStorage.getItem('exportaLayoutA2x');
        let aExportaContabil = sessionStorage.getItem('exportaContabil');
        let aIdentEmpCtbPeloSetor = sessionStorage.getItem('identEmpCtbPeloSetor');
        if ((aExportaLayoutA2x == null) || (aExportaLayoutA2x == undefined) ||
            (aExportaContabil == null) || (aExportaContabil == undefined) ||
            (aIdentEmpCtbPeloSetor == null) || (aIdentEmpCtbPeloSetor == undefined)) {

            let codEmpresa = sessionStorage.getItem('codEmpresa');
            if ((codEmpresa === null) || (codEmpresa === undefined)) {
                codEmpresa = 1;
            }

            const field = '?field=EXPORTALAYOUTA2X,EXPORTACONTABIL,IDENTEMPCTBPELOSETOR';
            const aURL = '/financas/configuracoes/' + codEmpresa + field;
            const response = await api.get(aURL)
                .then(function (response) {
                    if ((aExportaLayoutA2x == null) || (aExportaLayoutA2x == undefined))
                        sessionStorage.setItem('exportaLayoutA2x', response.data[0].exportalayouta2x);
                    if ((aExportaContabil == null) || (aExportaContabil == undefined))
                        sessionStorage.setItem('exportaContabil', response.data[0].exportacontabil);
                    if ((aIdentEmpCtbPeloSetor == null) || (aIdentEmpCtbPeloSetor == undefined))
                        sessionStorage.setItem('identEmpCtbPeloSetor', response.data[0].identempctbpelosetor);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        // carrega informações formulário
        const { id } = this.props.match.params;
        const { location } = this.props;
        const currentPath = location.pathname;

        if (id >= 0) {
            const response = await api.get(`/financas/conta/${id}`);
            //console.log('antes atribuir variavel: ' + JSON.stringify(response));
            //console.log(response)
            this.setState({
                contacorrente: response.data[0],
                isNewRecord: false,
                isView: (currentPath.includes('/view'))
            }
            );

            //console.log('após atribuir variavel: ' + JSON.stringify(this.state.contacorrente));
            if (!(response.data[0].codbanco == null)) {
                const responseBanco = await api.get(`/banco/${response.data[0].codbanco}`)

                this.setState({
                    banco: {
                        numerobanco: responseBanco.data[0].nrocompensacao,
                        digitobanco: responseBanco.data[0].digito
                    }
                })
            };
        }
        else if (id === 'novo') {
            this.setState({
                isNewRecord: true
            });
            this.state.isNewRecord = true;
            const { contacorrente } = this.state;
            contacorrente.tipocaixabanco = ATIPO_CONTA_BANCO;
            contacorrente.moeda = parseInt(sessionStorage.getItem('moeda'));
        }
    }

    /* evento on change componentes */
    OnChange = (event) => {
    const { contacorrente } = this.state;
    const { name, type, value, checked } = event.target;
    let adjustedValue = value; // Valor ajustado com base no tipo do input
    //console.log('the type is ' + type + ' and name is ' + name + ' and value is ' + value);
    // Decide o tratamento com base no tipo do input ou nome
        switch (type) {
            case 'number':
                console.log('É um campo de número inteiro. Nome:', name);
                // Converte a string numérica para número antes de salvar
                adjustedValue = Number(value);
                break;
            case 'checkbox':
                // Usa o valor checked para campos do tipo checkbox
                // Tratamentos adicionais específicos para campos
                if (name === 'inativo') {
                  if (checked === false) {
                      adjustedValue = ASIM;
                  }
                  else {
                      //contacorrente.inativo = ANAO;
                      adjustedValue = ANAO;
                  }
                }
                break;
            case 'select-one':
                // Tratamento específico para select, se necessário
                if (name == 'codbanco' || name == 'tipocaixabanco' || name == 'tipopix' || name == 'moeda'){
                    adjustedValue = Number(value);
                    if (name === 'codbanco') {
                        this.atualizaDadosBanco(adjustedValue); // Atualiza dados do banco conforme necessário
                    }
                } 
                break;
            default:
                // Tratamento para outros tipos de inputs, como text
                if (name == 'pix') {
                    // Se o nome do campo é 'pix', não converte para maiúsculas mantem formato original
                }
                else
                if (name == 'limtite') {
                    adjustedValue = Number(value.replace(/\./g, '').replace(',', '.'));
                }
        
                else
                {
                    // Converte valor para maiúsculas para outros campos
                    adjustedValue = value.toUpperCase();
                }
                break;
        }
    
        // Atualiza o estado com o valor ajustado
        contacorrente[name] = adjustedValue;
        this.setState({ contacorrente: contacorrente });
     
    };

    atualizaDadosBanco = async (codbanco) => {
        const { contacorrente } = this.state;
        const { banco } = this.state;

        console.log('the code bank is '+ codbanco)
        if (!(contacorrente.codbanco == 0)) {
//            const responseBanco = await api.get(`/banco/${contacorrente.codbanco}`);
            const responseBanco = await api.get(`/banco/${codbanco}`);

            banco.numerobanco = responseBanco.data[0].nrocompensacao;
            banco.digitobanco = responseBanco.data[0].digito;
        } else {
            banco.numerobanco = '';
            banco.digitobanco = '';
        }

        this.setState({ banco: banco });
    }


    /* atualiza dados de banco existente - post */
    atualizaConta = async () => {
        const { contacorrente } = this.state;
        console.log('o que está no objeto antes de salvar alteração: ' + JSON.stringify(contacorrente));
        try {
            const response = await api.post(`/financas/conta/${contacorrente.cdconta}`, contacorrente);
            if (response.status === 200) {
                this.props.history.push('/financas/contascorrentes');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Click salvar */
    onClickSave = () => {
        if (this.validationRequiredFields()) {
            if (this.state.isNewRecord) {
                this.insereConta()
            }
            else {
                this.atualizaConta();
            }
        }
    }

    /* Click cancelar */
    onClickCancel = () => {
        this.props.history.push('/financas/contascorrentes');
    }

    /* verifica se tem erro campos */
    // hasErrorField = (field, value) => {
    //     if (field === 'clienteconta') {
    //         if ((String(value).trim() === '')) {
    //             return true
    //         }
    //     }
    // }

    /* valida campos obrigatórios ao salvar */
    validationRequiredFields() {
        const { contacorrente } = this.state;
        let retorno = true;

        if ((String(contacorrente.clienteconta).trim() === '') || (contacorrente.clienteconta === undefined)) {
            contacorrente.clienteconta = '';
            this.setState({ contacorrente: contacorrente });
            retorno = false;
        };

        if ((String(contacorrente.nroconta).trim() === '') || (contacorrente.nroconta === undefined)) {
            contacorrente.nroconta = '';
            this.setState({ contacorrente: contacorrente });
            retorno = false;
        };

        return retorno;
    }

    /* insere nova conta corrente */
    insereConta = async () => {
        const { contacorrente } = this.state;
        contacorrente.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
        console.log('o que está antes do post ' + JSON.stringify(contacorrente))
        try {
            const response = await api.post(`/financas/conta`, contacorrente);
            if (response.status === 200) {
                this.props.history.push('/financas/contascorrentes');
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    render() {
        const { contacorrente } = this.state;
        const { banco } = this.state;
        return (
            <div>
                <CardContent>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                        Conta Corrente {contacorrente.cdconta}
                    </Typography>

                    <FormControl
                        // fullWidth
                        style={{ marginTop: '1rem', width: '200px', marginRight: '10px' }}
                        variant="outlined"
                        disabled={this.state.isView}
                    >
                        <InputLabel htmlFor="outlined-age-native-simple">Tipo</InputLabel>

                        <ComboTipoCaixaBanco
                            name={'tipocaixabanco'}
                            label={'Tipo'}
                            value={parseInt(contacorrente.tipocaixabanco)}
                            onChange={this.OnChange}
                        />

                    </FormControl>

                    <FormControlLabel
                        style={{ marginTop: '1.5rem', marginLeft: '10px' }}
                        control={
                            <Switch
                                checked={!(contacorrente.inativo === ASIM)}
                                name="inativo"
                                onChange={this.OnChange}
                                color="primary"
                                disabled={this.state.isView}
                            />
                        }
                        label={(contacorrente.inativo === ASIM) ? 'Inativo' : 'Ativo'}
                    />

                    <br />

                    <FormGroup row={true} style={{ marginTop: '20px' }}>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Agência"
                            style={{ width: 150, marginRight: '10px' }}
                            name="agenconta"
                            value={contacorrente.agenconta || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 15 }}
                        />

                        <TextField
                            className={this.state.useStyles.textField}
                            label="Dígito"
                            style={{ width: 75, marginRight: '10px' }}
                            name="digitoagencia"
                            value={contacorrente.digitoagencia || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 1 }}
                        />

                        <TextField
                            className={this.state.useStyles.TextField}
                            label="Nº Conta"
                            style={{ width: 150, marginRight: '10px' }}
                            name="nroconta"
                            value={contacorrente.nroconta || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            helperText={contacorrente.nroconta === '' ? 'Número da conta obrigatório' : ' '}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 20 }}
                        />

                        <TextField
                            className={this.state.useStyles.textField}
                            label="Dígito"
                            style={{ width: 75, marginRight: '10px' }}
                            name="digitoconta"
                            value={contacorrente.digitoconta || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 1 }}
                        />

                        <TextField
                            className={this.state.useStyles.textField}
                            label="Operação"
                            style={{ width: 100, marginRight: '10px' }}
                            name="operador"
                            value={contacorrente.operador || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 10 }}
                        />

                    </FormGroup>

                    <FormGroup>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Cliente Conta"
                            style={{ marginTop: '10px' }}
                            name="clienteconta"
                            value={contacorrente.clienteconta || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            helperText={contacorrente.clienteconta === '' ? 'Cliente da conta obrigatório' : ' '}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormGroup>

                    <FormGroup row={true}>
                        <FormControl
                            fullWidth
                            style={{ marginTop: '10px', marginRight: '10px', width: '70%' }}
                            variant="outlined"
                            disabled={this.state.isView}
                        >
                            <InputLabel htmlFor="outlined-age-native-simple">Banco</InputLabel>

                            <ComboBanco
                                name={'codbanco'}
                                label={'Banco'}
                                value={contacorrente.codbanco}
                                onChange={this.OnChange}
                            />

                        </FormControl>

                        <TextField
                            className={this.state.useStyles.textField}
                            label="Número"
                            style={{ marginTop: '10px', width: '100px', marginRight: '10px' }}
                            name="numerobanco"
                            value={banco.numerobanco || ''}
                            variant="outlined"
                            inputProps={{ readOnly: true }}
                            disabled={this.state.isView}
                        />

                        <TextField
                            className={this.state.useStyles.textField}
                            label="Dígito"
                            style={{ marginTop: '10px', width: '100px', marginRight: '10px' }}
                            name="digitobanco"
                            value={banco.digitobanco || ''}
                            variant="outlined"
                            inputProps={{ readOnly: true }}
                            disabled={this.state.isView}
                        />
                    </FormGroup>

                    <FormGroup>
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Identificação internacional da conta"
                            style={{ marginTop: '20px' }}
                            name="identifinternconta"
                            value={contacorrente.identifinternconta || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />
                    </FormGroup>

                    <FormGroup row={true}>
                        <FormControl
                            // fullWidth
                            style={{ marginTop: '20px', width: '225px', marginRight: '10px' }}
                            variant="outlined"
                            disabled={this.state.isView}
                        >
                            <InputLabel htmlFor="outlined-age-native-simple">Tipo da chave PIX</InputLabel>

                            <ComboTipoChavePix
                                name={'tipopix'}
                                label={'Tipo da chave PIX'}
                                value={contacorrente.tipopix}
                                onChange={this.OnChange}
                            />

                        </FormControl>

                        <TextField
                            className={this.state.useStyles.textField}
                            label="PIX"
                            style={{ marginTop: '20px', width: '500px' }}
                            name="pix"
                            value={contacorrente.pix || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 100 }}
                        />
                    </FormGroup>

                    <FormGroup row={true}>
                        <FormControl
                            // fullWidth
                            style={{ marginTop: '20px', width: '225px', marginRight: '10px' }}
                            variant="outlined"
                            disabled={this.state.isView}
                        >
                            <InputLabel htmlFor="outlined-age-native-simple">Moeda da conta corrente</InputLabel>

                            <ComboMoeda
                                name={'moeda'}
                                label={'Moeda da conta corrente'}
                                value={contacorrente.moeda}
                                onChange={this.OnChange}
                                visible={true}
                            />

                        </FormControl>

                        <CurrencyTextField
                            className={this.state.useStyles.textField}
                            label="Limite"
                            style={{ marginTop: '20px', width: '225px' }}
                            name="limtite"
                            id="limtite"
                            value={contacorrente.limtite || ''}
                            variant="outlined"
                            currencySymbol=""
                            outputFormat="number" // Use 'string' para manter a formatação personalizada
                            decimalCharacter="," // Define a vírgula como o separador decimal
                            digitGroupSeparator="." // Define o ponto como o separador de milhar
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            minimumValue="0"
                        />


                    </FormGroup>

                    <FormGroup
                        style={{
                            display: ((sessionStorage.getItem('exportaLayoutA2x') == ASIM ||
                                sessionStorage.getItem('exportaContabil') == ASIM) ? '' : 'none')
                        }}
                        row={true}
                    >
                        <TextField
                            className={this.state.useStyles.textField}
                            label="Código conta para exportação"
                            style={{ marginTop: '20px', width: '225px', marginRight: '10px' }}
                            name="codcontacorrenteexp"
                            value={contacorrente.codcontacorrenteexp || ''}
                            variant="outlined"
                            onChange={this.OnChange}
                            disabled={this.state.isView}
                            inputProps={{ maxLength: 50 }}
                        />

                        <FormControl
                            fullWidth
                            style={{
                                marginTop: '20px',
                                marginRight: '10px',
                                width: '500px',
                                display: ((sessionStorage.getItem('exportaContabil') == ASIM &&
                                    sessionStorage.getItem('identEmpCtbPeloSetor') == ASIM) ? '' : 'none')
                            }}
                            variant="outlined"
                            disabled={this.state.isView}
                        >
                            <InputLabel htmlFor="outlined-age-native-simple">Setor</InputLabel>

                            <ComboSetor
                                name={'codsetor'}
                                label={'Setor'}
                                value={contacorrente.codsetor}
                                onChange={this.OnChange}
                            />

                        </FormControl>

                    </FormGroup>

                    <br />

                    <Button
                        name="salvar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickSave}
                        style={{ marginTop: '10px' }}
                        disabled={this.state.isView}
                    >
                        Salvar
                    </Button>

                    <Button
                        name="cancelar"
                        color="primary"
                        variant="contained"
                        onClick={this.onClickCancel}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                    >
                        Cancelar
                    </Button>
                </CardContent>

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

ContaCorrenteForm.propTypes =
{
    classes: PropTypes.object.isRequired
};

export default ContaCorrenteForm;