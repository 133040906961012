import { Button, Card, CardContent, Input, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PerfectScrollbar from 'react-perfect-scrollbar';
import api from '../../../services/api';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from 'components/AlertInformation/AlertInformation';

class MotivoCancelamentoList extends Component {
    state = {
        motivoscancelamentos: [],
        searchFilter: {
            nomeMotivoCancelamento: '',
            situacaoInativoFilter: ANAO,
        },
    };

    estilo = {
        marginLeft: 30,
        marginTop: 30,
        marginRight: 30,
        container: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        addButton: {
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            float: 'right', // alinhamento a direita
            marginTop: 3,
            marginRight: 3
        },
        redText: {
            color: 'red',
        },
    };

    constructor(props) {
        super(props);
    }

    /* carregamento inicial */
    async componentDidMount() {
        this.loadMotivosCancelamento();
    }

    /* Carrega motivos cancelamento */
    loadMotivosCancelamento = async () => {
        const { searchFilter } = this.state;
        let params = '';

        /* consulta pela descrição do motivo de cancelamento */
        if (!(searchFilter.nomeMotivoCancelamento === '')) {
            params += '?tbmotivocancdevservico.descmotivo=_like_' + searchFilter.nomeMotivoCancelamento
        }

        /* filtra situação do motivo de cancelamento */
        switch (searchFilter.situacaoInativoFilter) {
            case ASIM:
                if (params) {
                    params += '&tbmotivocancdevservico.inativo=' + ASIM
                }
                else
                    params += '?tbmotivocancdevservico.inativo=' + ASIM;
                break;
            case ANAO:
                if (params) {
                    params += '&tbmotivocancdevservico.inativo=' + ANAO
                }
                else
                    params += '?tbmotivocancdevservico.inativo=' + ANAO;
                break;
            default:
                break;
        }

        const response = await api.get('/motivocancelamento' + params);
        this.setState({ motivoscancelamentos: response.data });
    }

    /* Escolha de um registro para exclusão */
    handleSelectItem = (id) => {
        this.setState({ selectedId: id });
    };

    /* deleta motivo cancelamento */
    handleDelete = async (id) => {
        try {
            const response = await api.delete(`/motivocancelamento/${id}`);
            // Verifica se a exclusão foi bem-sucedida
            if (response.status === 200) {
                this.handleClose();
                this.loadMotivosCancelamento();
            }
        } catch (error) {
            this.handleClose();
            this.handleOpenAlert(error.response.data.error);
            console.log(error);
            console.log(error.message);
        }
    };

    /* abre janela confirmação para exclusão */
    handleOpen = (codmotivo) => {
        this.setState({ open: true });
        this.handleSelectItem(codmotivo);
    };

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message) => {
        this.setState({ openAlert: true });
        this.setState({ messageAlert: message });
    };

    /* fecha janela de confirmação exclusão */
    handleClose = () => {
        this.setState({ open: false });
    };

    /* fecha janela mensagem alerta */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    }

    /* Pesquisar filtros na tela */
    OnChangeSearch = (event) => {
        const { searchFilter } = this.state;
        let { name, value } = event.target;
        value = value.toUpperCase()
        searchFilter[name] = value;
        this.setState({ searchFilter: searchFilter });
        if (name === 'situacaoInativoFilter') {
            this.loadMotivosCancelamento();
        }
    };

    /* Limpa Filtros na tela */
    LimpaFiltros = () => {
        let { searchFilter } = this.state;
        searchFilter.nomeMotivoCancelamento = '';
        searchFilter.situacaoInativoFilter = 'N';
        this.setState({ searchFilter: searchFilter });
    }

    render() {
        const { motivoscancelamentos } = this.state;
        const { searchFilter } = this.state;
        return (
            <div style={this.estilo}>
                <Button
                    component={Link}
                    to="/front/motivoscancelamentos/novo"
                    variant="contained"
                    color="primary" 
                    style={this.estilo.addButton}
                >
                    Novo
                </Button>

                <Paper hover>
                    <Input
                        autoFocus
                        id="nomeMotivoCancelamento"
                        name="nomeMotivoCancelamento"
                        onBlur={this.onInputBlur}
                        onChange={this.OnChangeSearch}
                        placeholder="Nome motivo cancelamento a pesquisar"
                        style={{ marginBottom: 10, width: 300, marginLeft: 10, marginTop: 10 }}
                        value={searchFilter.nomeMotivoCancelamento}
                    />

                    <Select
                        id="situacaoInativoFilter"
                        name="situacaoInativoFilter"
                        style={{ marginLeft: 20 }}
                        value={searchFilter.situacaoInativoFilter || ''}
                        onChange={this.OnChangeSearch}
                    >
                        <MenuItem value={ANAO}>Ativos</MenuItem>
                        <MenuItem value={ASIM}>Inativos</MenuItem>
                        <MenuItem value={'T'}>Todos</MenuItem>
                    </Select>

                    <Button
                        color="primary"
                        id="pesquisar"
                        onClick={() => this.loadMotivosCancelamento()}
                    >
                        <SearchIcon />
                    </Button>

                    <Button
                        color="primary"
                        onClick={() => this.LimpaFiltros()}
                    >
                        <ClearAllIcon />
                    </Button>
                </Paper>

                <Card>
                    <CardContent>
                        <PerfectScrollbar>
                            <div >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {motivoscancelamentos.slice(0, 100).map(motivocancelamento => (
                                            <TableRow
                                                hover
                                                key={motivocancelamento.codmotivo}
                                            >
                                                <TableCell style={(motivocancelamento.inativo === ASIM) ? this.estilo.redText : {}}>{motivocancelamento.codmotivo}</TableCell>

                                                <TableCell style={(motivocancelamento.inativo === ASIM) ? this.estilo.redText : {}} >{motivocancelamento.descmotivo}</TableCell>

                                                <TableCell>
                                                    <Link to={`/front/motivoscancelamentos/${motivocancelamento.codmotivo}`}>
                                                        <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    </Link>
                                                    <Link to={`/front/motivoscancelamentos/${motivocancelamento.codmotivo}/view`}>
                                                        <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                    </Link>
                                                    <DeleteForeverOutlinedIcon
                                                        onClick={() => this.handleOpen(motivocancelamento.codmotivo)}
                                                        style={{ cursor: 'pointer', marginRight: '10px' }} />
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardContent>
                </Card>

                <DeleteConfirmationModal
                    open={this.state.open}
                    handleClose={() => this.handleClose()}
                    itemId={this.state.selectedId}
                    message={"Confirma exclusão do motivo de cancelamento " + this.state.selectedId + '?'}
                    handleDelete={() => this.handleDelete(this.state.selectedId)}
                />

                <AlertInformation
                    open={this.state.openAlert}
                    handleClose={() => this.handleCloseAlert()}
                    message={this.state.messageAlert}
                />
            </div>
        );
    }
}

export default MotivoCancelamentoList;