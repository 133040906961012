import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, withStyles, Modal } from '@material-ui/core';
import api from 'services/api';
import { formatDateTimeISO } from 'lib/functions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';
import HorarioPadraoServicoForm from './HorarioPadraoServicoForm';

const styles = (theme) => ({
    table: {
        minWidth: 650,
    },
    dialogContent: {
        wordWrap: 'break-word',
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
});

class HorarioPadraoServico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horarios: [], // Armazena os dados de horário
            isHorarioDeleteModalOpen: false,
            selectedIdHorario: 0,
            selectedHorario: {},
        };
    };

    // Método para buscar os dados da API ao montar o componente
    componentDidMount() {
        const { codServico, horariosServico } = this.props;

        if (codServico) {
            this.fetchHorario(codServico);
        } else {
            this.setState({ horarios: horariosServico });
        }
    };

    // Faz a requisição à API para obter os horários
    fetchHorario = async (codServico) => {
        try {
            const response = await api.get(`/horarioservico?tbhorarioservico.codservico=${codServico}`);
            this.setState({ horarios: response.data });
        } catch (error) {
            console.error('Erro ao buscar horários:', error);
        }
    };

    isValidTimeFormat = (time) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        return regex.test(time);
    };

    formatHora(hora) {
        if (!(hora === null) && (!(hora === undefined))) {
            if (this.isValidTimeFormat(hora)) {
                return hora;
            } else {
                const horaApresentacao = hora;
                const dateObject = new Date(horaApresentacao);
                const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                return formattedTime;
            }
        }
        else {
            return '';
        }
    };

    /* abre janela de alerta com mensagem */
    handleOpenAlert = (message, callback) => {
        this.setState({
            messageAlert: message,
            openAlert: true,
            onCloseCallback: callback
        });
    };

    /* fecha janela de confirmação exclusão de horário */
    openHorarioDeleteModal = (idhorario) => {
        this.setState({ selectedIdHorario: idhorario });
        // this.setState({ selectedNomePax: nomepax });
        this.setState({ isHorarioDeleteModalOpen: true });
    };

    /* fecha janela de confirmação exclusão de horário */
    closeHorarioDeleteModal = () => {
        this.setState({ isHorarioDeleteModalOpen: false });
    };

    /* deleta horario */
    handleDeleteHorario = async (id) => {
        // const { isNewRecord } = this.state;
        try {
            // if (!(isNewRecord)) {
            const response = await api.delete(`/horarioservico/${id}`);

            if (response.status === 200) {
                try {
                    this.closeHorarioDeleteModal();
                    this.updateHorarioList();
                } catch (error) {
                    console.error('Erro ao fechar o modal:', error);
                }
            }
            // }
            // else {
            //     this.removePaxEReorganizarSequencia(id);
            //     this.closePaxDeleteModal();
            // }

        } catch (error) {
            this.closeHorarioDeleteModal();
            this.handleOpenAlert(error.response.data.error);
            console.log(error);
            console.log(error.message);
        }
    };

    // Método para atualizar a lista de horários
    updateHorarioList = async (horario) => {
        const horariooff = horario;
        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.get(`/horarioservico?tbhorarioservico.codservico=${this.props.codServico}`);
                if (response.status === 200) {
                    this.setState({ horarios: response.data });
                }
            }
            else {
                const { horarios } = this.state;
                // const index = horarios.findIndex(horario => horario.chave === horariooff.chave);

                // if (index !== -1) {
                //     const novaListaHorarios = [
                //         ...horarios.slice(0, index),
                //         horariooff,
                //         ...horarios.slice(index + 1)
                //     ];

                //     // Atualiza o estado com a nova lista
                //     this.setState({ horarios: novaListaHorarios });
                // } else {
                //     const novoHorario = horariooff;
                //     // novoHorario.chave = (this.state.horarios.length + 1);

                //     // Cria uma nova lista adicionando o novo horario ao final da lista existente
                //     const novaListaHorarios = [...this.state.horarios, novoHorario];

                //     // Atualiza o estado com a nova lista de horarios
                //     this.setState({ horarios: novaListaHorarios });
                // }

                horario.chave = horarios.length + 1;
                horarios.push(horario);

                this.setState({ horarios: horarios });
            }

        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    };

    /* open form horario modal */
    openHorarioModal = (id = 'novo') => {
        let horarioEncontrado = {};
        // if (this.state.isNewRecord) {
        //     if (!(id === 'novo')) {
        //         const idpaxProcurado = parseInt(id, 10);
        //         paxEncontrado = this.state.paxos.find(pax => pax.idpax === idpaxProcurado);
        //     }
        // }

        this.setState({
            openHorarioForm: true,
            selectedIdHorario: id, // Define o ID do horario que será editado, ou 'novo' para criar
            selectedHorario: horarioEncontrado
        });
    };

    handleFormClose = () => {
        this.setState({ openHorarioForm: false });
    };

    render() {
        const { horarios
            // , selectedHistorico, open 
        } = this.state;
        const { classes } = this.props;

        return (
            <div>
                <div>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Domingo</TableCell>
                                    <TableCell>Segunda</TableCell>
                                    <TableCell>Terça</TableCell>
                                    <TableCell>Quarta</TableCell>
                                    <TableCell>Quinta</TableCell>
                                    <TableCell>Sexta</TableCell>
                                    <TableCell>Sábado</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {horarios.map((horario) => (
                                    <TableRow key={horario.chave}>
                                        <TableCell>{this.formatHora(horario.dom)}</TableCell>
                                        <TableCell>{this.formatHora(horario.seg)}</TableCell>
                                        <TableCell>{this.formatHora(horario.ter)}</TableCell>
                                        <TableCell>{this.formatHora(horario.qua)}</TableCell>
                                        <TableCell>{this.formatHora(horario.qui)}</TableCell>
                                        <TableCell>{this.formatHora(horario.sex)}</TableCell>
                                        <TableCell>{this.formatHora(horario.sab)}</TableCell>
                                        <TableCell>
                                            <Button
                                                color="primary"
                                                onClick={() => this.openHorarioModal(horario.chave)}
                                            >
                                                <EditIcon />
                                            </Button>

                                            <Button
                                                color="primary"
                                                onClick={() => this.openHorarioDeleteModal(horario.chave)}
                                            >
                                                <DeleteOutlineIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        color="primary"
                        className={classes.addButton}
                        variant="contained"
                        onClick={() => this.openHorarioModal('novo')}
                        // disabled={this.state.isView}
                        style={{ marginTop: '5px' }}
                    >
                        Inserir
                    </Button>

                    {/* Exibe a observação no diálogo */}
                    {/* <Dialog open={open} onClose={this.handleClose} maxWidth="sm" fullWidth>
          <DialogTitle className={classes.dialogTitle}>Observação do Histórico</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="body1">
              {selectedHistorico ? selectedHistorico.obs : ''}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog> */}
                </div>
                <div>
                    <Modal
                        open={this.state.openHorarioForm}
                        onClose={this.handleFormClose}
                    >
                        {/* <div style={{ margin: '100px auto', width: '80%', padding: '20px', backgroundColor: 'white' }}> */}
                        <div>
                            <HorarioPadraoServicoForm
                                // pax={this.state.selectedPax} // passa objetoPaxselecionado para o formulário.
                                onClose={this.handleFormClose}
                                onSave={(updateHorario) => {
                                    this.handleFormClose(); // Fechar o modal
                                    this.updateHorarioList(updateHorario); // Atualizar a lista de horários
                                }}
                                isModal={true}
                                codServico={this.props.codServico}
                                id={this.state.selectedIdHorario}  // Passe o ID do horário ou 'novo' para um novo registro                                
                            />
                        </div>
                    </Modal>

                    <DeleteConfirmationModal
                        open={this.state.isHorarioDeleteModalOpen}
                        handleClose={() => this.closeHorarioDeleteModal()}
                        itemId={this.state.selectedIdHorario}
                        message={"Confirma exclusão do horário padrão do serviço?"}
                        handleDelete={() => this.handleDeleteHorario(this.state.selectedIdHorario)}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(HorarioPadraoServico);