import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles'; // Componente de classe usa withStyles ao invés de makeStyles
import { InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    select: {
        '& .MuiSelect-root': {
            padding: theme.spacing(2), // Aumenta a altura do Select
            borderRadius: 4,
            border: 'none', // Remove qualquer borda padrão
            boxShadow: 'none', // Remove sombras que possam estar interferindo
            height: '22px', // Define a altura do Select
        },
        '& option': {
            padding: theme.spacing(1),
            borderRadius: 4,
            margin: 0, // Remove margens para evitar o risco leve
        }
    },
    inputLabel: {
        marginBottom: theme.spacing(2), // Adiciona o espaçamento desejado aqui
    },
    helperTextError: {
        color: 'red',
    }
});

class ComboPadrao extends Component {
    // Verifica se o componente deve atualizar ou não com base nas mudanças das props
    shouldComponentUpdate(nextProps) {
        return (
            nextProps.value !== this.props.value ||
            nextProps.listItems !== this.props.listItems ||
            nextProps.helperText !== this.props.helperText
        );
    }

    render() {
        const { classes, fieldDescription, fieldId, value, name, helperText, ...rest } = this.props;
        const listItems = rest.listItems;
        const selectedItem = listItems.find(item => item[fieldId] === value);

        return (
            <div>
                <InputLabel 
                    htmlFor="outlined-age-native-simple"
                    className={classes.inputLabel} 
                >
                    {rest.label}
                </InputLabel>

                <Select
                    fullWidth
                    className={classes.select}
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: name,
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    value={value || ''}
                    style={{
                        color: selectedItem && selectedItem.inativo === 'S' ? 'red' : 'black',
                        border: 'none'
                    }}
                >
                    <option value={"undefined"} aria-label="Não Definido"> </option>
                    {listItems.map(item => (
                        <option
                            key={item[fieldId]}
                            value={item[fieldId]}
                            style={{
                                color: item.inativo === 'S' ? 'red' : 'black',
                                backgroundColor: item.inativo === 'S' ? '#ff9999' : 'inherit'
                            }}
                        >
                            {item[fieldDescription]}
                        </option>
                    ))}
                </Select>
                <FormHelperText className={helperText ? classes.helperTextError : ''}>
                    {helperText}
                </FormHelperText>
            </div>
        );
    }
}

ComboPadrao.propTypes = {
    classes: PropTypes.object.isRequired,
    fieldDescription: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    listItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
};

// withStyles é usado para aplicar estilos ao componente de classe
export default withStyles(styles)(ComboPadrao);
